import { all, call, put, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'redux/Message/actions';
import { setValidation } from 'redux/Validation/actions';
import messageTypes from 'redux/Message/messagetypes';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as actions from './actions';
import * as types from './types';

// инциденты
function* loadIncidentsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingIncidents(true));
    const response = yield call(api.getIncidents, page, limit, params);
    if (response?.success) {
        yield put(actions.loadedIncidents(response));
    }
    yield put(actions.loadingIncidents(false));
}

function* loadIncidentByIdSaga({ payload, callback }) {
    const response = yield call(api.getIncidentById, payload);
    if (response?.success) {
        yield callback && callback(response.data);
    }
}

function* loadTypicalOperationsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingTypicalOperations(true));
    const typicalOperationsList = yield call(api.getTypicalOperations, page, limit, params);
    if (typicalOperationsList) {
        yield put(actions.loadedTypicalOperations(typicalOperationsList));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* createTypicalOperationSaga({ payload: data, callback }) {
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.createTypicalOperation, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* editTypicalOperationSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.editTypicalOperation, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* deleteTypicalOperationSaga({ payload: id, callback }) {
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.deleteTypicalOperation, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingTypicalOperations(false));
}

// статус инц.
function* loadIncidentsStatusesSaga() {
    const statuses = yield call(api.getIncidentsStatuses);
    if (statuses) {
        yield put(actions.loadedIncidentsStatuses(statuses.data));
    }
}

function* createIncidentSaga({ payload: data, callback }) {
    const response = yield call(api.createIncident, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

// сайдбар инц.
function* loadIncidentSidebarSaga({ payload }) {
    const { page } = payload;
    yield put(actions.loadingIncidentSidebar(true));
    const response = yield call(api.getIncidentSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedIncidentSidebar(response, page === 1));
    }
    yield put(actions.loadingIncidentSidebar(false));
}

// полигон инц.
function* loadIncidentPolygonSaga({ payload }) {
    const { polygon, filter } = payload;
    yield put(actions.loadingIncidentPolygon(true));
    const response = yield call(api.getIncidentPolygon, { polygon, ...filter });
    if (response?.success) {
        yield put(actions.loadedIncidentPolygon(response.data));
    }
    yield put(actions.loadingIncidentPolygon(false));
}

// тепловая карта инц.
function* loadIncidentHeatMapSaga({ payload }) {
    yield put(actions.loadingIncidentHeatMap(true));
    const response = yield call(api.getIncidentHeatMap, { ...payload });
    if (response?.success) {
        yield put(actions.loadedIncidentHeatMap(response.data));
    }
    yield put(actions.loadingIncidentHeatMap(false));
}

// типы инц.
function* loadIncidentTypesSaga({ payload }) {
    yield put(actions.loadingIncidentTypes(true));
    const typesList = yield call(api.getIncidentTypes, payload);
    if (typesList) {
        yield put(actions.loadedIncidentTypes(typesList));
    }
    yield put(actions.loadingIncidentTypes(false));
}

function* createIncidentTypeSaga({ payload: data, callback }) {
    const response = yield call(api.createIncidentType, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

function* editIncidentTypeSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editIncidentType, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

function* deleteIncidentTypeSaga({ payload: id, callback }) {
    const response = yield call(api.deleteIncidentType, id);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
}

function* loadIncidentTypeByIdSaga({ payload: id, callback }) {
    const response = yield call(api.getIncidentTypeById, id);
    if (response?.success) {
        yield callback?.(response.data);
    }
}
// факторы инц.
function* loadIncidentsFactorsSaga({ payload, callback }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.getIncidentsFactors, page, limit, params);
    if (response?.success) {
        if (callback) {
            callback(response);
        } else {
            yield put(actions.loadedIncidentsFactors(response));
        }
    }
    yield put(actions.loadingIncidentsFactors(false));
}

function* createIncidentsFactorsSaga({ payload: data, callback }) {
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.createIncidentsFactors, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingIncidentsFactors(false));
}

function* editIncidentSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editIncident, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteIncidentSaga({ payload, callback }) {
    const response = yield call(api.deleteIncident, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

function* editIncidentsFactorsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.editIncidentsFactors, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingIncidentsFactors(false));
}

function* deleteIncidentsFactorsSaga({ payload: id, callback }) {
    yield put(actions.loadingIncidentsFactors(true));
    const response = yield call(api.deleteIncidentsFactors, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingIncidentsFactors(false));
}

// сценарии
function* loadScenariosSaga({ payload }) {
    yield put(actions.loadingScenarios(true));
    const response = yield call(api.getScenarios, payload);
    if (response?.success) {
        yield put(actions.loadedScenarios(response));
    }
    yield put(actions.loadingScenarios(false));
}
// уровень угрозы
function* loadThreatLevelsSaga({ payload }) {
    yield put(actions.loadingThreatLevels(true));
    const response = yield call(api.getThreatLevels, payload);
    if (response?.success) {
        yield put(actions.loadedThreatLevels(response));
    }
    yield put(actions.loadingThreatLevels(false));
}
//  виды тс
function* loadVehicleCategoriesSaga() {
    const response = yield call(api.getCategories);
    if (response) {
        yield put(actions.loadedVehicleCategories(response));
    }
}

function* loadIncidentEventsSaga({ payload }) {
    yield put(actions.loadingIncidentEvents(true));
    const response = yield call(api.getIncidentEvents, payload);
    if (response) {
        yield put(actions.loadedIncidentEvents(response.data));
    }
    yield put(actions.loadingIncidentEvents(false));
}

// явления
function* loadPhenomenonsSaga({ payload }) {
    yield put(actions.loadingPhenomenons(true));
    const response = yield call(api.getPhenomenons, payload);
    if (response?.success) {
        yield put(actions.loadedPhenomenons(response));
    }
    yield put(actions.loadingPhenomenons(false));
}

// триггеры
function* loadTriggerStatusesSaga() {
    const response = yield call(api.getTriggerStatuses);
    if (response?.success) {
        yield put(actions.loadedTriggerStatuses(response.data));
    }
}

function* loadTriggersSaga({ payload }) {
    yield put(actions.loadingTriggers(true));
    const response = yield call(api.getTriggers, payload);
    if (response?.success) {
        yield put(actions.loadedTriggers(response));
    }
    yield put(actions.loadingTriggers(false));
}

function* createTriggerSaga({ payload, callback }) {
    const response = yield call(api.createTrigger, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
}

function* editTriggerSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editTrigger, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }
}

function* deleteTriggerSaga({ payload, callback }) {
    const response = yield call(api.deleteTrigger, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

function* getPolygonForTriggers ({ payload, callback }) {
    const { url, params } = payload;
    const response = yield call(api.getPolygonForTriggers, url, params);
    if (response?.success) {
        yield callback?.(response.data);
    }
}

function* loadKeyWordsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingKeyWordList(true));
    const response = yield call(api.getKeywordList, page, limit, params);
    if (response) {
        yield put(actions.loadedKeyWordList(response));
    }
    yield put(actions.loadingKeyWordList(false));
}

function* editKeyWordSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editKeyWord, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
        yield put(setValidation({ success: true }));
    }
}

function* addKeyWordSaga({ payload, callback }) {
    const response = yield call(api.addKeyWord, payload);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield put(setValidation({ success: true }));
    }
}

function* deleteKeyWordSaga({ payload: id, callback }) {
    const response = yield call(api.deleteKeyWord, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_INCIDENTS, loadIncidentsSaga),
        takeLatest(types.CREATE_INCIDENT, createIncidentSaga),
        takeLatest(types.EDIT_INCIDENT, editIncidentSaga),
        takeLatest(types.DELETE_INCIDENT, deleteIncidentSaga),
        takeLatest(types.LOAD_INCIDENT_BY_ID, loadIncidentByIdSaga),

        takeLatest(types.LOAD_TYPICAL_OPERATIONS, loadTypicalOperationsSaga),
        takeLatest(types.CREATE_TYPICAL_OPERATION, createTypicalOperationSaga),
        takeLatest(types.EDIT_TYPICAL_OPERATION, editTypicalOperationSaga),
        takeLatest(types.DELETE_TYPICAL_OPERATION, deleteTypicalOperationSaga),

        takeLatest(types.LOAD_INCIDENTS_STATUSES, loadIncidentsStatusesSaga),
        takeLatest(types.LOAD_THREAT_LEVELS, loadThreatLevelsSaga),
        takeLatest(types.LOAD_INCIDENT_SIDEBAR, loadIncidentSidebarSaga),
        takeLatest(types.LOAD_INCIDENT_POLYGON, loadIncidentPolygonSaga),
        takeLatest(types.LOAD_INCIDENT_HEAT_MAP, loadIncidentHeatMapSaga),
        takeLatest(types.LOAD_VEHICLES_CATEGORIES, loadVehicleCategoriesSaga),
        takeLatest(types.LOAD_INCIDENT_EVENTS, loadIncidentEventsSaga),
        takeLatest(types.LOAD_INCIDENTS_FACTORS, loadIncidentsFactorsSaga),
        takeLatest(types.CREATE_INCIDENTS_FACTORS, createIncidentsFactorsSaga),
        takeLatest(types.EDIT_INCIDENTS_FACTORS, editIncidentsFactorsSaga),
        takeLatest(types.DELETE_INCIDENTS_FACTORS, deleteIncidentsFactorsSaga),
        takeLatest(types.LOAD_SCENARIOS, loadScenariosSaga),
        takeLatest(types.LOAD_PHENOMENONS, loadPhenomenonsSaga),
        takeLatest(types.LOAD_TRIGGER_STATUSES, loadTriggerStatusesSaga),
        takeLatest(types.LOAD_TRIGGERS, loadTriggersSaga),
        takeLatest(types.CREATE_TRIGGER, createTriggerSaga),
        takeLatest(types.EDIT_TRIGGER, editTriggerSaga),
        takeLatest(types.DELETE_TRIGGER, deleteTriggerSaga),
        takeLatest(types.LOAD_POLYGON_FOR_TRIGGERS, getPolygonForTriggers),
        takeLatest(types.LOAD_INCIDENT_TYPES, loadIncidentTypesSaga),
        takeLatest(types.CREATE_INCIDENT_TYPE, createIncidentTypeSaga),
        takeLatest(types.EDIT_INCIDENT_TYPE, editIncidentTypeSaga),
        takeLatest(types.DELETE_INCIDENT_TYPE, deleteIncidentTypeSaga),
        takeLatest(types.GET_INCIDENT_TYPE_BY_ID, loadIncidentTypeByIdSaga),
        takeLatest(types.LOAD_KEY_WORD_LIST, loadKeyWordsSaga),
        takeLatest(types.ADD_KEY_WORD, addKeyWordSaga),
        takeLatest(types.EDIT_KEY_WORD, editKeyWordSaga),
        takeLatest(types.DELETE_KEY_WORD, deleteKeyWordSaga),
    ]);
}
