import * as types from './types';

export const loadIncidents = (page, limit, params) => ({
    type: types.LOAD_INCIDENTS,
    payload: { page, limit, params }
});

export const loadingIncidents = (loading) => ({
    type: types.LOADING_INCIDENTS,
    payload: loading
});

export const loadedIncidents = (response) => ({
    type: types.LOADED_INCIDENTS,
    payload: response
});

export const loadIncidentById = (id, callback) => ({
    type: types.LOAD_INCIDENT_BY_ID,
    payload: id,
    callback,
});

export const createIncident = (data, callback) => ({
    type: types.CREATE_INCIDENT,
    payload: data,
    callback
});

export const editIncident = (id, data, callback) => ({
    type: types.EDIT_INCIDENT,
    payload: { id, data },
    callback
});

export const deleteIncident = (id, callback) => ({
    type: types.DELETE_INCIDENT,
    payload: id,
    callback
});

// сайдбар инцидентов
export const loadIncidentSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_INCIDENT_SIDEBAR,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedIncidentSidebar = (data, isReplace = true) => ({
    type: types.LOADED_INCIDENT_SIDEBAR,
    payload: {
        data,
        isReplace,
    },
});

export const loadingIncidentSidebar = (bool = false) => ({
    type: types.LOADING_INCIDENT_SIDEBAR,
    payload: bool,
});

export const clearIncidentSidebar = () => ({
    type: types.CLEAR_INCIDENT_SIDEBAR,
});

export const reloadIncidentSidebar = (bool = false) => ({
    type: types.NEED_RELOAD_INCIDENT_SIDEBAR,
    payload: bool,
});

// полигон инцидентов
export const loadIncidentPolygon = (polygon, filter = {}) => ({
    type: types.LOAD_INCIDENT_POLYGON,
    payload: { polygon, filter }
});

export const loadedIncidentPolygon = (data) => ({
    type: types.LOADED_INCIDENT_POLYGON,
    payload: data
});

export const loadingIncidentPolygon = (bool = false) => ({
    type: types.LOADING_INCIDENT_POLYGON,
    payload: bool
});

export const clearIncidentPolygon = () => ({
    type: types.LOADED_INCIDENT_POLYGON,
    payload: []
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {}
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

// тепловая карта инцидентов
export const loadIncidentHeatMap = (filter = {}) => ({
    type: types.LOAD_INCIDENT_HEAT_MAP,
    payload: filter
});

export const loadedIncidentHeatMap = (data) => ({
    type: types.LOADED_INCIDENT_HEAT_MAP,
    payload: data
});

export const clearIncidentHeatMap = () => ({
    type: types.LOADED_INCIDENT_HEAT_MAP,
    payload: []
});

export const loadingIncidentHeatMap = (bool = false) => ({
    type: types.LOADING_INCIDENT_HEAT_MAP,
    payload: bool,
});

// Статусы Инцидентов
export const loadIncidentsStatuses = () => ({
    type: types.LOAD_INCIDENTS_STATUSES
});

export const loadedIncidentsStatuses = (data) => ({
    type: types.LOADED_INCIDENTS_STATUSES,
    payload: data
});

// Типы Инцидентов
export const loadIncidentTypes = (page = 1, limit = 25, params) => ({
    type: types.LOAD_INCIDENT_TYPES,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadingIncidentTypes = (bool = false) => ({
    type: types.LOADING_INCIDENT_TYPES,
    payload: bool
});

export const loadedIncidentTypes = (data) => ({
    type: types.LOADED_INCIDENT_TYPES,
    payload: data
});

export const createIncidentType = (data, callback) => ({
    type: types.CREATE_INCIDENT_TYPE,
    payload: data,
    callback
});

export const editIncidentType = (id, data, callback) => ({
    type: types.EDIT_INCIDENT_TYPE,
    payload: { id, data },
    callback
});

export const deleteIncidentType = (id, callback) => ({
    type: types.DELETE_INCIDENT_TYPE,
    payload: id,
    callback
});

export const loadIncidentTypeById = (id, callback) => ({
    type: types.GET_INCIDENT_TYPE_BY_ID,
    payload: id,
    callback
});

// Сценарии
export const loadScenarios = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_SCENARIOS,
    payload: { page, limit, ...params }
});

export const loadingScenarios = (loading) => ({
    type: types.LOADING_SCENARIOS,
    payload: loading
});

export const loadedScenarios = (data) => ({
    type: types.LOADED_SCENARIOS,
    payload: data
});

// уровень угрозы
export const loadThreatLevels = (page = 1, limit = 100, params) => ({
    type: types.LOAD_THREAT_LEVELS,
    payload: {
        page,
        limit,
        ...params
    }
});

export const loadingThreatLevels = (bool = false) => ({
    type: types.LOADING_THREAT_LEVELS,
    payload: bool
});

export const loadedThreatLevels = (data) => ({
    type: types.LOADED_THREAT_LEVELS,
    payload: data
});

// явления
export const loadPhenomenons = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_PHENOMENONS,
    payload: { page, limit, ...params }
});

export const loadingPhenomenons = (loading) => ({
    type: types.LOADING_PHENOMENONS,
    payload: loading
});

export const loadedPhenomenons = (data) => ({
    type: types.LOADED_PHENOMENONS,
    payload: data
});

// виды тс
export const loadVehicleCategories = () => ({
    type: types.LOAD_VEHICLES_CATEGORIES
});

export const loadedVehicleCategories = (data) => ({
    type: types.LOADED_VEHICLES_CATEGORIES,
    payload: data
});

export const loadIncidentEvents = (id) => ({
    type: types.LOAD_INCIDENT_EVENTS,
    payload: id
});

export const loadingIncidentEvents = (loading) => ({
    type: types.LOADING_INCIDENT_EVENTS,
    payload: loading
});

export const loadedIncidentEvents = (data) => ({
    type: types.LOADED_INCIDENT_EVENTS,
    payload: data
});

// Факторы
export const loadIncidentsFactors = (page = 1, limit = 25, params, callback) => ({
    type: types.LOAD_INCIDENTS_FACTORS,
    payload: {
        page,
        limit,
        params,
    },
    callback
});

export const loadingIncidentsFactors = (bool = false) => ({
    type: types.LOADING_INCIDENTS_FACTORS,
    payload: bool
});

export const loadedIncidentsFactors = (data) => ({
    type: types.LOADED_INCIDENTS_FACTORS,
    payload: data
});

export const createIncidentsFactors = (data, callback) => ({
    type: types.CREATE_INCIDENTS_FACTORS,
    payload: data,
    callback
});

export const editIncidentsFactors = (id, data, callback) => ({
    type: types.EDIT_INCIDENTS_FACTORS,
    payload: {
        id,
        data
    },
    callback
});

export const deleteIncidentsFactors = (id, callback) => ({
    type: types.DELETE_INCIDENTS_FACTORS,
    payload: id,
    callback
});

//типовые операции
export const loadTypicalOperations = (page = 1, limit = 25, params) => ({
    type: types.LOAD_TYPICAL_OPERATIONS,
    payload: {
        page,
        limit,
        params
    }
});

export const loadingTypicalOperations = (bool = false) => ({
    type: types.LOADING_TYPICAL_OPERATIONS,
    payload: bool
});

export const loadedTypicalOperations = (data) => ({
    type: types.LOADED_TYPICAL_OPERATIONS,
    payload: data
});

export const createTypicalOperation = (data, callback) => ({
    type: types.CREATE_TYPICAL_OPERATION,
    payload: data,
    callback
});

export const editTypicalOperation = (id, data, callback) => ({
    type: types.EDIT_TYPICAL_OPERATION,
    payload: {
        id,
        data
    },
    callback
});

export const deleteTypicalOperation = (id, callback) => ({
    type: types.DELETE_TYPICAL_OPERATION,
    payload: id,
    callback
});

// триггеры
export const loadTriggerStatuses = () => ({
    type: types.LOAD_TRIGGER_STATUSES
});

export const loadedTriggerStatuses = (data) => ({
    type: types.LOADED_TRIGGER_STATUSES,
    payload: data
});

export const loadTriggers = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_TRIGGERS,
    payload: { page, limit, ...params }
});

export const loadingTriggers = (loading) => ({
    type: types.LOADING_TRIGGERS,
    payload: loading
});

export const loadedTriggers = (data) => ({
    type: types.LOADED_TRIGGERS,
    payload: data
});

export const createTrigger = (data, callback) => ({
    type: types.CREATE_TRIGGER,
    payload: data,
    callback
});

export const editTrigger = (id, data, callback) => ({
    type: types.EDIT_TRIGGER,
    payload: { id, data },
    callback
});

export const deleteTrigger = (id, callback) => ({
    type: types.DELETE_TRIGGER,
    payload: id,
    callback
});

export const loadPolygonForTriggers = (url, params, callback) => ({
    type: types.LOAD_POLYGON_FOR_TRIGGERS,
    payload: { url, params },
    callback
});

export const loadKeyWordList = (page = 1, limit = 25, params) => ({
    type: types.LOAD_KEY_WORD_LIST,
    payload: {
        page,
        limit,
        params
    }
});

export const loadedKeyWordList = (data) => ({
    type: types.LOADED_KEY_WORD_LIST,
    payload: data
});

export const loadingKeyWordList = (bool = false) => ({
    type: types.LOADING_KEY_WORD_LIST,
    payload: bool
});

export const editKeyWord = (id, data, callback) => ({
    type: types.EDIT_KEY_WORD,
    payload: {
        id,
        data
    },
    callback
});

export const addKeyWord = (data, callback) => ({
    type: types.ADD_KEY_WORD,
    payload: data,
    callback
});

export const deleteKeyWord = (id, callback) => ({
    type: types.DELETE_KEY_WORD,
    payload: id,
    callback
});