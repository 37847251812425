import { useMemo } from 'react';
import { Grid } from '@mui/material';

import * as actions from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { Map, Marker, ToolTip } from 'components/MapComponents/leaflet';
import { createIconMarker, getColor } from 'components/MapComponents/Layers/Incidents/helper';
import PopUpModal from 'components/MapComponents/Layers/Incidents/PopUp';
import usePopModalListener from 'components/MapComponents/MapPopUp/PopUpListener/usePopModalListener';

import DtpTable from './DtpTable';

const DtpListModal = ({ isOpen, onClose, data }) => {

    const typesAll = useStoreFromSelectorListToObject(actions.loadDtpTypesAll, incidentsSelectors.typesAll);

    const mapPopupProvider = usePopModalListener();

    const dtpMarkers = useMemo(() => {
        const color = '#676a679e';

        return (data.dtp_list || []).map((item) => (
            <Marker
                key={item.id}
                icon={createIconMarker(getColor(item, typesAll.get))}
                latlng={[item.lat, item.lon]}
                attribution={{ color }}
                onClick={() => mapPopupProvider.setModal(item)}
            >
                <ToolTip
                    direction="top"
                    offset={[0, -40]}
                >
                    <div><b>ID:</b> {item.id}</div>
                    <div><b>Дата/время:</b> {fullDateTimeWithoutSeconds(item.created_at)}</div>
                    <div><b>Тип:</b> {item.dtp_type_name}</div>
                    <div><b>Количество погибших:</b> {item.dead?.toString?.() || '0'}</div>
                    <div><b>Количество раненых:</b> {item.wounded?.toString?.() || '0'}</div>
                    <div><b>Адрес:</b> {item.address_text || Object.keys(item.address || {}).length > 0
                        ? item.address_text || renderAddress(item.address)
                        : 'Информация отсутствует'}
                    </div>
                </ToolTip>
            </Marker>
        ));
    }, [data.dtp_list, typesAll]);

    return (
        <Modal
            isOpen={isOpen}
            title="Присвоенные ДТП"
            onClose={onClose}
            fullWidth
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            <Grid container sx={{ height: '100%' }} spacing={3}>
                <Grid item xs={6}>
                    <div className="map-form-wrap__container" style={{ height: '100%' }}>
                        <Map
                            center={[data.lat, data.lon]}
                            zoom={15}
                        >
                            {dtpMarkers}
                        </Map>
                    </div>
                </Grid>
                <Grid item xs={6} >
                    <DtpTable
                        list={data.dtp_list || []}
                    />
                </Grid>
            </Grid>

            {mapPopupProvider.isOpen && (
                <PopUpModal
                    isOpen={mapPopupProvider.isOpen}
                    typesAll={typesAll}
                    readOnly
                    data={mapPopupProvider.modal}
                    onClose={() => {
                        mapPopupProvider.clear();
                    }}
                />
            )}
        </Modal>
    );
};

export default DtpListModal;
