/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { setValidation } from 'redux/Validation/actions';
import { setMapLoading } from 'redux/Map/actions';
import { getHumanDate } from 'helpers/date.config';
import { getReport } from 'helpers/getReport';
import messages from 'helpers/constants/messages';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadDtpTypesSaga({ payload }) {
    yield put(actions.loadingDtpTypes(true));
    const response = yield call(api.getDtpTypes, payload);
    if (response) {
        yield put(actions.loadedDtpTypes(response));
    }
    yield put(actions.loadingDtpTypes(false));
}

function* editDtpTypeSaga({ payload }) {
    const response = yield call(api.editDtpType, payload);
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(actions.loadDtpTypes());
    }
}

function* loadDtpSaga({ payload }) {
    const { page, limit, data, replaceStore } = payload;
    const downloadFormat = data?.report || false;
    const config = downloadFormat
        ? { params: { ...data } }
        : { params: { page, limit, ...data } };

    yield put(actions.loading(true));
    const response = yield call(api.getDtpList, config);

    if (downloadFormat) {
        yield put(showMessage(messageTypes.success, response?.data?.join('')));
    }

    else if (response?.success) {
        yield put(actions.loadedDtpList(response, replaceStore));
    }

    yield put(actions.loading(false));
}

function* loadDtpTypesLoadSaga() {
    yield put(actions.loadingDtpTypes(true));
    const response = yield call(api.getDtpTypeLoad);
    if (response) {
        yield put(actions.loadedDtpLoadTypes(response.data));
    }
    yield put(actions.loadingDtpTypes(false));
}

function* loadDtpSettingsSaga({ payload }) {
    const { page, limit } = payload;
    yield put(actions.loadingDtpSettings(true));
    const response = yield call(api.getDtpSettings, page, limit);
    if (response.success) {
        yield put(actions.loadedDtpSettings(response));
    }
    yield put(actions.loadingDtpSettings(false));
}

function* loadDtpSettingByIdSaga({ payload }) {
    const response = yield call(api.getDtpSettingById, payload);
    if (response.success) {
        yield put(actions.loadedDtpSettingById(response.data));
    }
}

function* editDtpSettingSaga({ payload }) {
    const response = yield call(api.editDtpSetting, payload);
    if (response.success) {
        yield put(showMessage(messageTypes.success, 'Данные успешно сохранены'));
        yield put(actions.loadDtpSettings());
    }
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}

function* loadDtpGraphSaga({ payload }) {
    const { report, start_date, end_date } = payload;
    const downloadFormat = report || false;
    let period = start_date !== '' ? `_from_${getHumanDate(start_date)}` : '';
    period += end_date !== '' ? `_to_${getHumanDate(end_date)}` : '';
    const config = downloadFormat
        ? { params: payload, responseType: 'blob' }
        : { params: payload };

    yield put(actions.loadingDtpGraph(true));
    const response = yield call(api.getDtpReport, config);

    if (downloadFormat) {
        getReport(response, report, period);
    }

    if (response?.success) {
        yield put(actions.loadedDtpGraph(response));
    }
    yield put(actions.loadingDtpGraph(false));
}

function* loadDtpStreetsSaga({ payload }) {
    yield put(actions.loadingDtpStreets(true));
    const response = yield call(api.getDtpStreets, payload);
    if (response?.success) {
        yield put(actions.loadedDtpStreets(response));
    }
    yield put(actions.loadingDtpStreets(false));
}

function* loadDtpPolygonSaga({ payload }) {
    yield put(actions.loadingDtpPolygon(true));
    yield put(setMapLoading('dtp', true));

    const { polygon, params } = payload;
    const response = yield call(api.getPolygon, polygon, params);

    if (response?.success) {
        yield put(actions.loadedDtpPolygon(response?.data || []));
    }

    yield put(actions.loadingDtpPolygon(false));
    yield put(setMapLoading('dtp', false));

}

function* createNewDtpSaga({ payload }) {
    const { data, callback } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.createNewDtp, data);
    if (response?.success) {
        yield put(actions.saveDtp(response.success));
        callback && callback(response?.data?.id || null);
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
    }
    yield put(actions.loading(false));
}

function* editDtpSaga({ payload }) {
    const { id, data, callback } = payload;

    yield put(actions.loading(true));

    const response = yield call(api.editDtp, id, data);

    if (response?.success) {
        yield put(actions.saveDtp(response.success));
        callback && callback();
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
    }

    yield put(actions.loading(false));
}

function* deleteDtpSaga({ payload }) {
    const { id } = payload;
    yield put(actions.loading(true));
    const response = yield call(api.deleteDtp, id);
    if (response?.success) {
        yield put(actions.saveDtp(response.success));
        yield put(showMessage(messageTypes.success, 'ДТП удалено'));
    }
    yield put(actions.loading(false));
}

function* loadDtpByIdSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    yield put(actions.loading(true));
    const response = yield call(api.getDtpById, payload);
    if (response?.success) {
        yield fnCallback
            ? fnCallback(response?.data || {})
            : put(actions.loadedDtpById(response?.data || {}));
    }
    yield put(actions.loading(false));
    yield fnLoading?.(false);
}

function* loadDtpStreetListSaga({ payload }) {
    const params = payload;
    const response = yield call(api.loadDtpStreetList, params);
    if (response) {
        yield put(actions.loadedStreetList(response.data));
    }
}

function* loadDtpDatesSaga() {
    const response = yield call(api.loadDtpDates);
    if (response) {
        yield put(actions.loadedDtpDates(response.data));
    }
}

function* loadDtpStatusesSaga() {
    const response = yield call(api.getStatuses);
    if (response) {
        yield put(actions.loadedDtpStatuses(response.data));
    }
}

function* loadDtpSeveritySaga() {
    const response = yield call(api.getSeverity);
    if (response) {
        yield put(actions.loadedDtpSeverity(response.data));
    }
}

function* loadDtpReportByAreaSaga({ payload }) {
    yield put(actions.loadingDtpByArea(true));
    const response = yield call(api.getDtpReportByArea, payload);
    if (response) {
        yield put(actions.loadedDtpByArea(response.data));
    }
    yield put(actions.loadingDtpByArea(false));
}

function* loadDtpReportByPercentSaga({ payload }) {
    const { report, start_date, end_date } = payload;
    const downloadFormat = report || false;
    let period = start_date !== '' ? `_from_${getHumanDate(start_date)}` : '';
    period += end_date !== '' ? `_to_${getHumanDate(end_date)}` : '';
    const config = downloadFormat
        ? { params: payload, responseType: 'blob' }
        : { params: payload };

    yield put(actions.loadingDtpByPercent(true));

    const response = yield call(api.getDtpReportByPercent, config);

    if (downloadFormat) {
        getReport(response, report, period);
    }

    if (response?.success) {
        yield put(actions.loadedDtpByPercent(response.data));
    }
    yield put(actions.loadingDtpByPercent(false));
}

function* loadDangerousAreaSaga({ payload }) {
    const { report, start_date, end_date } = payload;
    const downloadFormat = report || false;
    let period = start_date !== '' ? `_from_${getHumanDate(start_date)}` : '';
    period += end_date !== '' ? `_to_${getHumanDate(end_date)}` : '';
    const config = downloadFormat
        ? { params: payload, responseType: 'blob' }
        : { params: payload };

    yield put(actions.loadingDangersArea(true));
    const response = yield call(api.getDangerousArea, { ...config, timeout: 300 * 1000 });

    if (downloadFormat && response) {
        getReport(response, report, period);
    }

    if (response?.success) {
        yield put(actions.loadedDangersArea(response.data));
    }
    yield put(actions.loadingDangersArea(false));
}

function* loadDtpFileSaga({ payload, callback }) {
    yield put(actions.loadingDtpFile(true));
    const response = yield call(api.loadDtpFile, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Файл загружен'));
        callback && callback();
    } else {
        yield put(showMessage(messageTypes.error, 'Загрузка не удалась. Попробуйте еще раз позднее'));
    }
    yield put(actions.loadingDtpFile(false));
}

function* reportMonthAreasSaga({ payload }) {
    yield put(actions.loadingReportMonthAreas(true));
    const response = yield call(api.reportMonthAreas, { params: payload });
    if (response?.success) {
        yield put(actions.loadedReportMonthAreas(response.data || {}));
    }
    yield put(actions.loadingReportMonthAreas(false));
}

function* reportStatisticSaga({ payload }) {
    yield put(actions.loadingReportStatistic(true));
    const response = yield call(api.reportStatistic, { ...payload });
    if (response?.success) {
        yield put(actions.loadedReportStatistic(response.data || {}));
    }
    yield put(actions.loadingReportStatistic(false));
}

function* reportByCategorySaga({ payload }) {
    yield put(actions.loadingReportByCategory(true));
    const response = yield call(api.reportByCategory, { ...payload });
    if (response?.success) {
        yield put(actions.loadedReportByCategory(response.data || []));
    }
    yield put(actions.loadingReportByCategory(false));
}

function* reportCategorySaga() {
    const response = yield call(api.reportCategory);
    if (response?.success) {
        yield put(actions.loadedReportCategory(response.data || {}));
    }
}

function* reportCategoryTableSaga({ payload }) {
    yield put(actions.loadingReportCategoryTable(true));
    const response = yield call(api.reportCategoryTable, payload);
    if (response?.success) {
        yield put(actions.loadedReportCategoryTable(response.data || []));
    }
    yield put(actions.loadingReportCategoryTable(false));
}

function* editStatusesSaga({ payload, callback }) {
    yield put(actions.loadingEditStatus(true));
    const response = yield call(api.editStatuses, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные сохранены'));
        callback();
    }
    yield put(actions.loadingEditStatus(false));
}

function* loadNewMapSaga({ payload }) {
    const { report, start_date, end_date } = payload;

    const downloadFormat = report || false;

    let period = start_date !== '' ? `_from_${getHumanDate(start_date)}` : '';
    period += end_date !== '' ? `_to_${getHumanDate(end_date)}` : '';

    yield put(actions.loadingNewMap(true));

    const response = yield call(api.newMap, payload,  { ...(downloadFormat && { responseType: 'blob', timeout: 300 * 1000 }) });

    if (downloadFormat) {
        getReport(response, report, period);
    }

    if (response?.success) {
        yield put(actions.loadedNewMap(response.data || []));
    }
    yield put(actions.loadingNewMap(false));
}

function* loadPopulationSaga({ payload }) {
    yield put(actions.loadingPopulation(true));
    const response = yield call(api.getPopulation, payload);
    if (response?.data) {
        yield put(actions.loadedPopulation(response));
    }
    yield put(actions.loadingPopulation(false));
}

function* createPopulationSaga({ payload, callback }) {
    const response = yield call(api.createPopulation, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
}

function* editPopulationSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editPopulation, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
}

function* deletePopulationSaga({ payload, callback }) {
    const response = yield call(api.deletePopulation, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}


function* loadConcentrationAreaSaga({ payload }) {
    yield put(actions.loadingConcentrationArea(true));
    const response = yield call(api.getConcentrationArea, payload);
    if (response?.data) {
        yield put(actions.loadedConcentrationArea(response));
    }
    yield put(actions.loadingConcentrationArea(false));
}

function* createConcentrationAreaSaga({ payload, callback }) {
    yield put(actions.loadingCEDConcentrationArea(true));
    const response = yield call(api.createConcentrationArea, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingCEDConcentrationArea(false));
}

function* editConcentrationAreaSaga({ payload, callback }) {
    yield put(actions.loadingCEDConcentrationArea(true));
    const { id, data } = payload;
    const response = yield call(api.editConcentrationArea, id, data);
    if (response?.success) {
        yield callback?.(response.data);
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
    }
    yield put(actions.loadingCEDConcentrationArea(false));
}

function* deleteConcentrationAreaSaga({ payload, callback }) {
    yield put(actions.loadingCEDConcentrationArea(true));
    const response = yield call(api.deleteConcentrationArea, payload);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingCEDConcentrationArea(false));
}

function* loadConcentrationAreaStatsSaga({ payload }) {
    const response = yield call(api.getConcentrationAreaStats, payload);
    if (response?.data) {
        yield put(actions.loadedConcentrationAreaStats(response.data));
    }
}

function* loadConcentrationAreaTypesSaga() {
    const response = yield call(api.getConcentrationAreaTypes);
    if (response?.data) {
        yield put(actions.loadedConcentrationAreaTypes(response.data));
    }
}

function* loadConcentrationAreaStatusesSaga() {
    const response = yield call(api.getConcentrationAreaStatuses);
    if (response?.data) {
        yield put(actions.loadedConcentrationAreaStatuses(response.data));
    }
}

function* loadDtpOnRadiusSaga({ payload }) {
    yield put(actions.loadingDtpOnRadius(true));
    const response = yield call(api.getDtpOnRadius, payload);
    if (response?.data) {
        yield put(actions.loadedDtpOnRadius(response.data));
    }
    yield put(actions.loadingDtpOnRadius(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadDtpSettingByNameSaga({ payload }) {
    const response = yield call(api.getDtpSettingByName, payload);
    if (response?.data) {
        yield put(actions.loadedDtpSettingByName(response.data));
    }
}

function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadDtpTypesAllSaga() {
    const response = yield call(api.getDtpTypesAll);
    if (response?.success) {
        yield put(actions.loadedDtpTypesAll(response.data || []));
    }
}

function* loadDtpByTimeSaga({ payload }) {
    yield put(actions.loadingDtpByTime(true));
    const response = yield call(api.getDtpByTime, payload);
    if (response?.data) {
        yield put(actions.loadedDtpByTime(response.data));
    }
    yield put(actions.loadingDtpByTime(false));
}

function* loadParticipantsSaga() {
    const response = yield call(api.getParticipants);
    if (response) {
        yield put(actions.loadedParticipants(response.data));
    }
}

function* loadGendersSaga() {
    const response = yield call(api.getGenders);
    if (response) {
        yield put(actions.loadedGenders(response.data));
    }
}

function* loadViolationsSaga() {
    const response = yield call(api.getViolations);
    if (response) {
        yield put(actions.loadedViolations(response.data));
    }
}

function* loadFastenedSaga() {
    const response = yield call(api.getFastened);
    if (response) {
        yield put(actions.loadedFastened(response.data));
    }
}

function* loadChildRestraintTypesSaga() {
    const response = yield call(api.getChildRestraintTypes);
    if (response) {
        yield put(actions.loadedChildRestraintTypes(response.data));
    }
}

function* loadPlacesOfDamageTypesSaga() {
    const response = yield call(api.getPlacesOfDamageTypes);
    if (response) {
        yield put(actions.loadedPlacesOfDamageTypes(response.data));
    }
}

function* loadOwnershipsSaga() {
    const response = yield call(api.getOwnerships);
    if (response) {
        yield put(actions.loadedOwnerships(response.data));
    }
}

function* loadRoadPurposeTypesSaga() {
    const response = yield call(api.getRoadPurposeTypes);
    if (response) {
        yield put(actions.loadedRoadPurposeTypes(response.data));
    }
}

function* loadRoadDefectTypesSaga() {
    const response = yield call(api.getRoadDefectTypes);
    if (response) {
        yield put(actions.loadedRoadDefectTypes(response.data));
    }
}

function* loadDrivingModeFactorTypesSaga() {
    const response = yield call(api.getDrivingModeFactorTypes);
    if (response) {
        yield put(actions.loadedDrivingModeFactorTypes(response.data));
    }
}

function* getDtpLinkTypesSaga() {
    const response = yield call(api.getDtpLinkTypes);
    if (response?.data) {
        yield put(actions.loadedDtpLinkTypes(response.data));
    }
}

function* getDtpLinkStatusesSaga() {
    const response = yield call(api.getDtpLinkStatuses);
    if (response?.data) {
        yield put(actions.loadedDtpLinkStatuses(response.data));
    }
}

function* getDtpLinkSaga({ payload: filter , fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getDtpLink, filter);
    if (response?.data) {
        yield fnCallback?.(response.data);
        // yield put(actions.loadedDtpByTime(response.data));
    }
    yield fnLoading?.(false);
}

function* createDtpLinkSaga({ payload , fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.createDtpLink, payload);
    if (response?.data) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* editDtpLinkSaga({ payload: { id, data } , fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.editDtpLink, id, data);
    if (response?.data) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* deleteDtpLinkSaga({ payload , fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.deleteDtpLink, payload);
    if (response?.data) {
        yield fnCallback?.();
        yield showMessage(messageTypes.success, messages.DELETE_SUCCESS);
    }
    yield fnLoading?.(false);
}

function* loadDtpLinkByIdSaga({ payload , fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getDtpLink, payload);
    if (response?.data) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

function* loadDtpLinkNewSaga({ payload, fnLoading, fnCallback }) {
    yield put(setMapLoading('dtp_links_new', true));
    yield fnLoading?.(true);
    const response = yield call(api.getDtpLinkNew, payload);
    if (response?.data) {
        yield fnCallback?.(response.data || []);
    }
    yield fnLoading?.(false);
    yield put(setMapLoading('dtp_links_new', false));
}

function* loadDtpByIdsSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getDtpByIds, payload);
    if (response?.success) {
        yield fnCallback?.(response?.data || []);
    }
    yield fnLoading?.(false);
}

function* loadDtpHistorySaga({ payload }) {
    yield put(actions.loadingDtpHistory(true));
    const response = yield call(api.getDtpHistory, payload.id, payload.params);
    if (response) {
        yield put(actions.loadedDtpHistory(response.data));
    }
    yield put(actions.loadingDtpHistory(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_DTP_TYPES, loadDtpTypesSaga),
        takeLatest(types.EDIT_DTP_TYPE, editDtpTypeSaga),
        takeLatest(types.LOAD_DTP_LIST, loadDtpSaga),
        takeLatest(types.LOAD_DTP_LOAD_TYPES, loadDtpTypesLoadSaga),
        takeLatest(types.LOAD_DTP_SETTINGS, loadDtpSettingsSaga),
        takeLatest(types.LOAD_DTP_SETTING_BY_ID, loadDtpSettingByIdSaga),
        takeLatest(types.EDIT_DTP_SETTING, editDtpSettingSaga),
        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),
        takeLatest(types.LOAD_DTP_GRAPH, loadDtpGraphSaga),
        takeLatest(types.LOAD_DTP_STREETS, loadDtpStreetsSaga),
        takeLatest(types.LOAD_DTP_POLYGON, loadDtpPolygonSaga),
        takeLatest(types.CREATE_NEW_DTP, createNewDtpSaga),
        takeLatest(types.EDIT_DTP, editDtpSaga),
        takeLatest(types.DELETE_DTP, deleteDtpSaga),
        takeLatest(types.LOAD_DTP_BY_ID, loadDtpByIdSaga),
        takeLatest(types.LOAD_DTP_STREET_LIST, loadDtpStreetListSaga),
        takeLatest(types.LOAD_DTP_DATES, loadDtpDatesSaga),
        takeLatest(types.LOAD_DTP_STATUSES, loadDtpStatusesSaga),
        takeLatest(types.LOAD_DTP_SEVERITY, loadDtpSeveritySaga),
        takeLatest(types.LOAD_DTP_BY_AREA, loadDtpReportByAreaSaga),
        takeLatest(types.LOAD_DTP_BY_PERCENT, loadDtpReportByPercentSaga),
        takeLatest(types.LOAD_DANGERS_AREA, loadDangerousAreaSaga),
        takeLatest(types.LOAD_DTP_FILE, loadDtpFileSaga),
        takeLatest(types.LOAD_DTP_REPORT_MONTH_AREAS, reportMonthAreasSaga),
        takeLatest(types.LOAD_DTP_REPORT_STATISTIC, reportStatisticSaga),
        takeLatest(types.LOAD_DTP_REPORT_BY_CATEGORY, reportByCategorySaga),
        takeLatest(types.LOAD_DTP_REPORT_CATEGORY, reportCategorySaga),
        takeLatest(types.LOAD_DTP_REPORT_CATEGORY_TABLE, reportCategoryTableSaga),

        takeLatest(types.EDIT_DTP_STATUSES, editStatusesSaga),
        takeLatest(types.LOAD_NEW_MAP, loadNewMapSaga),

        takeLatest(types.LOAD_POPULATION, loadPopulationSaga),
        takeLatest(types.CREATE_POPULATION, createPopulationSaga),
        takeLatest(types.EDIT_POPULATION, editPopulationSaga),
        takeLatest(types.DELETE_POPULATION, deletePopulationSaga),

        takeLatest(types.LOAD_CONCENTRATION_AREA, loadConcentrationAreaSaga),
        takeLatest(types.CREATE_CONCENTRATION_AREA, createConcentrationAreaSaga),
        takeLatest(types.EDIT_CONCENTRATION_AREA, editConcentrationAreaSaga),
        takeLatest(types.DELETE_CONCENTRATION_AREA, deleteConcentrationAreaSaga),

        takeLatest(types.LOAD_CONCENTRATION_AREA_STATS, loadConcentrationAreaStatsSaga),
        takeLatest(types.LOAD_CONCENTRATION_AREA_TYPES, loadConcentrationAreaTypesSaga),
        takeLatest(types.LOAD_CONCENTRATION_AREA_STATUSES, loadConcentrationAreaStatusesSaga),
        takeLatest(types.LOAD_DTP_ON_RADIUS, loadDtpOnRadiusSaga),

        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),
        takeLatest(types.LOAD_DTP_SETTING_BY_NAME, loadDtpSettingByNameSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_DTP_TYPES_ALL, loadDtpTypesAllSaga),

        takeLatest(types.LOAD_DTP_BY_TIME, loadDtpByTimeSaga),

        takeLatest(types.LOAD_PARTICIPANTS, loadParticipantsSaga),
        takeLatest(types.LOAD_GENDERS, loadGendersSaga),
        takeLatest(types.LOAD_VIOLATIONS, loadViolationsSaga),
        takeLatest(types.LOAD_FASTENED, loadFastenedSaga),
        takeLatest(types.LOAD_CHILD_RESTRAINT_TYPES, loadChildRestraintTypesSaga),
        takeLatest(types.LOAD_PLACES_OF_DAMAGE_TYPES, loadPlacesOfDamageTypesSaga),
        takeLatest(types.LOAD_OWNERSHIPS, loadOwnershipsSaga),
        takeLatest(types.LOAD_ROAD_PURPOSE_TYPES, loadRoadPurposeTypesSaga),
        takeLatest(types.LOAD_ROAD_DEFECT_TYPES, loadRoadDefectTypesSaga),
        takeLatest(types.LOAD_DRIVING_MODE_FACTOR_TYPES, loadDrivingModeFactorTypesSaga),

        takeLatest(types.LOAD_DTP_LINK_TYPES, getDtpLinkTypesSaga),
        takeLatest(types.LOAD_DTP_LINK_STATUSES, getDtpLinkStatusesSaga),
        takeLatest(types.LOAD_DTP_LINK_LIST, getDtpLinkSaga),
        takeLatest(types.CREATE_DTP_LINK, createDtpLinkSaga),
        takeLatest(types.EDIT_DTP_LINK, editDtpLinkSaga),
        takeLatest(types.DELETE_DTP_LINK, deleteDtpLinkSaga),
        takeLatest(types.LOAD_DTP_LINK_BY_ID, loadDtpLinkByIdSaga),
        takeLatest(types.LOAD_DTP_LINK_NEW, loadDtpLinkNewSaga),
        takeLatest(types.LOAD_DTP_BY_IDS, loadDtpByIdsSaga),
        takeLatest(types.LOAD_DTP_HISTORY, loadDtpHistorySaga),
    ]);
};
