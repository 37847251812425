import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiReactMethods = {
    getIncidents: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getIncidents(), {
            params: { page, limit, ...params }
        });
        return response?.data;
    },
    getIncidentById: async (id) => {
        const response = await instance.get(apiUrls.getIncidentById(id));
        return response?.data;
    },

    createIncident: async (data) => {
        const response = await instance.post(apiUrls.createIncident(), data);
        return response?.data;
    },

    editIncident: async (id, data) => {
        const response = await instance.put(apiUrls.editIncident(id), data);
        return response?.data;
    },

    deleteIncident: async (id) => {
        const response = await instance.delete(apiUrls.deleteIncident(id));
        return response?.data;
    },

    getIncidentSidebar: async (params) => {
        const response = await instance.get(apiUrls.getIncidents(), { params });
        return response?.data;
    },

    getIncidentPolygon: async (params) => {
        const response = await instance.post(apiUrls.getIncidentPolygon(), params);
        return response.data;
    },

    getIncidentHeatMap: async (params) => {
        const response = await instance.post(apiUrls.getIncidentHeatMap(), params);
        return response.data;
    },

    getIncidentsStatuses: async () => {
        const response = await instance.get(apiUrls.getIncidentsStatuses());

        return response?.data;
    },

    getThreatLevels: async (params) => {
        const response = await instance.get(apiUrls.getThreatLevels(), { params });
        return response?.data;
    },

    getCategories: async () => {
        const response = await instance.get(apiUrls.getVehicleCategories());
        return response.data;
    },

    getIncidentEvents: async (id) => {
        const response = await instance.get(apiUrls.getIncidentEvents(id));
        return response?.data;
    },

    getIncidentsFactors: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getIncidentsFactors(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    getTypicalOperations: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getTypicalOperations(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    createTypicalOperation: async (data) => {
        const response = await instance.post(apiUrls.createTypicalOperation(), data);
        return response?.data;
    },

    editTypicalOperation: async (id, data) => {
        const response = await instance.put(apiUrls.editTypicalOperation(id), data);
        return response?.data;
    },

    deleteTypicalOperation: async (id) => {
        const response = await instance.delete(apiUrls.deleteTypicalOperation(id));
        return response?.data;
    },

    createIncidentsFactors: async (data) => {
        const response = await instance.post(apiUrls.createIncidentsFactors(), data);
        return response?.data;
    },

    editIncidentsFactors: async (id, data) => {
        const response = await instance.put(apiUrls.editIncidentsFactors(id), data);
        return response?.data;
    },

    deleteIncidentsFactors: async (id) => {
        const response = await instance.delete(apiUrls.deleteIncidentsFactors(id));
        return response?.data;
    },

    getScenarios: async (params) => {
        const response = await instance.get(apiUrls.getScenarios(), { params });
        return response?.data;
    },

    getPhenomenons: async (params) => {
        const response = await instance.get(apiUrls.getPhenomenons(), { params });
        return response?.data;
    },

    getTriggerStatuses: async () => {
        const response = await instance.get(apiUrls.getTriggerStatuses());
        return response?.data;
    },

    getTriggers: async (params) => {
        const response = await instance.post(apiUrls.getTriggers(), { ...params });
        return response?.data;
    },

    createTrigger: async (params) => {
        const response = await instance.post(apiUrls.createTrigger(), { ...params });
        return response?.data;
    },

    editTrigger: async (id, params) => {
        const response = await instance.put(apiUrls.editTrigger(id), { ...params });
        return response?.data;
    },

    deleteTrigger: async (id) => {
        const response = await instance.delete(apiUrls.deleteTrigger(id));
        return response?.data;
    },

    getPolygonForTriggers: async (url, params) => {
        const response = await instance.post(apiUrls.getPolygonForTriggers(url), { ...params });
        return response?.data;
    },

    getIncidentTypes: async (params) => {
        const response = await instance.get(apiUrls.getIncidentTypes(), { params });
        return response?.data;
    },

    createIncidentType: async (params) => {
        const response = await instance.post(apiUrls.createIncidentTypes(), { ...params });
        return response?.data;
    },

    editIncidentType: async (id, params) => {
        const response = await instance.put(apiUrls.editIncidentTypes(id), { ...params });
        return response?.data;
    },

    deleteIncidentType: async (id) => {
        const response = await instance.delete(apiUrls.deleteIncidentTypes(id));
        return response?.data;
    },

    getIncidentTypeById: async (id) => {
        const response = await instance.get(apiUrls.getIncidentTypeById(id));
        return response?.data;
    },

    getKeywordList: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getKeyWordList(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },

    editKeyWord: async (id, data) => {
        const response = await instance.put(apiUrls.editKeyWord(id), data);
        return response?.data;
    },

    addKeyWord: async (data) => {
        const response = await instance.post(apiUrls.addKeyWord(), data);
        return response?.data;
    },

    deleteKeyWord: async (id) => {
        const response = await instance.delete(apiUrls.deleteKeyWord(id));
        return response?.data;
    },
};

export default ApiReactMethods;
