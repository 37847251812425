import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import {
    clearDtpSettingByName,
    loadDtpOnRadius,
    loadDtpSettingByName,
} from 'redux/Incidents/actions';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import {
    Circle,
    FeatureGroup,
    Map,
    MapControl,
    Marker,
    ToolTip
} from 'components/MapComponents/leaflet';
import { createIconMarker } from 'components/MapComponents/Layers/Incidents/helper';
import { config } from 'config';

const MapForm = ({
    value,
    mainDtp,
    onChange,
    multiply,
}) => {

    const dispatch = useDispatch();

    const nearDtpList = useSelector(incidentsSelectors.dtpOnRadius);
    const settingByName = useSelector(incidentsSelectors.settingByName);

    const selectedDtp = multiply
        ? value
        : value && Object.keys(value || {}).length > 0
            ? [value]
            : [];

    useEffect(() => {
        // запрос настройки радиуса
        dispatch(loadDtpSettingByName('dca_radius_on_map'));

        return () => {
            dispatch(clearDtpSettingByName());
        };
    }, []);

    // загрузка дтп в радиусе по координатам
    useEffect(() => {
        const {
            lat = null,
            lon = null,
        } = mainDtp || {};
        if (lat !== null && lon !== null) {
            dispatch(loadDtpOnRadius({ lat, lon, is_link: true }));
        }
    }, [mainDtp]);

    const CreateMarker = ({
        item,
        color,
        onClick,
        ...props
    }) => (
        <Marker
            {...props}
            icon={createIconMarker(color)}
            latlng={[item.lat, item.lon]}
            attribution={{ color }}
            onClick={onClick}
        >
            <ToolTip
                offset={[0, -30]}
                direction="top"
            >
                <div><strong>ID: </strong>{item.id || ''}</div>
                <div><strong>{titles.DTP_TYPE}: </strong>{item.dtp_type_name || ''}</div>
                <div><strong>Дата/время: </strong>{fullDateTimeWithoutSeconds(item.dtp_at) || ''}</div>
            </ToolTip>
        </Marker>
    );

    const renderMainDtp = useMemo(() => {
        if (mainDtp) {
            return (
                <CreateMarker
                    item={mainDtp}
                    color="#000000"
                />
            );
        }
    }, [mainDtp]);

    // загруженные маркеры
    const nearDtpMarkers = useMemo(() => {
        const color = 'rgba(103,106,103,0.62)';

        // id выбранных дтп
        const selectDtpIds = selectedDtp?.map(i => parseInt(i?.id));

        return nearDtpList.reduce((res, item) => {
            if (
                !selectDtpIds.includes(parseInt(item.id))
                && item.id !== mainDtp?.id
            ) {
                res.push(
                    <CreateMarker
                        key={item.id}
                        item={item}
                        color={color}
                        onClick={() => {
                            onChange(
                                multiply
                                    ? [...selectedDtp, item]
                                    : item
                            );
                        }}
                    />
                );
            }
            return res;
        }, []);
    }, [nearDtpList, selectedDtp, onChange, multiply, mainDtp]);

    // выбранные маркеры
    const selectedDtpMarkers = useMemo(() => {
        const color = '#4b9c4b';
        return selectedDtp?.map((item) => (
            <CreateMarker
                key={item.id}
                item={item}
                color={color}
                onClick={() => {
                    onChange(
                        multiply
                            ? selectedDtp.filter(el => el.id !== item.id)
                            : {}
                    );
                }}
            />
        ));
    }, [selectedDtp, onChange, multiply]);

    const centerMain = useMemo(() => {
        return mainDtp ? [mainDtp.lat, mainDtp.lon] : null;
    }, [mainDtp]);

    const centerConfig = config.get('mapCenter');

    return (
        <div
            style={{
                minWidth: '60%',
                minHeight: '400px',
                height: '100%',
                marginRight: 20,
            }}
        >
            <Map
                center={centerMain || centerConfig}
                zoom={17}
            >
                <MapControl>
                    <FeatureGroup>
                        {renderMainDtp}
                        {nearDtpMarkers}
                        {selectedDtpMarkers}

                        {settingByName?.value && centerMain && (
                            <Circle
                                latlng={centerMain}
                                color="#676a679e"
                                radius={settingByName?.value}
                            />
                        )}
                    </FeatureGroup>
                </MapControl>
            </Map>
        </div>
    );
};

export default MapForm;
