import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadDtpByIds, loadDtpLinkById, saveDtp } from 'redux/Incidents/actions';
import TabList from 'components/MapComponents/TabList';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';

import LinkFormModal from '../../../forms/LinkFormModal';

import Link from './Link';

const Links = ({
    data,
}) => {

    const dispatch = useDispatch();

    // связи
    const [links, setLinks] = useState([]);
    // открыть форму создания
    const [modalCreateForm, setModalCreateForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const handleLoaded = (linksList) => {
        setLinks(linksList);
        setIsLoaded(true);

        if (linksList?.length > 0) {
            // id дтп
            const ids = linksList.reduce((r, i) => {
                const {
                    dtp_id,
                    linked_dtp_id,
                } = i;

                if (!r.includes(dtp_id)) r.push(dtp_id);
                if (!r.includes(linked_dtp_id)) r.push(linked_dtp_id);

                return r;
            }, []);

            // догрузка данных по дтп
            dispatch(loadDtpByIds(
                ids,
                (dtpList) => {
                    const dtp = dtpList.reduce((r, i) => ({ ...r, [i.id]: i }), {});

                    setLinks((old) => old.map(item => ({
                        ...item,
                        dtp: {
                            ...item.dtp,
                            ...(dtp[item.dtp_id] || {})
                        },
                        linked_dtp: {
                            ...item.linked_dtp,
                            ...(dtp[item.linked_dtp_id] || {})
                        }
                    })));
                }
            ));
        }
    };

    const handleRefresh = () => {
        dispatch(loadDtpLinkById(
            [data.id],
            handleLoaded,
            setLoading
        ));

        dispatch(saveDtp(true));
    };

    useEffect(() => {
        // загрузка связей
        if (data.id) {
            dispatch(loadDtpLinkById(
                [data.id],
                handleLoaded,
                setLoading
            ));
        }
    }, [data?.id, dispatch]);

    const linksList = links.map(item => ({
        key: item.id,
        title: item.id,
        component: (
            <Link
                link={item}
                currentDtpId={data.id}
                onRefresh={handleRefresh}
            />
        )
    }));

    const count = links.length;

    return (
        <>
            {loading && <Loading circular/>}

            {isLoaded && (
                <>
                    {count === 0
                        ? (
                            <div style={{ padding: '1rem' }}>
                                <FormButtons
                                    buttons={[
                                        {
                                            ...buttonsTypes.create,
                                            onClick: () => setModalCreateForm(true),
                                        }
                                    ]}
                                    // noMarginLeft
                                    justButton
                                />

                                <div>Нет данных</div>
                            </div>
                        )
                        : (
                            <>
                                {count > 1 // если прийдет больше 1 линка
                                    ? (
                                        <TabList list={linksList}/>
                                    )
                                    : (
                                        <Link
                                            link={links?.[0] || {}}
                                            currentDtpId={data.id}
                                            onRefresh={handleRefresh}
                                        />
                                    )
                                }

                            </>
                        )
                    }
                </>
            )}

            {/* форма создания линка */}
            {modalCreateForm && (
                <LinkFormModal
                    isOpen={modalCreateForm}
                    data={{
                        dtp_id: data.id,
                        dtp: data,
                    }}
                    onClose={() => setModalCreateForm(false)}
                    onSave={handleRefresh}
                />
            )}
        </>
    );
};

export default Links;
