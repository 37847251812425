import messages from 'helpers/constants/messages';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import CircleStatus from 'components/common/CircleStatus';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import { reactSelectors } from 'modules/React';
import { loadIncidentsStatuses } from 'modules/React/redux/actions';
import renderAddress from 'helpers/renderAddress';

import type { IncidentItem as IncidentItemType } from './types';

type IncidentItemProps = {
  item: IncidentItemType
  onClickItem: () => void
  selectedId: number | null
}

export const IncidentItem = ({ 
    item, 
    onClickItem, 
    selectedId 
}: IncidentItemProps) => {
    const statuses = useStoreFromSelectorListToObject(loadIncidentsStatuses,reactSelectors.incidentsStatuses);

    const renderThreatLevel = () => item?.threat_level?.color
        ? <span style={{ color: item?.threat_level.color }}>{ item?.threat_level?.name }</span>
        : item?.threat_level?.name;

    const fields = [
        fullDateTimeWithoutSeconds(item.created_at),
        fullDateTimeWithoutSeconds(item.date_plan),
        fullDateTimeWithoutSeconds(item.date_fact),
        item.address_text || renderAddress(item.address || {}),
        item?.type_text,
        renderThreatLevel(),
    ];

    return (
        <>
            <LSContentItem 
                onClick={onClickItem} 
                isMobile={!!selectedId}
                needFocus={selectedId === item?.id}
            >
                <LSContentColumn>
                    <CircleStatus 
                        title={statuses.get(item?.status_id).name}
                        color={statuses.get(item?.status_id).color} 
                    />
                </LSContentColumn>
                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                {!selectedId && fields.map((el, i) => (
                    <LSContentColumn key={i}>
                        {el || messages.NO_VALUE}
                    </LSContentColumn>
                ))}
                <LSContentColumn isActions>
                    <ActionMore 
                        isPassport
                        isOpen={selectedId === item.id} 
                        onClick={onClickItem} 
                    />
                </LSContentColumn>
            </LSContentItem>
        </>
    );
};
