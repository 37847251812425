import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Pie } from 'react-chartjs-2';

import { incidentsSelectors } from 'redux/Incidents';

const Graph = () => {
    const dtpReportByPercent = useSelector(incidentsSelectors.dtpReportByPercent);

    const getLabel = (context) => {
        const label = context?.formattedValue || '';
        const count = dtpReportByPercent?.[context?.dataIndex]?.count || '';
        return ` ${label}${label && '%'} (${count}${count && 'шт.'})`;
    };

    const toolTipTitle = (tooltipItems) => {
        return tooltipItems[0]?.dataset.label[tooltipItems[0]?.dataIndex];
    };

    const options = {
        maintainAspectRatio: false,
        indexAxis: 'y',
        redraw: false,
        plugins: {
            tooltip: {
                mode: 'point',
                usePointStyle: true,
                callbacks: {
                    label: getLabel,
                    title: toolTipTitle
                }
            },
            legend: {
                align: 'center',
                position: 'bottom',
                labels: {
                    boxWidth: 20,
                }
            }
        },
    };

    return (
        <>
            {dtpReportByPercent.map((item) =>
                <div>
                    <h1 style={{ textAlign: 'center' }}>{item.type}</h1>
                    <div>
                        <Pie
                            key={item.type}
                            width={'400px'}
                            height={'400px'}
                            data={{
                                labels: item.data?.map(({ dtp_type_name }) => dtp_type_name),
                                datasets: [{
                                    data: item.data?.map(({ percent }) => percent),
                
                                    maxBarThickness: 80,
                                    label: item.data?.map(({ dtp_type_name }) => dtp_type_name),
                                    backgroundColor: item.data?.map(({ dtp_type_color }) => dtp_type_color || '#000'),
                                    borderColor: '#fff',
                                }],
                            }}
                            options={options}
                            type="Pie"
                        />
                    </div>
                </div> 
            )}
        </>
    );
};

export default memo(Graph);