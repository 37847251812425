import { useState } from 'react';
import { useDispatch } from 'react-redux';

import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import { editIncident, loadIncidentsStatuses } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';

import type { IncidentItem } from '../../types';

interface StatusModalProps {
    isOpen: boolean;
    onClose: () => void;
    item: IncidentItem;
    reloadList: () => void;
}

const StatusModal = ({ isOpen, onClose, item, reloadList }: StatusModalProps) => {
    const dispatch = useDispatch();

    const statuses = useStoreFromSelectorListToObject(
        loadIncidentsStatuses,
        reactSelectors.incidentsStatuses
    );
    
    const [data, setData] = useState({ id: item.status_id, name: item.status_text });

    const onSave = () => {
        dispatch(editIncident(
            item.id, 
            removeEmptyFields({ ...item, status_id: data.id }), 
            () => { reloadList(); onClose(); }
        ));
    };
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding={true}
            small={true}
            title="Изменение статуса"
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose,
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <CommonAutocomplete
                    label={titles.STATUS}
                    selected={data}
                    onChange={setData as any}
                    options={statuses.list as any}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderLabel={(option) => option?.name || ''}
                    showAsSelect
                />
            </form>
        </Modal>
    );
};

export default StatusModal;
