import { useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';

import { loadMilestoneEvents } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import SelectCompany from 'components/common/Autocomplete/Companies';
import SelectRoutes from 'components/common/Autocomplete/PassengerTransport/Routes';
import SelectTrafficSchedule from 'components/common/Autocomplete/PassengerTransport/TrafficSchedule';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({
    setSelect,
    setParams,
    initialState,
    disabledFields = [],
    setFiltersValues,
    filterValues,
    isSearched,
    setTrafficSchedule
}) => {
    const milestoneEvents = useStoreProp(loadMilestoneEvents,'transportPassenger','milestoneEvents');

    const [isDateInvalid, setIsDateInvalid] = useState(false);

    const handleChange = (value, name, isMulti = false) => {
        setFiltersValues({
            ...filterValues,
            [name]: isMulti ? [...filterValues[name], value] : value
        });
    };

    const handleChangeTrafficSchedules = (value, name) => {
        setFiltersValues({
            ...filterValues,
            [name]: value
        });
    };

    const resetFilters = (needRefresh) => {
        if (needRefresh || isSearched) {
            setParams(initialState);
            setTrafficSchedule([]);
        }
        setFiltersValues(initialState);
        setSelect({});
    };

    const updateFilter = (filters) => {
        setFiltersValues({
            ...initialState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setParams({
                ...initialState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={() => setParams(filterValues)}
            filter={filterValues}
            setUserFilter={updateFilter}
            disabled={isDateInvalid
                || !filterValues.start_date_at
                || !filterValues.end_date_at
            }
            filterException={['start_date_at', 'end_date_at',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    handleDate={(value) => handleChange(value, 'start_date_at')}
                    handleEndDate={(value) => handleChange(value, 'end_date_at')}
                    valueStartDate={filterValues.start_date_at}
                    valueEndDate={filterValues.end_date_at}
                    dateOnly
                    isDisabledHandler={setIsDateInvalid}
                    disableStartField={disabledFields.includes('start_date_at')}
                    disableEndField={disabledFields.includes('end_date_at')}
                    equalDates
                />
                <SelectTrafficSchedule
                    selected={filterValues.traffic_schedule_ids}
                    onChange={(value) => handleChangeTrafficSchedules(value, 'traffic_schedule_ids')}
                    multiple
                    isDisabled={disabledFields.includes('traffic_schedule_ids')}
                />
                <SelectRoutes
                    selected={filterValues.route}
                    onChange={(value) => handleChange(value, 'route')}
                    multiple={true}
                    disabled={disabledFields.includes('route')}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <SelectVehicles
                    multiple
                    selected={filterValues.grz}
                    onChange={(value) => handleChange(value, 'grz')}
                    label="Номер ТС"
                    disabled={disabledFields.includes('grz')}
                />
                <SelectCompany
                    multiple
                    selected={filterValues.organization_id}
                    onChange={(value) => handleChange(value, 'organization_id')}
                    filter={{ withDeleted: 1 }}
                    selectDeletedСompanies
                    disabled={disabledFields.includes('organization_id')}
                />
                <FormControl variant="outlined" size="small">
                    <Autocomplete
                        multiple
                        value={filterValues.milestone_event_id_list || []}
                        options={Object.keys(milestoneEvents)?.map(id => ({ id, label: milestoneEvents[id] }))}
                        noOptionsText={messages.NO_DATA}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        filterSelectedOptions
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label || ''}
                        size="small"
                        onChange={(e, newValues) => handleChange(newValues, 'milestone_event_id_list')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Контрольные события"
                            />
                        )}
                        disabled={disabledFields.includes('milestone_event_id_list')}
                    />
                </FormControl>
                <TextField
                    value={filterValues.id}
                    onChange={({ target : { value, name } }) => handleChange(value, name)}
                    name="id"
                    label="ID наряда"
                    variant="outlined"
                    size="small"
                    type="number"
                    disabled={disabledFields.includes('id')}
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
