import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import L from 'leaflet';

import { metroSelectors } from 'modules/Metro';
import * as actions from 'modules/Metro/redux/actions';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { usePopModalListener } from 'components/MapComponents/MapPopUp';

import PopUpModal from './PopUpModal';
import { createIconMarker } from './helper.js';
import config from './config.js';

const Layer = (props) => {
    const { map } = props;

    const dispatch = useDispatch();

    const polygon = useSelector(metroSelectors.vestibulePolygon);
    const active = useSelector(metroSelectors.active);
    const filters = useSelector(metroSelectors.filters);

    const popupListener = usePopModalListener();

    const [geoJson, setGeoJson] = useState([]);
    const prevFilters = usePrevious(filters) || {};
    const loadingPolygon = useRef(true);

    useWsSubscribe('transport-metro_vestibule_load_telemetry_v2', (events) => {
        if (events.length > 0) dispatch(actions.changeColorVestibulePolygon(events));
    });

    // грузим полигон
    const fetchPolygon = () => {
        if (loadingPolygon.current) {
            const polygon = mapHelper.getGeometryPolygon(map);
            const filter = getFilters(filters);
            dispatch(actions.loadVestibulePolygon(polygon, filter));
        }
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        fetchPolygon();

        return () => {
            dispatch(actions.clearActive());
        };
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.clearActive());
            // dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, prevFilters]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length > 0) {

            const hasCoordinates = active?.geometry?.geometry?.coordinates?.length > 0;
            if (hasCoordinates) {
                const center = L.geoJSON(active?.geometry)?.getBounds?.().getCenter?.();
                if (center) map.setView(center, 18);
            }
        }
    }, [active]);

    useEffect(() => {
        const geoJson = polygon.reduce((res, { entrances, color_load, ...props }) => {
            return [
                ...res,
                ...(entrances.map(item => ({
                    ...item.geometry,
                    properties: {
                        ...props,
                        color_load: color_load
                    },
                    attribution: {
                        slug: config.slug,
                        color: color_load
                    },
                    style: {
                        color: color_load,
                        weight: 7
                    }
                })))
            ];
        }, []);

        setGeoJson(geoJson);
    }, [polygon]);

    return (
        <>
            <GeoJsonNew
                {...props}
                data={geoJson}
                toolTipTemplate={({ name }) => <div>{name ?? ''}</div>}
                icon={({ color_load }) => createIconMarker(color_load)}
                idPrefix={config.slug}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -36]
                }}
                onClick={(item) => popupListener.setModal(item.id)}
            />

            {/* popup */}
            {popupListener.isOpen && (
                <PopUpModal
                    isOpen={popupListener.isOpen}
                    id={popupListener.modal}
                    onClose={() => {
                        dispatch(actions.clearActive());
                        popupListener.clear();
                    }}
                />
            )}
        </>
    );
};

export default Layer;
