import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import Companies from 'components/common/Autocomplete/Companies';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import AddressFields from 'components/common/Location/AddressFields';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import { isDateValid } from 'helpers/date.config';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import SelectUsers from 'components/common/Autocomplete/Users';
import Attachments from 'components/common/Upload/Attachments';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';
import StatusesSelect from 'components/common/Autocomplete/Statuses';
import {
    loadIncidentsFactors,
    loadIncidentsStatuses,
    loadIncidentTypeById,
    loadVehicleCategories,
} from 'modules/React/redux/actions';
import TitleValidator from 'components/common/Location/TitleValidator';
import FieldsModal from 'components/common/Location/FieldsModal';
import { buttonsTypes } from 'components/common/FormButtons';
import TransportTypes from 'components/common/Autocomplete/Transport/Types';
import SelectFactorIncident from 'modules/React/utils/components/SelectIncidentFactors';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';
import InfoBlock from 'components/common/InfoBlock';
import type { IncidentType } from 'modules/React/Dictionaries/IncidentsTypes/types';
import type { IncidentItem } from 'modules/React/Dictionaries/Incidents/types';
import type { Factor } from 'modules/React/Dictionaries/IncidentFactors/types';

import { FactorsList } from './FactorsList';


type BasicDataProps = {
  data?: IncidentItem;
  isNew?: boolean;
  isMkDtp?: boolean,
  setData: (item: any) => void,
}

export const BasicData = ({ data = {}, setData = () => {}, isNew = false, isMkDtp = false }: BasicDataProps) => {
    const dispatch = useDispatch();

    //TODO дописать типы
    const [type, setType] = useState<IncidentType | null>(null);
    const [dependence, setDependence] = useState(true);

    const validation = useValidation();
    const statuses = useStoreProp(loadIncidentsStatuses, 'react', 'incidentsStatuses');
    let categories = useStoreProp(loadVehicleCategories, 'react', 'vehicleCategories');
    categories = Object.keys(categories).map(key => ({ id: Number(key), name: categories[key] }));

    const [factorsList, setFactorsList] = useState<Factor[]>([]);

    const changeFactors = (array: Factor[]) => {
        return array.map((f) => ({
            ...f,
            ...(f.is_geo
                ? {
                    comment: f.comment || 'Отсутствуют необходимые сведения',
                    geo_json: f.geo_json || null
                }
                : {}
            ) //список всех факторов из типа с is_default - добавляем им коммент и геозону
        }));
    };

    const updateDataFactors = useCallback((list?: Factor[], typeChanged = true) => {
        if (list && list.length > 0) {
            // обновился factorsList - надо из него взять те,
            // которых еще нет в инциденте и у которых is_default и добавить в инцидент
            // typeChanged - если поменяли тип то не надо объединять с уже имеющимися у инцидента факторами
            setData((prev: any) => {
                const newArray = prev.factors.length > 0 && !typeChanged
                    ? prev.factors
                    : list.filter((elem: Factor) => elem.is_default);

                // && !prev.factors.find((f: Factor) => f.id === elem.id))

                const newFactors = changeFactors(newArray);

                return ({
                    ...prev,
                    factors: newFactors
                });
            });
        } else {
            setData((prev: any) => ({
                ...prev,
                factors: []
            }));
        }
    }, [setData]);


    useEffect(() => {
        if (data.type_id && data.type_id !== type?.id) {
            dispatch(loadIncidentTypeById(data.type_id, (el: IncidentType) => {
                setType(el); // заполняем поле тип
                setFactorsList(el.factors); //список всех факторов типа - их будем выбирать в универсальном селекте
                updateDataFactors(el.factors, false);
            }));
        }
    }, [data.type_id, dispatch, updateDataFactors, type]);

    //TODO дописать типы
    const dependenceHandler = (event: any) => {
        setDependence(event.target.checked);
    };

    const onChangeGeoJsonCoordinates = (params: any) => {
        setData((prevData: any) => ({
            ...prevData,
            ...params,
        }));
        validation.deleteKeys(Object.keys(params || {}));
    };

    const onChange = (name: string, value: any) => {
        setData((prev: any) => ({
            ...prev,
            [name]: value
        }));

        validation.deleteKey(name);
    };

    const onChangeFactors = (value: Factor[] | null) => {
        setData((prev: any) => ({
            ...prev,
            factors: value ? changeFactors(value) : value
        }));

        validation.deleteKey('factors');
    };

    const handleChangeIncidentTypes = (e: IncidentType | null) => {
        setType(e);
        if (e) {
            setData({
                ...data,
                type_id: e?.id,
                type_text: e?.name,
                vehicle_types: [],
            });
            setFactorsList(e.factors);
            validation.deleteKey('type_id');
            updateDataFactors(e.factors);
        } else {
            setData({
                ...data,
                type_id: null,
                type_text: '',
                vehicle_types: [],
            });
            setFactorsList([]);
            updateDataFactors([]);
        }
    };

    const checkInvalidDate = useCallback((date) => {
        return date && !isDateValid(date);
    }, []);

    const handleChangeDocuments = (files: any) => {
        setData({
            ...data,
            attachments: files
        });
    };

    const getLatLonGeometry = data.lat && data.lon
        ? {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    data.lon,
                    data.lat,
                ],
            }
        }
        : null;

    return (
        <form className="modal__form">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <div className="block">
                        <FormInfoWrapper error={!data.name || !!validation.isKey('name')} helperText={validation.get('name')}>
                            <TextField
                                label={titles.NAME}
                                size="small"
                                value={data.name}
                                variant="outlined"
                                name="name"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                required={true}
                                error={!data.name || !!validation.isKey('name')}
                                disabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                            />
                        </FormInfoWrapper>
                    </div>

                    <div className="block">
                        <StatusesSelect
                            //@ts-ignore
                            selected={statuses.find(el => el.id === data.status_id)}
                            label="Статус"
                            options={statuses}
                            disabled={isNew}
                            renderLabel={(option) => option?.name || ''}
                            //@ts-ignore
                            onChange={(value) => onChange('status_id', value?.id)}
                            helperText={validation.get('status_id')}
                            error={validation.isKey('status_id')}
                        />
                    </div>

                    <div className="block">
                        <SelectIncidentTypes
                            selected={type}
                            error={!data?.type_id || !!validation.isKey('type_id')}
                            helperText={validation.get('type_id')}
                            onChange={(el) => handleChangeIncidentTypes(el as (IncidentType | null))}
                            required
                            disabled={!isNew ||  isMkDtp}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('registered_at', date)}
                            value={data.registered_at}
                            label="Дата/время инцидента"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            required
                            isDisabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                            error={!data.registered_at
                                        || checkInvalidDate(data.registered_at)
                                        || validation.isKey('registered_at')}
                            helperText={validation.get('registered_at')}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('date_plan', date)}
                            value={data.date_plan}
                            label="Дата/время решения инцидента план"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            isDisabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                            helperText={validation.get('date_plan')}
                            error={validation.isKey('date_plan')}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('date_fact', date)}
                            value={data.date_fact}
                            label="Дата/время решения инцидента факт"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            isDisabled={true}
                            helperText={validation.get('date_fact')}
                            error={validation.isKey('date_fact')}
                        />
                    </div>

                    <div className="block">
                        <SingleKeyboardDateTimePicker
                            onChange={date => onChange('created_at', date)}
                            value={data.created_at}
                            label="Дата/время регистрации"
                            pickerFormat="dd.MM.yyyy HH:mm"
                            isDisabled={true}
                            helperText={validation.get('created_at')}
                            error={validation.isKey('created_at')}
                        />
                    </div>

                    <div className="block">
                        <FormInfoWrapper error={!data.description || validation.isKey('description')} helperText={validation.get('description')}>
                            <TextField
                                label={titles.DESCRIPTION}
                                size="small"
                                value={data.description}
                                variant="outlined"
                                name="description"
                                type="text"
                                onChange={(e) => onChange(e.target.name, e.target.value)}
                                required={true}
                                error={!data.description || validation.isKey('description')}
                                disabled={Number(data.status_id) === 3}
                            />
                        </FormInfoWrapper>
                    </div>

                    <div className="block">
                        <SelectThreatLevels
                            required={true}
                            selected={data?.threat_level || { id: 0, name: '' }}
                            helperText={validation.get('threat_level')}
                            error={!data?.threat_level || validation.isKey('threat_level')}
                            onChange={(value: any) => onChange('threat_level', value)}
                        />
                    </div>

                    {
                        !isNew && <div className="block">
                            <FormInfoWrapper error={!!validation.isKey('source')} helperText={validation.get('source')}>
                                <TextField
                                    disabled
                                    label={titles.DATA_SOURCE}
                                    size="small"
                                    value={data.source}
                                    variant="outlined"
                                    name="source"
                                    type="text"
                                    onChange={(e) => onChange(e.target.name, e.target.value)}
                                />
                            </FormInfoWrapper>
                        </div>
                    }

                    <TransportTypes
                        multiple
                        label={titles.TYPE_OF_VEHICLE}
                        selected={data.vehicle_types || []}
                        onChange={(el) => onChange('vehicle_types', el)}
                        options={categories}
                        helperText={validation.get('vehicle_types')}
                        error={validation.isKey('vehicle_types')}
                    />

                    <div className="block">
                        <Companies
                            multiple
                            filter={{ withDeleted: 1 }}
                            label="Ответственная организация"
                            selected={data.organizations}
                            onChange={(value) => { onChange('organizations', value);}}
                            helperText={validation.get('organizations')}
                            error={validation.isKey('organizations')}
                        />
                    </div>

                    <div className="block">
                        <SelectUsers
                            multiple
                            selected={data.user}
                            onChange={(value) => onChange('user', value)}
                            helperText={validation.get('user_id')}
                            error={validation.isKey('user_id')}
                            disabled={Number(data.status_id) === 3 || data.organizations?.length === 0}
                            label={titles.EMPLOYEES}
                            filter={{ org_ids: data.organizations?.map(el => el.id) }}
                        />
                    </div>

                    <FormControl className="block" size="small" variant="outlined">
                        <SelectUsers
                            multiple
                            selected={data.dispatcher}
                            onChange={(value) => onChange('dispatcher', value)}
                            helperText={validation.get('user_id') || 'Пример: "ФИО Диспетчера"'}
                            error={data.dispatcher.length === 0 || validation.isKey('user_id')}
                            required={true}
                            // disabled={Number(data.status_id) === 3 || data.organizations?.length === 0}
                            label={'Диспетчер'}
                            filter={{ org_ids: data.organizations?.map(el => el.id) }}
                        />
                    </FormControl>

                    <FormInfoWrapper>
                        <FormControl
                            variant="outlined"
                            size="small"
                            className="block"
                            required={true}
                            error={!Number.isInteger(data?.resolve_rules) || validation.isKey('resolve_rules')}
                        >
                            <InputLabel>Правило закрытия</InputLabel>
                            <Select
                                value={data.resolve_rules}
                                name="resolve_rules"
                                //@ts-ignore
                                onChange={(event) => onChange('resolve_rules', event.target.value)}
                                label="Правило закрытия"
                            >
                                <MenuItem value={1}>Вручную</MenuItem>
                                <MenuItem value={2}>Автоматом</MenuItem>
                            </Select>
                        </FormControl>
                    </FormInfoWrapper>

                    <InfoBlock label="Пользовательские факторы"  mt="1rem">

                        <div className="block">
                            <SelectFactorIncident
                                multiple
                                required={factorsList.length > 0}
                                error={!data?.type_id || validation.isKey('factors')}
                                selected={data?.factors || []}
                                sortedList={factorsList}
                                disabled={!data?.type_id || factorsList.length === 0}
                                onChange={(value) => onChangeFactors(value as (Factor[] | null))}
                                helperText={!data?.type_id
                                    ? 'Необходимо выбрать тип инцидента'
                                    : factorsList.length === 0
                                        ? 'Для выбранного типа инцидента не добавлено ни одного фактора'
                                        : validation.get('factors')
                                }
                            />
                        </div>

                        <div className="block">
                            <FactorsList
                                factors={data.factors}
                                onChange={(factors) => setData({ ...data, factors })}
                                visibleGeometry={getLatLonGeometry}
                            />
                        </div>
                    </InfoBlock>
                </Grid>

                <Grid item xs={6}>
                    <FormControlLabel
                        style={{ pointerEvents: 'none' }}
                        control={
                            <Checkbox
                                disabled={Number(data.status_id) === 3 || Number(data?.type_creation) === 2}
                                checked={dependence}
                                onChange={dependenceHandler}
                                color="primary"
                                style={{ pointerEvents: 'auto' }}
                            />
                        }
                        label="Адрес зависит от координат"
                    />

                    <FormControl className="block" variant="outlined">
                        {/* lat lon */}
                        <LoadAddressByCoords
                            {...data}
                            isLoadOnLatLon={false}
                            isLoadOnChange={dependence}
                            onChange={onChangeGeoJsonCoordinates}
                        >
                            <LatLonCoordinates required/>
                        </LoadAddressByCoords>
                    </FormControl>

                    <FormControl className="block" variant="outlined">
                        {/* address */}
                        <AddressFields
                            {...data}
                            //@ts-ignore
                            onChange={(props) => onChangeGeoJsonCoordinates(props)}
                            returnLatLon={dependence}
                            returnAddressText={dependence}
                            returnGeometry={Object.keys(data.geometry || {}).length === 0 || dependence}
                        />
                    </FormControl>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {JSON.stringify(data.geometry) === '{}'
                  && <h2 style={{ color: 'red' }}>Укажите транспортный инцидент*</h2>}
                    </div>

                    <FormControl className="block" variant="outlined" error={true}>
                        <LoadAddressByCoords
                            {...data}
                            onChange={onChangeGeoJsonCoordinates}
                            isLoadOnLatLon={dependence}
                            isLatLonColumnView={true} // такого поля нет
                        >
                            {/* @ts-ignore */}
                            <AddressList required />
                        </LoadAddressByCoords>
                    </FormControl>

                    <FormControl className="block" variant="outlined">
                        <MapDragMarker
                            {...data}
                            onChange={onChangeGeoJsonCoordinates}
                            required
                        />
                    </FormControl>

                    <div className="block">
                        <TitleValidator
                            fields={data}
                            validationFields={['area']}
                        >
                        Зона действия *:
                        </TitleValidator>
                        {validation.isKey('check_point.geometry')
                            ? (
                                <FormHelperText className="error">
                                    {validation.get('check_point.geometry')}
                                </FormHelperText>
                            )
                            : null
                        }
                        {/* //@ts-ignore */}
                        <FieldsModal
                            title="Выбрать область на карте"
                            fields={{ geometry: data?.geometry || {} }}
                            buttonText={isNew ? 'Выбрать' : 'Изменить'}
                            //@ts-ignore
                            buttonType={buttonsTypes.selectOnMap}
                            buttonVariant="contained"
                            onChange={({ geometry }: any) => {
                                onChange('geometry', geometry);
                                validation.deleteKeys(['area.geometry.coordinates.0', 'check_point.geometry']);
                            }}
                        >
                            <MapGeoJson
                                visibleGeometry={getLatLonGeometry}
                                circle={true}
                                polygon={true}
                                polyline={false}
                                marker={false}
                                isHideValidation
                            />
                        </FieldsModal>

                    </div>
                    <Divider style={{ margin: '0.5rem 0' }}/>
                    <Attachments
                        //@ts-ignore
                        onChange={handleChangeDocuments}
                        files={data?.attachments}
                        readOnly={Number(data.status_id) === 3}
                        service="situational-plans"
                        onlyLink={false}
                    />
                </Grid>
            </Grid>
        </form>
    );
};
