import { useMemo } from 'react';
import { Grid } from '@mui/material';

import { config } from 'config';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Information from 'components/common/Information';
import MapScroll from 'components/MapComponents/MapScroll';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';
import {
    GeoJson,
    Map,
    MapControl,
} from 'components/MapComponents/leaflet';
import { MapPopUp }  from 'components/MapComponents/MapPopUp';

import configLayer from '../config';
import { createIcon, createIconMarker, getColor } from '../helper';


const PopUpModal = ({
    data,
    isOpen,
    onClose
}) => {

    const {
        name = '',
        address_text = '',
        address = {}
    } = data || {};

    const selectedItemGeo = useMemo(() => data
        ? {
            ...data.geometry,
            properties: {
                ...data.geometry.properties,
                data: data,
                attribution: {
                    slug: configLayer.slug,
                    color: getColor(data)
                },
            },
            style: {
                color: getColor(data),
                weight: 7
            }
        }
        : null
    , [data]);

    const renderInfo = () => (
        <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} md={6}>
                <MapScroll>
                    <Information
                        data={data}
                        title={{
                            name: {
                                title: 'Название',
                            },
                            type: {
                                title: 'Тип объекта',
                                value: ({ type }) => type?.name || messages.INFO_IS_NOT_FOUND
                            },
                            address: {
                                title: 'Адрес',
                                value: ({ address, address_text }) => {
                                    if (address) {
                                        return address_text || getAddressStringFromObject(address);
                                    }
                                    return null;
                                }
                            },
                            latlon: {
                                title: titles.COORDINATES,
                                value: ({ lat, lon }) => lat && lon ? `${lat} / ${lon}` : null
                            },
                            organization: {
                                title: titles.ORGANIZATION,
                                value: ({ organization }) => organization?.title
                            },
                            description: {
                                title: titles.DESCRIPTION,
                                value: ({ description }) => description
                            },
                            /*
                                created_at: {
                                    title: 'Дата создания',
                                    value: ({ created_at }) =>
                                            created_at
                                                ? getHumanDate(created_at)
                                                : messages.INFO_IS_NOT_FOUND,
                                    },
                                    fixated_at: {
                                        title: 'Дата регистрации',
                                        value: ({ fixated_at }) =>
                                            fixated_at
                                                ? getHumanDate(fixated_at)
                                                : messages.INFO_IS_NOT_FOUND,
                                    },
                            */
                        }}
                    />
                </MapScroll>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: '1rem 1rem .5rem' }}>
                <Map
                    center={config.get('mapCenter')}
                    zoom={15}
                >
                    <MapControl>
                        {selectedItemGeo
                            ? <GeoJson
                                data={selectedItemGeo}
                                // если вдруг прилетел маркер
                                icon={item => createIconMarker(item)}
                                centerAfter={true}
                            />
                            : null
                        }
                    </MapControl>
                </Map>

            </Grid>
        </Grid>
    );

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={<span className="inner-icon" dangerouslySetInnerHTML={{ __html: createIcon('#fff') }}/>}
            title={name || address_text || getAddressStringFromObject(address) || messages.NO_DATA}
            onClose={onClose}
            titleBackgroundColor={getColor(data)}
            medium
        >
            {renderInfo()}
        </MapPopUp>
    );
};

export default PopUpModal;
