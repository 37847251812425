import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    changeRolesContracts,
    createRolesContracts,
    deleteRolesContracts
} from 'redux/Companies/actions';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

import Item from './Item';

const RolesContractsModal = ({ isOpen, onClose, item = {}, reloadList }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState(item.role_contracts);

    const handleAdd = () => {
        setData([
            ...data,
            {
                role_id: '',
                role: {},
                organization_id: item.id,
                contract_number: '',
                start_date: null,
                end_date: null,
                attachments: []
            }
        ]);
    };

    const handleChange = (index, value) => {
        const newData = [
            ...data.slice(0, index),
            value,
            ...data.slice(index + 1)
        ];
        setData(newData);
    };

    const handleDelete = (index) => {
        setData([
            ...data.slice(0, index),
            ...data.slice(index + 1)
        ]);
    };

    const handleSave = () => {
        const prepareData = data.map(el => (
            removeEmptyFields({
                ...el,
                role_id: el.role?.id,
                start_date: dateWithDashYYYYMMDD(el.start_date),
                end_date: dateWithDashYYYYMMDD(el.end_date)
            },false)
        ));

        const callback = () => {
            onClose();
            reloadList();
        };

        prepareData.length > 0
            ? item.role_contracts.length > 0
                ? dispatch(changeRolesContracts({ role_contracts: prepareData }, callback))
                : dispatch(createRolesContracts({ role_contracts: prepareData }, callback))
            : dispatch(deleteRolesContracts({ organization_id: item.id }, callback));
    };

    const isDisabled = data.some(el => (!el.role?.id
        || el.start_date > el.end_date
        || (el.end_date > new Date())
    ));

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            title="Роли и договора"
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: isDisabled
                        }
                    ]}
                />
            }
        >
            <div className="modal__form">
                {data?.map((item, index) => (
                    <Item
                        key={index}
                        item={item}
                        onChange={(value) => handleChange(index, value)}
                        onDelete={() => handleDelete(index)}
                    />
                ))}
                <FormButtons
                    noPadding
                    positionLeft
                    noMarginLeft
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: handleAdd,
                        }
                    ]}
                />
            </div>
        </Modal>
    );
};

export default RolesContractsModal;
