import createIconCluster from 'components/MapComponents/helpers/createIconCluster';

const config = {
    name: 'Инциденты',
    slug: 'react-incidents',
    clusterIcon: () =>  createIconCluster('fas fa-car-bump'),
    layerIcon: 'far fa-car-bump',
};

export default config;
