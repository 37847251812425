import { useMemo, useState } from 'react';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { Autocomplete } from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { loadRegions } from 'redux/Dadata/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import GovernmentContractList from 'components/common/Autocomplete/GovernmentContract';
import SimpleSelect from 'components/common/SimpleSelect';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';

const Filter = ({ data, onChange, complexStatuses }) => {
    const regionsAll = useStoreProp(loadRegions, 'dadata', 'regions');

    const [area, setArea] = useState();
    const [district, setDistrict] = useState();

    const handleChangeArea = (value) => {
        setArea(value);
        onChange({ area: value?.name, district: '' });
    };

    const handleChangeDistrict = (value) => {
        setDistrict(value);
        onChange({ district: value?.name });
    };

    const getValue = (key, def = '') => data[key] || def;

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    const cities = useMemo(() => {
        if (data?.area) {
            const area = regionsAll?.areas?.find((item) => data?.area === item.name);
            return regionsAll?.cities?.filter((city) => area?.id === city.parent_id);
        }

        return regionsAll?.cities || [];
    }, [data?.area, regionsAll]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    label={titles.NAME}
                    size="small"
                    value={data.name || ''}
                    variant="outlined"
                    name="name"
                    type="text"
                    onChange={(e) => onChange({ [e.target.name]: e.target.value })}
                />
            </div>

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    openText={'Открыть'}
                    closeText={'Закрыть'}
                    multiple
                    value={getValue('status', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={complexStatuses}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('status')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.STATUS}
                        />
                    )}
                />
            </div>

            <div className="row">
                <Autocomplete
                    style={{ width: '100%' }}
                    multiple
                    value={getValue('serial_number', [])}
                    options={[]}
                    filterSelectedOptions={true}
                    freeSolo={true}
                    size="small"
                    onChange={handleEvent('serial_number')}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Серийный номер"
                        />
                    )}
                />
                <Tooltip
                    // title={titles.WRITE_TRANSPORT_NUMBER}
                    title={'Ввод серийного номера целиком (после ввода нажмите Enter чтобы добавить)'}
                    style={{ marginLeft: '.5rem', marginTop: '.2rem' }}
                >
                    <IconButton size="small">
                        <ErrorOutline />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="block">
                <TextField
                    label={'IP-адрес'}
                    value={data.ip_route || ''}
                    name="ip_route"
                    onChange={(e) => onChange({ [e.target.name]: e?.target?.value?.trim() })}
                />
            </div>

            <div className="block">
                <GovernmentContractList
                    label="Госконтракт"
                    multiple
                    selected={data.contract_id || []}
                    onChange={(value) => onChange({ contract_id: value })}
                    renderLabel={item => item?.name || ''}
                    limitTags={4}
                />
            </div>

            <SelectComplex
                multiple={true}
                selected={data.complexes_ids}
                onChange={(value) => onChange({ complexes_ids: value })}
                className="block"
            />

            <div className="block">
                <SimpleSelect
                    label="Район области"
                    selected={area}
                    onChange={handleChangeArea}
                    options={Array.isArray(regionsAll.areas) ? regionsAll.areas : []}
                    renderLabel={(option) => option?.name || ''}
                />
            </div>

            <div className="block">
                <SimpleSelect
                    label="Район города"
                    selected={district}
                    onChange={handleChangeDistrict}
                    options={Array.isArray(cities) ? cities : []}
                    renderLabel={(option) => option?.name || ''}
                />
            </div>
        </form>
    );
};

export default Filter;
