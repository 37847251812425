import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import * as actions from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import Loading from 'components/common/Loading';
import { Cluster, FeatureGroup, Map, MapControl } from 'components/MapComponents/leaflet';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import HeatLayer from 'components/MapComponents/leaflet/components/HeatLayer';
import IncidentsLayer from 'components/MapComponents/Layers/Incidents/layer';
import PageLayout from 'components/layout/PageLayout';
import Legend from 'components/MapComponents/Layers/Incidents/Legend';
import MapLegends from 'components/common/Transport/MapLegends';
import { fullDateTimeWithTimeZone, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';
import messages from 'helpers/constants/messages';
import useNewMap from 'helpers/hooks/Incidents/useNewMap';
import { config } from 'config';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';

import Filter from './Filter';

// страница тепловая карта по дтп
const DangerousArea = () => {
    const dataProvider = useNewMap();
    const dispatch = useDispatch();

    const polygonLoading = useSelector(incidentsSelectors.polygonLoading);
    const dtp_types = useSelector(incidentsSelectors.dtp_types);

    const initialState = {
        type_load: [],
        start_date: getStartOf('day', getDateWithDuration({ month: -1 })),
        end_date: getEndOf(),
        with_wounded: false,
        with_dead: false,
        dtp_type: [],
    };

    const [showLayer, setShowLayer] = useState(false);
    const [filter, setFilter] = useState(initialState);
    const [filterPolygon, setFilterPolygon] = useState(initialState);

    const handleChangeFilter = (formValues) => {
        const newFilter = getFilter({ ...filter, ...formValues });
        dispatch(actions.loadNewMap(newFilter));
        setFilterPolygon(filter);
    };

    useEffect(() => {
        if (dtp_types?.data?.length === 0) {
            dispatch(actions.loadDtpTypes());
        }
    }, []);

    const errorDateRange = () => {
        const {
            start_date = null,
            end_date = null,
        } = filter;

        return !start_date || !end_date;
    };

    const getFilter = (formValues) => {
        const filters = {
            start_date: fullDateTimeWithTimeZone(formValues?.start_date),
            end_date: fullDateTimeWithTimeZone(formValues?.end_date),
            type_load: formValues?.type_load?.map(({ id }) => id) || [],
            type_load: formValues?.type_load?.map(({ id }) => Number(id)) || [],
            dtp_type: formValues?.dtp_type?.map(({ id }) => id) || [],
        };
        if (formValues.with_wounded) filters.with_wounded = 1;
        if (formValues.with_dead) filters.with_dead = 1;

        return removeEmptyFields(filters);
    };

    const onResetFilter = (needRefresh) => {
        if (needRefresh) {
            handleChangeFilter(initialState);
        }
        setFilter(initialState);
        setFilterPolygon(initialState);
    };

    const renderContent = () => (
        <>
            {(polygonLoading || dataProvider.loading) && <Loading circular/>}
            {(dataProvider?.data?.length === 0 && !dataProvider.loading) && (
                <div
                    style={{
                        padding: '.5rem',
                        textAlign: 'center'
                    }}
                >
                    {messages.DATA_IS_NOT_FOUND}
                </div>
            )}

            <Map
                center={config.get('mapCenter')}
            >
                <MapControl>
                    <FeatureGroup>
                        {/* тепловая */}
                        <HeatLayer
                            json={dataProvider.data}
                        />

                        {/* слой */}
                        {showLayer
                            && (
                                <Cluster
                                    iconCreateFunction={iconCreateFunctionSimple}
                                    disableClusteringAtZoom={15}
                                    maxClusterRadius={10}
                                >
                                    <IncidentsLayer readOnly filter={filterPolygon}/>

                                    <MapLegends visibleRequired={true}>
                                        <Legend/>
                                    </MapLegends>
                                </Cluster>
                            )
                        }
                    </FeatureGroup>
                </MapControl>
            </Map>
        </>
    );

    return (
        <PageLayout
            header="Тепловая карта по ДТП"
            filters={
                <Filter
                    onChange={handleChangeFilter}
                    filter={filter}
                    setFilter={setFilter}
                    errorDateRange={errorDateRange}
                    resetFilter={onResetFilter}
                    initialState={initialState}
                />
            }
            actionPanel={<FormControlLabel
                control={
                    <Checkbox
                        onClick={(e) => setShowLayer(e.target.checked)}
                        checked={showLayer}
                        size="small"
                    />
                }
                label="Показать иконки ДТП"
            />}
            informPanelProps={{
                buttons: (
                    <PageReportModal>
                        <PageReportBlock filter={getFilter(filter)}/>
                    </PageReportModal>
                )
            }}
            content={renderContent}
            customStyles={{
                overflow: 'auto',
                padding: 0,
                background: 'transparent'
            }}
        />
    );
};

export default DangerousArea;