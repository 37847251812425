import { useState } from 'react';
import {
    Box,
    FormHelperText,
    IconButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { checkBase64 } from 'helpers/transport.types';
import buttons from 'helpers/constants/buttons';
import ImagePreview from 'components/common/ImagePreview';

const UploadImageField = ({
    value,
    name,
    label = 'Изображение',
    onChange,
    errorText = '',
    helperText = 'Размер изображения должен быть более 150х150 пикселей и менее 1000х1000 пикселей',
    imageErrorValidation = (img) => img.width > 1000 || img.height > 1000 || img.width < 150 || img.height < 150,
    imageSize = 50,
    required = false,
    showDeleteIcon = false,
    disabled = false,
    isSelectBorder = false,
    color = false, // TODO: как-нибудь разобраться с аргументом color, по сути string поумолчанию boolean
    acceptFiles='.jpg,.jpeg,.png',
    showPreview = false
}) => {
    const [isError, setIsError] = useState(false);

    const handleUploadImage = (files) => {
        if (files.length) {
            const reader = new FileReader();
            const blobFile = new Blob(files, { type: 'image/png' });
            const img = new Image();

            img.onload = () => {
                const isErrorValid = imageErrorValidation(img);
                setIsError(isErrorValid);
                onChange(name, isErrorValid ? '' : img.src);
            };
            reader.onloadend = () => {
                img.src = reader.result.toString();
            };

            reader.readAsDataURL(blobFile);
        }
    };

    const handleDelete = () => {
        onChange(name, '');
    };

    const imgUrl = checkBase64(value);

    const sxOption = {
        background: color,
        width: `${imageSize}px`,
        height: `${imageSize}px`,
        padding: '10%',
        borderRadius: '50%'
    };

    return (
        <>
            <ListItemIcon >
                <Box
                    sx={imgUrl && !isSelectBorder && !isError
                        ? sxOption
                        : { ...sxOption, border: '1px solid #999' }
                    }
                >
                    {!isError && (
                        <ImagePreview 
                            src={imgUrl}
                            showPreview={showPreview && !!value}
                        />
                    )}
                </Box>
            </ListItemIcon>

            <ListItemText>
                <span className={required && !value ? 'error' : ''}>
                    {label}{required && ' *'}
                </span>
                {errorText && (
                    <FormHelperText error>
                        {errorText}
                    </FormHelperText>
                )}
                {helperText && (
                    <FormHelperText {...isError ? { error: true } : {}}>
                        {helperText}
                    </FormHelperText>
                )}
            </ListItemText>
            
            <IconButton 
                aria-label="upload picture"
                component="label"
                disabled={disabled}
                size="small"
            >
                <input
                    hidden
                    accept={acceptFiles}
                    type="file"
                    onChange={(e) => handleUploadImage(e?.target?.files)}
                    value=""
                />
                <Tooltip title={buttons.SELECT}>
                    <i className="far fa-paperclip" />
                </Tooltip>
            </IconButton>

            {showDeleteIcon && !!value  && (
                <IconButton onClick={handleDelete} size="small">
                    <Tooltip title={buttons.DELETE}>
                        <HighlightOffIcon fontSize="small" />
                    </Tooltip>
                </IconButton>
            )}
        </>
    );
};

export default UploadImageField;