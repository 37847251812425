import { config } from 'config';

const apiDtpUrl = () => config.get('urls').apiDtpUrl;

const apiUrls = {
    getDtpTypes: () => `${apiDtpUrl()}/dtp/types`,

    editDtpType: () => `${apiDtpUrl()}/dtp/types/color`,

    getDtpList: () => `${apiDtpUrl()}/dtp`,

    getDtpTypeLoad: () => `${apiDtpUrl()}/dtp/load/types`,

    getDtpSettings: () => `${apiDtpUrl()}/settings`,

    getDtpSettingById: (id) => `${apiDtpUrl()}/settings/${id}`,

    editDtpSetting: (id) => `${apiDtpUrl()}/settings/${id}`,

    editSettingsList: () => `${apiDtpUrl()}/settings/list`,

    getDtpReport: () => `${apiDtpUrl()}/report`,

    getDtpStreets: () => `${apiDtpUrl()}/dtp/streets`,

    getPolygon: () => `${apiDtpUrl()}/dtp/polygon`,

    createNewDtp: () => `${apiDtpUrl()}/dtp`,

    editDtp: (id) => `${apiDtpUrl()}/dtp/${id}`,

    deleteDtp: (id) => `${apiDtpUrl()}/dtp/${id}`,

    getDtpById: (id) => `${apiDtpUrl()}/dtp/${id}`,

    loadDtpStreetList: () => `${apiDtpUrl()}/dtp/streets`,

    loadDtpDates: () => `${apiDtpUrl()}/dtp/dates`,

    getStatuses: () => `${apiDtpUrl()}/dtp/statuses`,

    getSeverity: () => `${apiDtpUrl()}/dtp/severity`,

    getDtpReportByArea: () => `${apiDtpUrl()}/report/by-area`,

    getDtpReportByPercent: () => `${apiDtpUrl()}/report/by-percent`,

    getDangerousArea: () => `${apiDtpUrl()}/dtp/dangerous-areas`,

    loadDtpFile: () => `${apiDtpUrl()}/dtp/upload`,

    reportMonthAreas: () => `${apiDtpUrl()}/dtp/month-areas`,

    reportStatistic: () => `${apiDtpUrl()}/report/statistics`,

    reportByCategory: () => `${apiDtpUrl()}/dtp/polygon/category`,

    reportCategory: () => `${apiDtpUrl()}/dtp/categories`,

    reportCategoryTable: () => `${apiDtpUrl()}/dtp/polygon/categories`,

    editStatuses: () => `${apiDtpUrl()}/dtp/status`,

    newMap: () => `${apiDtpUrl()}/v2/dtp/heat-map`,

    getPopulation: () => `${apiDtpUrl()}/population`,

    createPopulation: () => `${apiDtpUrl()}/population`,

    editPopulation: (id) => `${apiDtpUrl()}/population/${id}`,

    deletePopulation: (id) => `${apiDtpUrl()}/population/${id}`,

    getConcentrationArea: () => `${apiDtpUrl()}/dtp-concentration-area`,

    createConcentrationArea: () => `${apiDtpUrl()}/dtp-concentration-area`,

    editConcentrationArea: (id) => `${apiDtpUrl()}/dtp-concentration-area/${id}`,

    deleteConcentrationArea: (id) => `${apiDtpUrl()}/dtp-concentration-area/${id}`,

    getConcentrationAreaTypes: () => `${apiDtpUrl()}/v2/dtp-concentration-area/types`,

    getConcentrationAreaStatuses: () => `${apiDtpUrl()}/v2/dtp-concentration-area/statuses`,

    getConcentrationAreaStatistics: () => `${apiDtpUrl()}/dtp-concentration-area/statistics`,

    getDtpOnRadius: () => `${apiDtpUrl()}/dtp/radius`,

    loadShowcase: () => `${apiDtpUrl()}/showcase`,

    getDtpSettingByName: () => `${apiDtpUrl()}/settings/by-name`,

    getSettingsGroup: () => `${apiDtpUrl()}/settings_group`,

    editSettingsGroup: (id) => `${apiDtpUrl()}/settings_group/${id}/settings/update`,

    getDtpTypesAll: () => `${apiDtpUrl()}/dtp/types`,

    getDtpByTime: () => `${apiDtpUrl()}/dtp/by-time`,

    getParticipants: () => `${apiDtpUrl()}/dtp/participants`,

    getGenders: () => `${apiDtpUrl()}/dtp/genders`,

    getViolations: () => `${apiDtpUrl()}/dtp/violations`,

    getFastened: () => `${apiDtpUrl()}/dtp/fastened`,

    getChildRestraintTypes: () => `${apiDtpUrl()}/dtp-member/child-restraint-types`,

    getPlacesOfDamageTypes: () => `${apiDtpUrl()}/dtp-vehicle/places-of-damage-types`,

    getOwnerships: () => `${apiDtpUrl()}/dtp/ownerships`,

    getRoadPurposeTypes: () => `${apiDtpUrl()}/dtp/road-purpose-types`,

    getRoadDefectTypes: () => `${apiDtpUrl()}/dtp/road-defect-types`,

    getDrivingModeFactorTypes: () => `${apiDtpUrl()}/dtp/driving-mode-influence-factor-types`,

    getDtpByIds: () => `${apiDtpUrl()}/dtp`,

    getDtpLinkTypes: () => `${apiDtpUrl()}/dtp-link/types`,

    getDtpLinkStatuses: () => `${apiDtpUrl()}/dtp-link/statuses`,

    getDtpLink: () => `${apiDtpUrl()}/dtp-link`,

    createDtpLink: () => `${apiDtpUrl()}/dtp-link`,

    editDtpLink: (id) => `${apiDtpUrl()}/dtp-link/${id}`,

    deleteDtpLink: (id) => `${apiDtpUrl()}/dtp-link${id}`,

    getDtpLinkNew: () => `${apiDtpUrl()}/dtp-link/new`,
    
    getDtpHistory: (id) => `${apiDtpUrl()}/dtp/${id}/history`,
};

export default apiUrls;
