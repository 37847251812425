import * as types from './types';

const initialState = {
    incidents: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingIncidents: false,


    incidentSidebar: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingIncidentSidebar: false,
    needReloadIncidentSidebar: false,

    incidentPolygon: [],
    loadingIncidentPolygon: false,

    active: {},
    filters: {},

    incidentHeatMap: [],
    loadingIncidentHeatMap: false,

    incidentsStatuses: [],

    threatLevels: [],
    loadingThreatLevels: false,

    incidentTypes: {
        data:[],
        meta: {
            last_page: 0,
        }
    },
    loadingIncidentTypes: false,

    incidentFactors: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingIncidentFactors: false,

    typicalOperations: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    loadingTypicalOperations: false,

    vehicleCategories: {},
    vehicleCategoriesLoaded: false,

    incidentEvents: [],
    loadingIncidentEvents: false,

    scenarios: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingScenarios: false,

    phenomenons: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingPhenomenons: false,

    triggerStatuses: [],
    triggers: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingTriggers: false,
    keyWords: {
        data: [],
        meta: {
            last_page: 0,
        }
    },
    keyWordsLoading: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_INCIDENTS:
            return {
                ...state,
                incidents: payload,
            };
        case types.LOADING_INCIDENTS:
            return {
                ...state,
                loadingIncidents: payload,
            };

        case types.LOADED_INCIDENT_SIDEBAR: {
            const { data, isReplace } = payload;

            if (isReplace) {
                return {
                    ...state,
                    incidentSidebar: data,
                };
            }

            return {
                ...state,
                incidentSidebar: {
                    ...state.incidentSidebar,
                    ...data,
                    data: [
                        ...state.incidentSidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_INCIDENT_SIDEBAR:
            return {
                ...state,
                loadingIncidentSidebar: payload,
            };

        case types.NEED_RELOAD_INCIDENT_SIDEBAR:
            return {
                ...state,
                needReloadIncidentSidebar: true,
            };

        case types.CLEAR_INCIDENT_SIDEBAR:
            return {
                ...state,
                incidentSidebar: initialState.incidentSidebar,
            };

        case types.LOADED_INCIDENT_POLYGON:
            return {
                ...state,
                incidentPolygon: payload
            };

        case types.LOADING_INCIDENT_POLYGON:
            return {
                ...state,
                loadingIncidentPolygon: payload
            };

        case types.SET_ACTIVE:
            return {
                ...state,
                active: payload,
            };

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload
            };

        case types.LOADED_INCIDENT_HEAT_MAP:
            return {
                ...state,
                incidentHeatMap: payload
            };

        case types.LOADING_INCIDENT_HEAT_MAP:
            return {
                ...state,
                loadingIncidentHeatMap: payload
            };

        case types.LOADED_TYPICAL_OPERATIONS:
            return {
                ...state,
                typicalOperations: payload
            };

        case types.LOADING_TYPICAL_OPERATIONS:
            return {
                ...state,
                loadingTypicalOperations: payload
            };

        case types.LOADED_INCIDENTS_STATUSES:
            return {
                ...state,
                incidentsStatuses: payload
            };

            // case types.SET_INCIDENT_FILTER_PARAMS: {
            //     return {
            //         ...state,
            //         incedentsFilterParams: payload,
            //     };
            // }

        case types.LOADED_INCIDENTS_FACTORS:
            return {
                ...state,
                incidentFactors: payload
            };

        case types.LOADING_INCIDENTS_FACTORS:
            return {
                ...state,
                loadingIncidentFactors: payload
            };

        case types.LOADED_SCENARIOS:
            return {
                ...state,
                scenarios: payload,
            };

        case types.LOADING_SCENARIOS:
            return {
                ...state,
                loadingScenarios: payload,
            };

        case types.LOADED_THREAT_LEVELS:
            return {
                ...state,
                threatLevels: payload,
            };

        case types.LOADING_THREAT_LEVELS:
            return {
                ...state,
                loadingThreatLevels: payload
            };

        case types.LOADED_INCIDENT_TYPES:
            return {
                ...state,
                incidentTypes: payload
            };

        case types.LOADING_INCIDENT_TYPES:
            return {
                ...state,
                loadingIncidentTypes: payload
            };

        case types.LOADED_VEHICLES_CATEGORIES:
            return {
                ...state,
                vehicleCategories: payload.data,
                vehicleCategoriesLoaded: true,
            };

        case types.LOADING_INCIDENT_EVENTS:
            return {
                ...state,
                loadingIncidentEvents: payload
            };

        case types.LOADED_INCIDENT_EVENTS:
            return {
                ...state,
                incidentEvents: payload,
            };

        case types.LOADED_PHENOMENONS:
            return {
                ...state,
                phenomenons: payload,
            };

        case types.LOADING_PHENOMENONS:
            return {
                ...state,
                loadingPhenomenons: payload,
            };

        case types.LOADED_TRIGGER_STATUSES:
            return {
                ...state,
                triggerStatuses: payload,
            };

        case types.LOADED_TRIGGERS:
            return {
                ...state,
                triggers: payload,
            };

        case types.LOADING_TRIGGERS:
            return {
                ...state,
                loadingTriggers: payload,
            };

        case types.LOADED_KEY_WORD_LIST:
            return {
                ...state,
                keyWords: payload
            };

        case types.LOADING_KEY_WORD_LIST:
            return {
                ...state,
                keyWordsLoading: payload
            };

        default:
            return state;
    }
}
