import { CircleMarker, ToolTip } from 'components/MapComponents/leaflet';

import { getCheckPointColor } from './helper';

const CheckPointsMarkers = ({
    item,
    handleBounds,
    categoriesObj = {},
    ...props
}) => {
    const { category_id, num, check_points = [] } = item;
    const id = `${num}_${category_id}`;

    const color = getCheckPointColor(categoriesObj[category_id]?.color || '');

    return (
        <>
            {check_points
                ?.filter((station) => station?.lat && station?.lon)
                .map((station, index) => {
                    const { lat, lon, id: idPoints, name } = station;
                    // if (!lat || !lon) return null;

                    return (
                        <CircleMarker
                            {...props}
                            key={`circle_${id}_${idPoints}_${index}`}
                            latlng={[lat, lon]}
                            radius={6}
                            weight={1}
                            // color={color.checkPoint}
                            color={'rgba(0,0,0,.4)'}
                            fillColor={color}
                            fillOpacity={1}
                        >
                            <CircleMarker
                                latlng={[lat, lon]}
                                radius={3}
                                weight={1}
                                color={'rgba(0,0,0,.4)'}
                                // color={color.checkPoint}
                                fillColor="#fff"
                                fillOpacity={1}
                            >
                                {/* Информация - нечего показывать */}
                                {/*<PopUp>*/}
                                {/*    <PlatformPopUp*/}
                                {/*        map={props.map}*/}
                                {/*        station={station}*/}
                                {/*        isStation={false}*/}
                                {/*        route={num}*/}
                                {/*    />*/}
                                {/*</PopUp>*/}

                                <ToolTip
                                    offset={[0, -5]}
                                    direction="top"
                                    //permanent={true}
                                    //sticky={true}
                                >
                                    <div>Контрольная точка{name ? `: ${name}` : ''}</div>
                                </ToolTip>
                            </CircleMarker>
                        </CircleMarker>
                    );
                })}
        </>
    );
};

export default CheckPointsMarkers;
