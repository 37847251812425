import DtpGraph from 'modules/Dtp/Reports/DtpGraph';

const reportsRoutes = [
    {
        path: '/dtp/reports/graph',
        component: DtpGraph,
        exact: true,
    },
];

export default reportsRoutes;
