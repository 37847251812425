import type { Dispatch, ReactNode, SetStateAction } from 'react';

import titles from 'helpers/constants/titles';
import PassportTabsGrid from 'components/common/PassportTabs/PassportTabsGrid';
import PassportTabs from 'components/common/PassportTabs/PassportTabs';

import BasicData from './PassportPage/BasicData';
import Events from './PassportPage/Events';
import DTP from './PassportPage/DTP';

import type { Incident, IncidentItem } from './types';

interface PassportTabsProps {
    selectedItem: Incident | null;
    reloadList: (item?: IncidentItem) => void; // либо передаем элемент (в попапе для обновления), либо true в случае удаления
    passportOnly?: boolean; // чтобы на карте открыть в модалке
    queryKey?: string;
    urlParams?: Record<string, number | null>;
    setUrlParams?: Dispatch<SetStateAction<{[key: string]: number | null; }>>;
    setParams?: Dispatch<SetStateAction<{page: number; limit: number;}>>
    renderList?: () => ReactNode;
}

export const RenderPassportTabs = ({
    selectedItem,
    reloadList,
    passportOnly = false,
    queryKey,
    urlParams = {},
    setUrlParams = () => {},
    setParams = () => {},
    renderList = () => {},
}: PassportTabsProps) => {

    const tabs = [
        {
            value: 'data',
            label: titles.BASIC_DATA,
            icon: <i className="fal fa-info-square"/>,
            contentComponent: <BasicData
                item={selectedItem as Incident}
                onEdited={(item) => reloadList(item)}
                onDeleted={reloadList}
            />,
        },
        {
            value: 'events',
            label: titles.EVENTS,
            icon: <i className="far fa-car-bump"/>,
            contentComponent: <Events
                item={selectedItem as Incident}
            />,
        },
        {
            value: 'dtp',
            label: titles.DTP,
            icon: <i className="far fa-car-crash"/>,
            contentComponent: <DTP item={selectedItem}/>,
        },
    ];

    return (<>
        {passportOnly
            ? <PassportTabs tabs={tabs} />
            : <PassportTabsGrid
                tabs={tabs}
                selectedItem={!!selectedItem?.id}
                queryKey={queryKey}
                urlParams={urlParams}
                setUrlParams={setUrlParams}
                setParams={setParams}
                renderList={renderList}
            />
        }
    </>
    );
};
