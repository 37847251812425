/**
 * Sagas
 * **/

import { all, call, put, takeLatest } from 'redux-saga/effects';

import { messageTypes, showMessage } from 'redux/Message/actions';
import { setValidation } from 'redux/Validation/actions';
import { setMapLoading } from 'redux/Map/actions';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadVideoCamerasListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingVideoCamerasList(true));
    const response = yield call(api.getVideoCamerasList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasList(response));
    }
    yield put(actions.loadingVideoCamerasList(false));
}

function* createVideoCameraSaga({ payload, callback }) {
    const response = yield call(api.postVideoCamera, payload);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, 'Видеокамера создана'));
    }
    yield put(actions.saveStatusCamera(response?.success || false));
}

function* editVideoCameraSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.putVideoCamera, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Видеокамера изменена'));
        yield put(setValidation({ success: response.success }));
        callback && callback();
    }
    yield put(actions.saveStatusCamera(response?.success || false));
}

function* deleteVideoCameraSaga({ payload, callback }) {
    const response = yield call(api.deleteVideoCamera, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Видеокамера удалена'));
        callback && callback();
    }
}

function* loadStatusesVideoCameraSaga() {
    const response = yield call(api.getStatusesVideoCamera);
    if (response?.success) {
        yield put(actions.loadedStatusesVideoCamera(response?.data));
    }
}

function* changeVideoCameraStatusSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.changeVideoCameraStatus, id, data);
    if (response?.success) {
        callback && callback();
        yield put(showMessage(messageTypes.success, 'Статус камеры успешно изменен'));
    }
    yield put(actions.saveStatusCamera(response?.success || false));
}

function* loadTypesVideoCameraSaga() {
    const response = yield call(api.getTypesVideoCamera);
    if (response?.success) {
        yield put(actions.loadedTypesVideoCamera(response?.data));
    }
}

function* getForPolygonSaga({ payload: params }) {
    yield put(setMapLoading('videoCameras', true));
    const response = yield call(api.getForPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put(setMapLoading('videoCameras', false));
}

function* loadSideBarSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebarList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadVideoCameraFrameSaga({ payload: id, callback }) {
    yield put(actions.loadingVideoCameraFrame(true));
    const response = yield call(api.getVideoCamerasFrame, id);
    if (response?.success) {
        yield put(actions.loadedVideoCameraFrame({ [id]: response?.data }));
        yield callback?.();
    }
    yield put(actions.loadingVideoCameraFrame(false));
}

function* loadCameraGroupListSaga({ payload }) {
    const { page, limit, query, replaceStore } = payload;
    yield put(actions.loadingCameraGroupList(true));
    const response = yield call(api.getCameraGroupList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedCameraGroupList(response, replaceStore));
    }
    yield put(actions.loadingCameraGroupList(false));
}

function* createCameraGroupSaga({ payload }) {
    const response = yield call(api.postCameraGroup, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Группа видеокамер создана'));
        yield put(actions.loadCameraGroupList());
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* editCameraGroupSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.putCameraGroup, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Видеокамера изменена'));
        yield put(actions.loadCameraGroupList());
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* deleteCameraGroupSaga({ payload }) {
    const response = yield call(api.deleteCameraGroup, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Видеокамера удалена'));
        yield put(actions.loadCameraGroupList());
    }
    yield put(actions.saveStatus(response?.success || false));
}

function* loadCameraTemplateListSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingCameraTemplateList(true));
    const response = yield call(api.getCameraTemplateList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedCameraTemplateList(response));
    }
    yield put(actions.loadingCameraTemplateList(false));
}

function* createCameraTemplateSaga({ payload, callback }) {
    const response = yield call(api.postCameraTemplate, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Шаблон создан'));
        yield callback?.();
    }
}

function* editCameraTemplateSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.putCameraTemplate, id, data);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Шаблон изменен'));
        yield callback?.();
    }
}

function* deleteCameraTemplateSaga({ payload, callback }) {
    const response = yield call(api.deleteCameraTemplate, payload);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Шаблон удален'));
        yield callback?.();
    }
}

function* loadVideocamerasByRadiusSaga({ payload, callback }) {
    const response = yield call(api.getVideoCamerasByRadius, payload);
    if (response?.success) {
        yield put(actions.loadedVideoCamerasByRadius(response.data));
        callback(response.data);
    }
}

function* loadCameraByIdSaga({ payload, fnCallback, fnLoading }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCameraById, payload);
    if (response?.success) {
        yield fnCallback?.(response.data);
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_VIDEO_CAMERAS_LIST, loadVideoCamerasListSaga),
        takeLatest(types.CREATE_VIDEO_CAMERA, createVideoCameraSaga),
        takeLatest(types.EDIT_VIDEO_CAMERA, editVideoCameraSaga),
        takeLatest(types.DELETE_VIDEO_CAMERA, deleteVideoCameraSaga),
        takeLatest(types.LOAD_STATUSES_VIDEO_CAMERA, loadStatusesVideoCameraSaga),
        takeLatest(types.CHANGE_VIDEO_CAMERA_STATUS, changeVideoCameraStatusSaga),
        takeLatest(types.LOAD_TYPES_VIDEO_CAMERA, loadTypesVideoCameraSaga),
        takeLatest(types.GET_POLYGON, getForPolygonSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSideBarSaga),
        takeLatest(types.LOAD_VIDEO_CAMERA_FRAME, loadVideoCameraFrameSaga),
        takeLatest(types.LOAD_CAMERA_GROUP_LIST, loadCameraGroupListSaga),
        takeLatest(types.CREATE_CAMERA_GROUP, createCameraGroupSaga),
        takeLatest(types.EDIT_CAMERA_GROUP, editCameraGroupSaga),
        takeLatest(types.DELETE_CAMERA_GROUP, deleteCameraGroupSaga),
        takeLatest(types.LOAD_CAMERA_TEMPLATE_LIST, loadCameraTemplateListSaga),
        takeLatest(types.CREATE_CAMERA_TEMPLATE, createCameraTemplateSaga),
        takeLatest(types.EDIT_CAMERA_TEMPLATE, editCameraTemplateSaga),
        takeLatest(types.DELETE_CAMERA_TEMPLATE, deleteCameraTemplateSaga),
        takeLatest(types.LOAD_VIDEOCAMERAS_BY_RADIUS, loadVideocamerasByRadiusSaga),
        takeLatest(types.LOADED_CAMERA_BY_ID, loadCameraByIdSaga),
    ]);
}
