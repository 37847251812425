const Legend = () => {

    return (
        <div className="sidebar-tp-legend column">
            <div className="item">
                <div className="title">
                    Уровень угрозы
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                        Низкий
                    <div
                        style={{ height: '20px',
                            width: '100px',
                            background: 'linear-gradient(to right, #a5c0ff, #003399)' 
                        }}
                    />
                        Критический
                </div>
            </div>
        </div>
    );
};

export default Legend;