import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

export const LOAD_INCIDENTS = `${prefix}/LOAD_INCIDENTS`;
export const LOADING_INCIDENTS = `${prefix}/LOADING_INCIDENTS`;
export const LOADED_INCIDENTS = `${prefix}/LOADED_INCIDENTS`;
export const LOAD_INCIDENT_BY_ID = `${prefix}/LOAD_INCIDENT_BY_ID`;

export const LOAD_TYPICAL_OPERATIONS = `${prefix}/LOAD_TYPICAL_OPERATIONS`;
export const LOADED_TYPICAL_OPERATIONS = `${prefix}/LOADED_TYPICAL_OPERATIONS`;
export const LOADING_TYPICAL_OPERATIONS = `${prefix}/LOADING_TYPICAL_OPERATIONS`;
export const CREATE_TYPICAL_OPERATION = `${prefix}/CREATE_TYPICAL_OPERATION`;
export const EDIT_TYPICAL_OPERATION = `${prefix}/EDIT_TYPICAL_OPERATION`;
export const DELETE_TYPICAL_OPERATION = `${prefix}/DELETE_TYPICAL_OPERATION`;

export const CREATE_INCIDENT = `${prefix}/CREATE_INCIDENT`;
export const EDIT_INCIDENT = `${prefix}/EDIT_INCIDENT`;
export const DELETE_INCIDENT = `${prefix}/DELETE_INCIDENT`;

export const LOAD_INCIDENT_SIDEBAR = `${prefix}/LOAD_INCIDENT_SIDEBAR`;
export const LOADING_INCIDENT_SIDEBAR = `${prefix}/LOADING_INCIDENT_SIDEBAR`;
export const LOADED_INCIDENT_SIDEBAR = `${prefix}/LOADED_INCIDENT_SIDEBAR`;
export const CLEAR_INCIDENT_SIDEBAR = `${prefix}/CLEAR_INCIDENT_SIDEBAR`;
export const NEED_RELOAD_INCIDENT_SIDEBAR = `${prefix}/NEED_RELOAD_INCIDENT_SIDEBAR`;

export const LOAD_INCIDENT_POLYGON = `${prefix}/LOAD_INCIDENT_POLYGON`;
export const LOADING_INCIDENT_POLYGON = `${prefix}/LOADING_INCIDENT_POLYGON`;
export const LOADED_INCIDENT_POLYGON = `${prefix}/LOADED_INCIDENT_POLYGON`;

export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;

export const LOAD_INCIDENT_HEAT_MAP = `${prefix}/LOAD_INCIDENT_HEAT_MAP`;
export const LOADING_INCIDENT_HEAT_MAP = `${prefix}/LOADING_INCIDENT_HEAT_MAP`;
export const LOADED_INCIDENT_HEAT_MAP = `${prefix}/LOADED_INCIDENT_HEAT_MAP`;

export const LOAD_INCIDENTS_STATUSES = `${prefix}/LOAD_INCIDENTS_STATUSES`;
export const LOADED_INCIDENTS_STATUSES = `${prefix}/LOADED_INCIDENTS_STATUSES`;

export const LOAD_INCIDENTS_FACTORS = `${prefix}/LOAD_INCIDENTS_FACTORS`;
export const LOADED_INCIDENTS_FACTORS = `${prefix}/LOADED_INCIDENTS_FACTORS`;
export const LOADING_INCIDENTS_FACTORS = `${prefix}/LOADING_INCIDENTS_FACTORS`;
export const CREATE_INCIDENTS_FACTORS = `${prefix}/CREATE_INCIDENTS_FACTORS`;
export const EDIT_INCIDENTS_FACTORS = `${prefix}/EDIT_INCIDENTS_FACTORS`;
export const DELETE_INCIDENTS_FACTORS = `${prefix}/DELETE_INCIDENTS_FACTORS`;

export const LOAD_SCENARIOS = `${prefix}/LOAD_SCENARIOS`;
export const LOADING_SCENARIOS = `${prefix}/LOADING_SCENARIOS`;
export const LOADED_SCENARIOS = `${prefix}/LOADED_SCENARIOS`;

export const LOAD_THREAT_LEVELS = `${prefix}/LOAD_THREAT_LEVELS`;
export const LOADING_THREAT_LEVELS = `${prefix}/LOADING_THREAT_LEVELS`;
export const LOADED_THREAT_LEVELS = `${prefix}/LOADED_THREAT_LEVELS`;

export const LOAD_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOAD`;
export const LOADED_VEHICLES_CATEGORIES = `${prefix}/VEHICLES/CATEGORIES/LOADED`;

export const LOAD_INCIDENT_EVENTS = `${prefix}/LOAD_INCIDENT_EVENTS`;
export const LOADING_INCIDENT_EVENTS = `${prefix}/LOADING_INCIDENT_EVENTS`;
export const LOADED_INCIDENT_EVENTS = `${prefix}/LOADED_INCIDENT_EVENTS`;

export const LOAD_PHENOMENONS = `${prefix}/LOAD_PHENOMENONS`;
export const LOADING_PHENOMENONS = `${prefix}/LOADING_PHENOMENONS`;
export const LOADED_PHENOMENONS = `${prefix}/LOADED_PHENOMENONS`;

export const LOAD_TRIGGER_STATUSES = `${prefix}/LOAD_TRIGGER_STATUSES`;
export const LOADED_TRIGGER_STATUSES = `${prefix}/LOADED_TRIGGER_STATUSES`;
export const LOAD_TRIGGERS = `${prefix}/LOAD_TRIGGERS`;
export const LOADING_TRIGGERS = `${prefix}/LOADING_TRIGGERS`;
export const LOADED_TRIGGERS = `${prefix}/LOADED_TRIGGERS`;
export const CREATE_TRIGGER = `${prefix}/CREATE_TRIGGER`;
export const EDIT_TRIGGER = `${prefix}/EDIT_TRIGGER`;
export const DELETE_TRIGGER = `${prefix}/DELETE_TRIGGER`;
export const LOAD_POLYGON_FOR_TRIGGERS = `${prefix}/LOAD_POLYGON_FOR_TRIGGERS`;

export const LOAD_INCIDENT_TYPES = `${prefix}/LOAD_INCIDENT_TYPES`;
export const LOADED_INCIDENT_TYPES = `${prefix}/LOADED_INCIDENT_TYPES`;
export const LOADING_INCIDENT_TYPES = `${prefix}/LOADING_INCIDENT_TYPES`;
export const CREATE_INCIDENT_TYPE = `${prefix}/CREATE_INCIDENT_TYPE`;
export const EDIT_INCIDENT_TYPE = `${prefix}/EDIT_INCIDENT_TYPE`;
export const DELETE_INCIDENT_TYPE = `${prefix}/DELETE_INCIDENT_TYPE`;
export const GET_INCIDENT_TYPE_BY_ID = `${prefix}/GET_INCIDENT_TYPE_BY_ID`;

export const LOAD_KEY_WORD_LIST = `${prefix}/LOAD_KEY_WORD_LIST`;
export const LOADED_KEY_WORD_LIST = `${prefix}/LOADED_KEY_WORD_LIST`;
export const LOADING_KEY_WORD_LIST = `${prefix}/LOADING_KEY_WORD_LIST`;
export const EDIT_KEY_WORD = `${prefix}/EDIT_KEY_WORD`;
export const ADD_KEY_WORD = `${prefix}/ADD_KEY_WORD`;
export const DELETE_KEY_WORD = `${prefix}/DELETE_KEY_WORD`;