import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
    editDtp,
    editDtpLink,
    loadDtpLinkTypes,
    loadDtpTypesAll
} from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { fullDateTime } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import prepareCoords from 'helpers/prepareCoords';
import { useStoreFromSelector, useStoreFromSelectorListToObject } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import numberValue from '../../numberValue';
import LinkFormModal from '../../../forms/LinkFormModal';

import CompareListTable from './CompareListTable';
import StatusButton from './Status/StatusButton';
import ReportButton from './ReportButton';
import RevertDtp from './RevertDtp';

const Link = ({
    link,
    currentDtpId,
    onRefresh,
}) => {

    const dispatch = useDispatch();

    const linkTypes = useStoreFromSelector(loadDtpLinkTypes, incidentsSelectors.dtpLinkTypes);
    const dtpTypesAll = useStoreFromSelectorListToObject(loadDtpTypesAll, incidentsSelectors.typesAll);

    const [editModal, setEditModal] = useState(false);

    const getDptType = (id) => {
        const currentItem = dtpTypesAll.get(id);
        return currentItem ? currentItem.name : '';
    };

    const {
        id,
        dtp = {},
        dtp_id,
        link_type,
        link_type_text,
        linked_dtp = {},
        linked_dtp_id,
    } = link || {};

    const fields = {
        status_text: {
            title: titles.STATUS,
            value: ({ status_text }) => status_text
        },
        ...(link.link_type === 2 // Тип связи: Следствие
            ? {
                type: {
                    title: 'Связь',
                    buttons: (index) => (
                        <>
                            <RevertDtp
                                index={index}
                                onChange={() => {
                                    const data = {
                                        ...link,
                                        dtp_id: linked_dtp_id,
                                        dtp: linked_dtp,
                                        linked_dtp_id: dtp_id,
                                        linked_dtp: dtp,
                                    };
                                    dispatch(editDtpLink(
                                        id,
                                        data,
                                        () => {
                                            onRefresh?.();
                                        }
                                    ));
                                }}
                            />
                        </>
                    )
                }
            }
            : {}
        ),
        ...(link.link_type === 1 // Тип связи: Эквивалентно
            ? {
                is_skip: {
                    title: 'Учитывать в отчетности',
                    buttons: (_, currentDtp, secondDtp) => (
                        <ReportButton
                            is_skip={currentDtp?.is_skip}
                            onChange={(is_skip) => {
                                // изменяем у текущего дтп
                                dispatch(editDtp(
                                    currentDtp.id,
                                    {
                                        ...currentDtp,
                                        is_skip,
                                    },
                                    () => {
                                        // изменяем у связанного дтп
                                        dispatch(editDtp(
                                            secondDtp.id,
                                            {
                                                ...secondDtp,
                                                is_skip: !is_skip,
                                            },
                                            () => {
                                                onRefresh?.();
                                            }
                                        ));
                                    }
                                ));
                            }}
                        />
                    )
                }
            }
            : {}
        ),
        dtp_type: {
            title: titles.DTP_TYPE,
            value: ({ dtp_type }) => getDptType(dtp_type)
        },
        dtp_at: {
            title: titles.DATE_TIME_DTP,
            value: ({ dtp_at }) => fullDateTime(dtp_at)
        },
        lat: {
            title: titles.LAT,
            value: ({ lat }) => prepareCoords(lat, 6)
        },
        lon: {
            title: titles.LON,
            value: ({ lon }) => prepareCoords(lon, 6)
        },
        address: {
            title: titles.ADDRESS,
            value: ({ address, dtp }) => dtp?.address_text || Object.keys(address || {}).length > 0
                ? dtp?.address_text || renderAddress(address)
                : messages.INFO_IS_NOT_FOUND
        },
        km: {
            title: titles.DTP_KM,
            value: ({ km }) => numberValue(km)
        },
        m: {
            title: titles.DTP_M,
            value: ({ m }) => numberValue(m)
        },
        type_load_text: {
            title: titles.DTP_DATA_SOURCE
        },
        created_at: {
            title: 'Дата создания записи',
            value: ({ created_at }) => fullDateTime(created_at)
        },
        updated_at: {
            title: 'Дата изменения записи',
            value: ({ updated_at }) => fullDateTime(updated_at)
        },
        count_members: {
            title: titles.COUNT_DTP_MEMBERS,
            value: ({ count_members }) => numberValue(count_members)
        },
        dead: {
            title: titles.COUNT_DTP_DEAD,
            value: ({ dead }) => numberValue(dead)
        },
        wounded: {
            title: titles.COUNT_DTP_WOUNDED,
            value: ({ wounded }) => numberValue(wounded)
        },
        dead_child: {
            title: titles.COUNT_DTP_DEAD_KIDS,
            value: ({ dead_child }) => numberValue(dead_child)
        },
        wounded_child: {
            title: titles.COUNT_DTP_WOUNDED_KIDS,
            value: ({ wounded_child }) => numberValue(wounded_child)
        },
        count_ts: {
            title: titles.COUNT_DTP_TS,
            value: ({ count_ts }) => numberValue(count_ts)
        },
    };

    return (
        <div>

            <div style={{ padding: '0 1rem' }}>
                <h2>
                    Характеристики связи
                    <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditModal(true),
                            }
                        ]}
                        noMarginLeft
                        justButton
                    />
                </h2>

                <div><b>Тип связи:</b> {link_type_text || linkTypes[link_type]}</div>
                <div>
                    <StatusButton
                        dtpLinkData={link}
                        onRefresh={onRefresh}
                    />
                    {/*(предварительный, отмененный и подтвержденный)*/}
                </div>
            </div>

            {/*  различия параметров */}
            <CompareListTable
                dtp={dtp}
                dtpLinked={linked_dtp}
                title={fields}
                currentDtpId={currentDtpId}
                onRefresh={onRefresh}
                isShowHeaderButton={link_type === 1}
            />

            {/* форма редактирования */}
            {editModal && (
                <LinkFormModal
                    isOpen={editModal}
                    data={link}
                    onClose={() => setEditModal(false)}
                    onSave={onRefresh}
                />
            )}

        </div>
    );
};

export default Link;
