import { useSelector } from 'react-redux';

import Loading from 'components/common/Loading';
import HeatLayer from 'components/MapComponents/leaflet/components/HeatLayer';
import MapLegends from 'components/common/Transport/MapLegends';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import { Cluster, FeatureGroup, Map, MapControl } from 'components/MapComponents/leaflet';
import { config } from 'config';
import { reactSelectors } from 'modules/React';
import IncidentsLayer from 'modules/React/Layers/Incidents';

import Legend from './Legend';

const RenderMap = ({ showLayer, polygonFilter }) => {
    const heatMapData =  useSelector(reactSelectors.incidentHeatMap);
    const loadingHeatMap = useSelector(reactSelectors.loadingIncidentHeatMap);
    const loadingPolygon = useSelector(reactSelectors.loadingIncidentPolygon);

    return <>
        {(loadingHeatMap || loadingPolygon) && <Loading circular />}
        <div style={{ display: 'contents', minHeight: '400px' }}>
            <Map center={config.get('mapCenter')} >
                <MapControl>
                    <FeatureGroup>
                        <HeatLayer json={heatMapData}/>

                        {showLayer &&  (
                            <Cluster
                                iconCreateFunction={iconCreateFunctionSimple}
                                disableClusteringAtZoom={15}
                                maxClusterRadius={10}
                            >
                                <IncidentsLayer filter={polygonFilter}/>
                            </Cluster>
                        )}

                        {/* <MapLegends visibleRequired={true}>
                            <Legend />
                        </MapLegends>  */}
                    </FeatureGroup>

                </MapControl>
            </Map>
        </div>
    </>;
};

export default RenderMap;
