import { useState } from 'react';
import { useDispatch } from 'react-redux';

import messages from 'helpers/constants/messages';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import ConfirmDelete from 'components/common/ConfirmModal';
import CircleStatus from 'components/common/CircleStatus';
import { deleteTrigger, loadTriggerStatuses } from 'modules/React/redux/actions';
import { reactSelectors } from 'modules/React';

import ModalForm from './ModalForm';
import Info from './Info';

import type { Trigger } from './types';

interface ItemProp {
    item: Trigger
    reloadList: (isDelete?: boolean) => void
}

const Item = ({ item, reloadList }: ItemProp) => {
    const dispatch = useDispatch();

    const statuses = useStoreFromSelectorListToObject(
        loadTriggerStatuses, 
        reactSelectors.triggerStatuses
    );

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const onDelete = () => {
        dispatch(deleteTrigger(item.id, () => {
            reloadList(true);
            setOpenDeleteModal(false);
        }));
    };

    return (
        <>
            <LSContentItem onClick={() => setOpenInfo(!openInfo)}>
                <LSContentColumn>
                    <CircleStatus 
                        title={statuses.get(item.status)?.name} 
                        color={statuses.get(item.status)?.color}
                    />
                </LSContentColumn>
                <LSContentColumn>
                    {item?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.type?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.responseScenario?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={openInfo}
                        onClick={() => setOpenInfo(!openInfo)}
                    />
                </LSContentColumn>
            </LSContentItem>

            {openInfo && (
                <Info
                    isOpen={openInfo}
                    data={item}
                />
            )}

            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    isNew={false}
                    item={item}
                    reloadList={reloadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteModal}
                    onSuccess={onDelete}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
