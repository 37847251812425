import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import externalTransportTitles from 'modules/ExternalTransport/utils/helpers/constants';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import { externalTransportSelectors } from 'modules/ExternalTransport';
import { loadStationSchedules } from 'modules/ExternalTransport/redux/actions';
import { fullDateTimeWithoutSeconds, getHumanDate } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

import PopupTabs from './Tabs';
import DynamicTable from './DynamicTable';

const departuretHeaders = [
    { title: 'flight', value: '№ рейса', width: '15%' },
    { title: 'carrier', value: 'Перевозчик', width: '20%' },
    { title: 'departureLocation', value: 'Пункт вылета', width: '25%' },
    { title: 'time_plan', value: 'Отправление план', width: '15%' },
    { title: 'time_fact', value: 'Отправление факт', width: '15%' },
    { title: 'status', value: 'Статус', width: '10%' }
];
const arrivalHeaders  = [
    { title: 'flight', value: '№ рейса', width: '15%' },
    { title: 'carrier', value: 'Перевозчик', width: '20%' },
    { title: 'departureLocation', value: 'Пункт назначения', width: '25%' },
    { title: 'time_plan', value: 'Прилет план', width: '15%' },
    { title: 'time_fact', value: 'Прилет факт', width: '15%' },
    { title: 'status', value: 'Статус', width: '10%' }
];

const AirPortTabs = ({ id }) => {
    const dispatch = useDispatch();
    const [schedule, setSchedule] = useState({ arrival: [], departure: [] });
    const [date, setDate] = useState('');
    const loading = useSelector(externalTransportSelectors.loading_station_schedules);

    // TODO пока нет ни одного аэро непонятно какие будут поля
    useEffect(() => {
        const createObject = (el, time_plan, time_fact) => {
            return {
                flight: el.schedule?.route?.number || messages.NO_VALUE,
                carrier: el.schedule?.route?.carrier?.name || messages.NO_VALUE,
                departureLocation: el.schedule?.route?.name || messages.NO_VALUE,
                time_plan: time_plan ? fullDateTimeWithoutSeconds(time_plan) : messages.NO_VALUE,
                time_fact: time_fact ? fullDateTimeWithoutSeconds(time_fact) : messages.NO_VALUE,
                status: el.status || messages.NO_VALUE,
            };
        };
        dispatch(loadStationSchedules(id, (data) => {
            const newData = data.reduce((res, el, index) => {
                res.arrival = res.arrival || [];
                res.departure = res.departure || [];
                if (el.arrival) {
                    res.arrival.push(createObject(el, el.arrival));
                }
                if (el.departure) {
                    res.departure.push(createObject(el, el.departure));
                }

                if (index === 0) setDate(getHumanDate(el.date)); // костыль пока нет выбора даты она нам нудна 1шт
                return res;
            }, { arrival: [], departure: [] });
            setSchedule(newData);
        }));
    }, []);

    const tabList = [
        {
            value: 'departures',
            label: externalTransportTitles.DEPARTURES,
            contentComponent: <DynamicTable headers={departuretHeaders} data={schedule.departure} />
        },
        {
            value: 'arrivals',
            label: externalTransportTitles.ARRIVALS,
            contentComponent: <DynamicTable headers={arrivalHeaders} data={schedule.arrival} />
        }];

    return <>
        {/* <div style={{ padding: '1rem', width: '50%' }}>
            <SingleKeyboardDateTimePicker value={date} onChange={setDate} label="Данные на дату" />
        </div> */}
        <PopupTabs tabList={tabList} date={date} loading={loading}/>;
    </>;

};

export default AirPortTabs;
