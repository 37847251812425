import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import { debounce } from 'lodash';

import renderChild from './helper/renderChild';

const Cluster = ({
    map,
    iconCreateFunction,
    disableClusteringAtZoom,
    children,
    isDebounce = true,
    isClick = false,
    maxClusterRadius,
    onClick,
    ...prop
}) => {
    const [area, setArea] = useState(null);

    const iconCreateFunctionDefault = (cluster) => {
        cluster.on('clustermouseover', () => console.log('dsd'));
        const childCount = cluster.getChildCount();
        const icon = new L.DivIcon({
            html: `<div class="count"><span>${childCount}</span></div>`,
            className: 'marker-cluster',
            iconSize: new L.Point(40, 40)
        });
        return icon;
    };

    const clusterProps = {
        //zoomToBoundsOnClick: false,
        //spiderfyOnMaxZoom: false,
        //disableClusteringAtZoom: 15,
        disableClusteringAtZoom: map.getMaxZoom() + 1, // увеличился зум до 20
        iconCreateFunction: iconCreateFunction || iconCreateFunctionDefault,
        maxClusterRadius: maxClusterRadius || 80,
    };

    if (disableClusteringAtZoom) clusterProps.disableClusteringAtZoom = disableClusteringAtZoom;
    if (isClick) {
        clusterProps.zoomToBoundsOnClick = false;
        clusterProps.spiderfyOnMaxZoom = false;
    }

    // bringToFront()
    // bringToBack()

    useEffect(() => {
        const geoGroup = L.markerClusterGroup(clusterProps);

        // обновление кластеров
        const doRefreshClusters = isDebounce
            ? debounce(() => {
                geoGroup.refreshClusters();
                // geoGroup.refreshIconOptions();
            }, 500)
            : () => {
                geoGroup.refreshClusters();
            };
        map.on('refreshClusters', function () {
            doRefreshClusters();
        });

        // смена тайла - решение взято тут - https://github.com/Leaflet/Leaflet.markercluster/issues/869
        map.addEventListener('baselayerchange', function() { // or use .on(
            // обновление максимального зума кластера
            geoGroup._maxZoom = map.getMaxZoom();
            geoGroup.options.disableClusteringAtZoom = map.getMaxZoom() + 1;

            // передобавление слоев
            const layers = geoGroup.getLayers();
            geoGroup.clearLayers();
            geoGroup.addLayers(layers);
        });

        // geoGroup.on('clustermouseover', function(event) {
        //     // your custom L.MarkerCluster extended with function highlight()
        //     console.log(event.layer);
        //     // event.layer.highlight();
        // });
        //
        // geoGroup.on('clustermouseout', function(event) {
        //     // your custom L.MarkerCluster extended with function resetHighlight()
        //     event.layer.resetHighlight();
        // });

        // клик по кластеру
        // onClick && geoGroup.on('clusterclick', function (a) {
        //     onClick(a);
        //     //console.log('clusterClick', a.layer.getAllChildMarkers());
        // });

        map.addLayer(geoGroup);
        setArea(geoGroup);

        return () => {
            // onClick && geoGroup.off('clusterclick');
            geoGroup.clearLayers();
            map.removeLayer(geoGroup);
            setArea(null);
            map.off('refreshClusters');
            geoGroup.off('clustermouseover');
            geoGroup.off('clustermouseout');
            map.removeEventListener('baselayerchange');
        };
    }, [map]);

    useEffect(() => {
        if (area && onClick) {
            const handleShow = (clusterProp) => {
                const latlng = clusterProp.latlng;
                const cluster = clusterProp.layer;

                onClick(cluster, latlng);

                // const count = cluster.getChildCount();
                // const markers = cluster.getAllChildMarkers();
                // const attribution = marker.getAttribution();
            };

            area.on('clusterclick', handleShow);

            return () => {
                area.off('clusterclick', handleShow);
            };
        }
    }, [area, onClick]);

    return area
        ? renderChild(
            children,
            {
                ...prop,
                map,
                parent: area,
                cluster: area
            }
        )
        : null;
};

export default Cluster;
