import * as types from './types';

export const loadCountByMonths = (filter, fnCallback, fnLoading,) => ({
    type: types.LOAD_COUNT_BY_MONTH,
    payload: filter,
    fnLoading,
    fnCallback
});

export const loadCountByPeriods = (filter, fnCallback, fnLoading) => ({
    type: types.LOAD_COUNT_BY_PERIODS,
    payload: filter,
    fnLoading,
    fnCallback
});
