import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';

import { dorisControlSelectors } from 'redux/DorisControl';
import { create1CName, edit1CName } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import CommonModal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import type { ComplexName } from './types';

interface BaseProps {
    onClose: () => void
    reloadList: () => void
}

interface Add extends BaseProps {
    isNew: true
    item?: never
}

interface Edit extends BaseProps {
    isNew?: false
    item: ComplexName
}

const ModalForm = ({ isNew, onClose, item, reloadList }: Add | Edit) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const loadingBtn: boolean = useSelector(dorisControlSelectors.loadingButton);

    const [name, setName] = useState(item?.name || '');

    const onSave = () => {
        const callback = () => {
            onClose();
            reloadList();
        };

        dispatch(isNew
            ? create1CName({ name }, callback)
            : edit1CName( { name }, item?.id, callback)
        );
    };

    const isDisabled = !name.trim() || validation.isKey('name');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
        validation.deleteKey('name');
    };

    return (
        <CommonModal
            isOpen
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes[isNew ? 'create' : 'save'],
                        onClick: onSave,
                        loading: loadingBtn,
                        disabled: isDisabled
                    },
                ]}
            />}
        >
            <div className="modal__form">
                <FormInfoWrapper error={isDisabled} helperText={validation.get('name')}>
                    <TextField
                        value={name}
                        onChange={handleChange}
                        label={titles.NAME}
                        error={isDisabled}
                        required
                    />
                </FormInfoWrapper>
            </div>
        </CommonModal>
    );
};

export default ModalForm;
