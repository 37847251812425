import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Scenario } from './ModalForm';

interface RadioCheckScenarioProps {
    selectedItem?: Scenario;
    setSelected: (id: number) => void;
    data: Scenario[];
}

const RadioCheckScenario = ({ selectedItem, setSelected, data }: RadioCheckScenarioProps) => {

    return (
        <FormControl>
            <FormLabel>Сценарий по умолчанию</FormLabel>
            <RadioGroup
                value={selectedItem?.id || 0}
                onChange={(e) => setSelected(Number(e.target.value))}
            >
                {data.map((el) => (
                    <FormControlLabel
                        key={el.id}
                        value={el.id}
                        control={<Radio size="small" />}
                        label={el.name}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioCheckScenario;
