import { config } from 'config';

const apiReactUrl = () => config.get('urls').apiReactUrl;
const apiBase = () => config.get('urls').apiBase;


const apiUrls = {
    getIncidents: () => `${apiReactUrl()}/incident`,

    getIncidentById: (id) => `${apiReactUrl()}/incident/${id}`,

    createIncident: () => `${apiReactUrl()}/incident`,

    editIncident: (id) => `${apiReactUrl()}/incident/${id}`,

    deleteIncident: (id) => `${apiReactUrl()}/incident/${id}`,

    getTypicalOperations: () => `${apiReactUrl()}/operation/typical`,

    createTypicalOperation:  () => `${apiReactUrl()}/operation/typical`,

    editTypicalOperation:  (id) => `${apiReactUrl()}/operation/${id}`,

    deleteTypicalOperation:  (id) => `${apiReactUrl()}/operation/${id}`,

    getIncidentPolygon: () => `${apiReactUrl()}/incident/polygon`,

    getIncidentHeatMap: () => `${apiReactUrl()}/incident/heat-map`,

    getIncidentsStatuses: () => `${apiReactUrl()}/incident/statuses`,

    getVehicleCategories: () => `${apiReactUrl()}/incident/vehicle/types`,

    getThreatLevels: () => `${apiReactUrl()}/threat-level`,

    getResponseScenarios: () => `${apiReactUrl()}/response-scenario/`,

    getIncidentEvents: (id) => `${apiReactUrl()}/incident/${id}/events`,

    getIncidentsFactors: () => `${apiReactUrl()}/factor`,

    createIncidentsFactors:  () => `${apiReactUrl()}/factor`,

    editIncidentsFactors:  (id) => `${apiReactUrl()}/factor/${id}`,

    deleteIncidentsFactors:  (id) => `${apiReactUrl()}/factor/${id}`,

    getScenarios: () => `${apiReactUrl()}/response-scenario/`,

    getPhenomenons: () => `${apiReactUrl()}/phenomenon/`,

    getTriggerStatuses: () => `${apiReactUrl()}/trigger/statuses`,

    getTriggers: () => `${apiReactUrl()}/trigger/list`,

    createTrigger: () => `${apiReactUrl()}/trigger`,

    editTrigger: (id) => `${apiReactUrl()}/trigger/${id}`,

    deleteTrigger: (id) => `${apiReactUrl()}/trigger/${id}`,

    getPolygonForTriggers: (url) => apiBase() ? `${apiBase()}${url}` : url,

    getIncidentTypes: () => `${apiReactUrl()}/type`,

    createIncidentTypes: () => `${apiReactUrl()}/type`,

    editIncidentTypes: (id) => `${apiReactUrl()}/type/${id}`,

    deleteIncidentTypes: (id) => `${apiReactUrl()}/type/${id}`,

    getIncidentTypeById: (id) => `${apiReactUrl()}/type/${id}`,

    getKeyWordList: () => `${apiReactUrl()}/keyword`,

    addKeyWord: () => `${apiReactUrl()}/keyword`,

    editKeyWord: (id) => `${apiReactUrl()}/keyword/${id}`,

    deleteKeyWord: (id) => `${apiReactUrl()}/keyword/${id}`,
};

export default apiUrls;
