import { useEffect, useState } from 'react';

import GetReports from 'components/common/GetReports';
import SimpleSelect from 'components/common/SimpleSelect';

const GetEventReport = ({ handleDownload, typeList, access, test_id_prefix = '' }) => {
    const [selectedType, setSelectedType] = useState('');
    const [selectedFormats, setSelectedFormats] = useState([]);

    const onDownload = (formats) => {
        handleDownload(formats, selectedType.key);
        setSelectedType('');
        setSelectedFormats([]);
    };

    useEffect(() => {
        if (!selectedType) {
            setSelectedFormats([]);
        }
    }, [selectedType]);

    const availableTypeList = access
        ? typeList.reduce((res, el) => {
            // если report id есть в access и он true
            if (access[el.key]) {
                res.push(el);
            }
            return res;
        }, [])
        : typeList;

    return (
        <div className="row noMargin">
            <div style={{ minWidth: '160px' }}>
                <SimpleSelect
                    label="Тип отчета"
                    selected={selectedType}
                    options={availableTypeList}
                    onChange={setSelectedType}
                    renderLabel={(option) => option?.name || ''}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    test_id_prefix={`${test_id_prefix}/report`}
                />
            </div>

            <GetReports
                onDownload={onDownload}
                disabled={!selectedType}
                onChange={setSelectedFormats}
                selected={selectedFormats}
                reportId={selectedType?.key}
                test_id_prefix={test_id_prefix}
            />
        </div>
    );
};

export default GetEventReport;
