import SelectScenarios from 'modules/React/utils/components/SelectScenarios';
import type { ItemType } from 'components/common/Autocomplete/CommonAutocomplete';

import RadioCheckScenario from './RadioCheckScenario';
import type { Scenario } from './ModalForm';

interface ScenariosProps {
    selected_scenarios: Scenario[];
    onChange: (scenarios: Scenario[]) => void;
}

const Scenarios = ({ selected_scenarios = [], onChange }: ScenariosProps) => {
    const handleChangeScenarios = (value: ItemType<Scenario>) => {
        if (!value) {
            onChange([]);
        } else if (Array.isArray(value)) {
            const validScenarios = value.filter((item): item is Scenario => typeof item !== 'string' && item !== null);

            const defaultItem = validScenarios.find(el => el.default);
            if (!defaultItem && validScenarios.length > 0) {
                validScenarios[0].default = true;
            }
            onChange(validScenarios);
        } else if (typeof value !== 'string' && value !== null) {
            onChange([value]);
        }
    };

    const handleCheck = (id: number) => {
        const changeDefault = selected_scenarios.map((el) => ({ ...el, default: el.id === id }));
        onChange(changeDefault);
    };

    return (
        <>
            <SelectScenarios
                onChange={handleChangeScenarios}
                selected={selected_scenarios}
                multiple={true}
            />
            {selected_scenarios?.length > 0 && (
                <RadioCheckScenario
                    data={selected_scenarios}
                    setSelected={handleCheck}
                    selectedItem={selected_scenarios.find((el) => el.default)}                                                                    
                />                               
            )}
        </>
    );
};

export default Scenarios;
