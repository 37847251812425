import { useEffect } from 'react';
import { 
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField 
} from '@mui/material';

import { loadRecognitionCameraDirTypes } from 'redux/DorisControl/actions';
import { useValidation } from 'helpers/hooks';
import useStoreProp from 'helpers/hooks/useStoreProp';


const DirTypeSelect = ({  data, setData, selectedType, }) => {
    const validation = useValidation();

    const dirTypeFields = selectedType?.fields.find(el => el.field === 'dir_type');
    const directionFields = selectedType?.fields.find(el => el.field === 'direction');

    const dirTypes = useStoreProp( 
        loadRecognitionCameraDirTypes,
        'dorisControl',
        'recognitionCameraDirTypes'
    );

    const initDirections = [
        {
            id: 0, 
            degree: 0, 
            direction: '',
        },
        {
            id: 1, 
            degree: 0, 
            direction: '',
        },
    ];

    useEffect(() => {
        if (data.dir_type !== '') {
            onChangeDirType({ target: { name: 'dir_type', value: Number(data.dir_type) } });
        }
    }, []);

    const onChangeDirType = (e) => {
        const { name, value } = e.target;
        const currentValue = String(value);

        const newDirection = currentValue === '2'
            ? [
                data?.direction?.find(el => String(el?.id) === '0') || initDirections[0],
                data?.direction?.find(el => String(el?.id) === '1') || initDirections[1]
            ] 
            : [data?.direction?.find(el => String(el?.id) === currentValue) || initDirections[value]];

        setData({ 
            ...data, 
            [name]: value,
            direction: newDirection,
        });
    };

    const onChangeDirection = (e, index) => {
        const { name, value } = e.target;

        setData({ 
            ...data, 
            direction: [
                ...data.direction.slice(0, index),
                { ...data.direction[index], [name]: value },
                ...data.direction.slice(index + 1 ),
            ]
        });

        validation.deleteKey(`direction.0.${name}`);
    };
    const onChangeDegree = (e) => {
        // градус отклонения один для всех направлений,
        // поэтому мы изменяем его один раз в инпуте но меняем во всех направлениях в массиве
        const { name, value } = e.target;
        const currentValue = Math.min(Math.max(0, parseInt(value || 0)), 360);

        setData({
            ...data, 
            direction: data.direction.map((el, index) => {
                return {
                    ...el,
                    [name]: currentValue,
                };
            }),
        });

        validation.deleteKey(`direction.0.${name}`);
    };

    const degreeFields = directionFields?.fields.find(field => (field.field === 'degree'));
    const degreeData = data.direction[0];

    return (
        <>
            <FormControl
                required={dirTypeFields?.require}
            >
                <h3 style={{ color: dirTypeFields?.require && data.dir_type === '' ? '#d32f2f' : 'inherit' }}>{dirTypeFields?.name}{dirTypeFields?.require && '*'}</h3>
                <RadioGroup
                    row
                    value={String(data?.dir_type) || ''}
                    onChange={onChangeDirType}
                >
                    {Object.keys(dirTypes).map((key) => (
                        <FormControlLabel
                            key={key}
                            size="small"
                            name="dir_type"
                            value={String(key)}
                            control={<Radio/>}
                            label={dirTypes[key]}
                        />)
                    )}
                </RadioGroup>
            </FormControl>
            {data.direction?.length > 0 && (
                <>
                    {degreeFields
                        && <div className="block">
                            <TextField
                                required={degreeFields.require}
                                variant="outlined"
                                size="small"
                                label={degreeFields.name}
                                value={degreeData?.[degreeFields.field] ?? ''}
                                name="degree"
                                onChange={onChangeDegree}
                                type={degreeFields.type}
                                error={(degreeData?.[degreeFields?.field] === '' && degreeFields?.require)}
                            // helperText={validation.get(`direction.${index}.${degreeFields.field}`)}
                            />
                        </div>
                    }
                    <h3>Текстовое описание направления движения ТС</h3>
                    <FormControl
                        size="small"
                        variant="outlined"
                        required={directionFields?.require}
                        className="row"
                    >
                        {data.direction?.map((el, index) => (
                            directionFields?.fields.map(field => field?.field === 'direction'
                                ? <TextField
                                    key={field?.field}
                                    className="row__item"
                                    variant="outlined"
                                    size="small"
                                    required={field?.require}
                                    label={`${field?.name} - ${dirTypes?.[el?.id]}`}
                                    value={el?.[field?.field] || ''}
                                    name={field?.field}
                                    onChange={(e) => onChangeDirection(e, index)}
                                    type={field?.type}
                                    error={(!el?.[field?.field] && field?.require) || validation.isKey(`direction.${index}.${field?.field}`)}
                                    helperText={validation.get(`direction.${index}.${field?.field}`)}
                                />
                                : null
                            )
                        ))}
                    </FormControl>
                </>
            )}
        </>
    );
};

export default DirTypeSelect;
