import { reactRoutes } from 'modules/React';
import { transportWater } from 'modules/TransportWater';
import { metroRoutes } from 'modules/Metro';
import { dataBusRoutes } from 'modules/DataBus';
import { externalTransport } from 'modules/ExternalTransport';
import { dtpRoutes } from 'modules/Dtp';
import HomePage from 'components/pages/Homepage';

import dictionaries from './Dictionaries/index';
import mapRoutes from './map.routes';
import adminRoutes from './admin.routes';
import profile from './profile.routes';
import reports from './Reports/index';
import showcase from './showcase.routes';
import monitoring from './monitoring.routes';

const routes = [
    {
        path: '/',
        component: HomePage,
        exact: true,
    },
    ...dictionaries,
    ...mapRoutes,
    ...adminRoutes,
    ...profile,
    ...reports,

    // витрина
    ...showcase,
    ...monitoring,

    // modules
    ...reactRoutes,
    ...transportWater,
    ...metroRoutes,
    ...dataBusRoutes,
    ...externalTransport,
    ...dtpRoutes,
];

export default routes;
