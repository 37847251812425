import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadKeyWordList } from 'redux/SituationalPlans/actions';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import titles from 'helpers/constants/titles';

import ModalAddEdit from './ModalAddEdit';
import Filters from './Filters';
import Item from './Item';
import type { Data } from './types';

function KeyWords() {
    const dispatch = useDispatch();

    const [modalOpen, setModalOpen] = useState(false);

    const data = useSelector(SituationalPlansSelectors.keyWordsData);
    const meta = useSelector(SituationalPlansSelectors.keyWordsMeta);
    const keyWordsLoading = useSelector(SituationalPlansSelectors.keyWordsLoading);

    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    
    const reloadList = (isDelete: boolean) => {
        const page = isDelete && data?.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadKeyWordList(params.page, limit, params.data));
    },[dispatch, limit, params]);

    const renderContent = () => (
        <LSContainer headers={[
            { title: titles.NAME },
            { title: titles.ACTIONS, align: 'right', isActions: true },
        ]}>
            {data?.map((item: Data) => 
                <Item key={item?.id} data={item} reloadList={reloadList}/>
            )}
        </LSContainer>
    );

    return (
        <>
            <PageLayout
                header="Ключевые слова"
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    accessProp: '',
                                    onClick: () => setModalOpen(true)
                                }
                            ]}
                            positionLeft
                            noPadding
                        />
                    ),
                    total: meta?.total
                }}
                loading={keyWordsLoading}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: meta,
                    limit: limit,
                    setLimit: setLimit,
                }}
            />
            {modalOpen
                && <ModalAddEdit
                    onClose={() => setModalOpen(false)}
                    isOpen={modalOpen}
                    isNew={true}
                    reloadList={reloadList}
                    data={undefined}
                />
            }
        </>
    );
}

export default KeyWords;
