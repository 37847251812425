import Incidents from 'modules/React/Dictionaries/Incidents';
import TypicalOperations from 'modules/React/Dictionaries/TypicalOperations';
import IncidentFactors from 'modules/React/Dictionaries/IncidentFactors';
import Triggers from 'modules/React/Dictionaries/Triggers';
import IncidentsTypes from 'modules/React/Dictionaries/IncidentsTypes';
import IncidentsHeatMap from 'modules/React/Dictionaries/IncidentsHeatMap';
import KeyWords from 'modules/React/Dictionaries/KeyWords';

const dictionariesRoutes = [
    {
        path: '/react/dictionaries/incidents',
        component: Incidents,
        exact: true,
    },
    {
        path: '/react/typical-operations',
        component: TypicalOperations,
        exact: true,
    },
    {
        path: '/react/user-incident-factors',
        component: IncidentFactors,
        exact: true,
    },
    {
        path: '/react/dictionaries/triggers',
        component: Triggers,
        exact: true,
    },
    {
        path: '/dictionaries/react/incidents-types',
        component: IncidentsTypes,
        exact: true,
    },
    {
        path: '/react/dictionaries/incidents-heat-map',
        component: IncidentsHeatMap,
        exact: true,
    },
    {
        path: '/react/dictionaries/keywords',
        component: KeyWords,
        exact: true,
    },
];

export default dictionariesRoutes;
