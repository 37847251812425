import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { groupBy } from 'lodash';

import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import Loading from 'components/common/Loading';
import PageLayout from 'components/layout/PageLayout';
import { loadCountByMonths, loadCountByPeriods } from 'modules/Dtp/redux/actions';

import Filter from './Filter';
import Graphic from './Graphic';
import GraphicListBar from './GraphicListBar';
import NoData from './NoData';


const DtpGraph = () => {
    const dispatch = useDispatch();

    // perion
    const [loadingPeriod, setLoadingPeriod] = useState(false);
    const [dataPeriod, setDataPeriod] = useState({});

    // month
    const [loadingMonths, setLoadingMonths] = useState(false);
    const [dataMonths, setDataMonths] = useState({});

    const [isLoaded, setIsLoaded] = useState(false);

    const gruppedPeriod = useMemo(() => groupBy(dataPeriod, 'title') || {}, [dataPeriod]);

    const handleChange = (fields) => {
        dispatch(loadCountByMonths(
            fields,
            setDataMonths,
            setLoadingMonths
        ));

        dispatch(loadCountByPeriods(
            {
                ...fields,
                dates: fields?.dates?.map(({ start_date, end_date }) => ({
                    start_date: dateWithDashYYYYMMDD(start_date),
                    end_date: dateWithDashYYYYMMDD(end_date),
                }))
            },
            setDataPeriod,
            setLoadingPeriod
        ));

        setIsLoaded(true);
    };

    useEffect(() => () => {
        setIsLoaded(false);
        setDataPeriod({});
        setDataMonths({});
        setLoadingPeriod(false);
        setLoadingMonths(false);
    }, []);

    const renderContent = () => (
        <>
            <div className="block" style={{ marginTop: '1rem' }}>
                {dataMonths?.length > 0
                    ? <Graphic data={dataMonths} />
                    : (!loadingMonths && isLoaded && <NoData />)
                }
            </div>

            <div className="block">
                {/*{loadingPeriod && <Loading circular={true}/>}*/}
                {Object.keys(gruppedPeriod).length > 0
                    ? Object.keys(gruppedPeriod).map((key) => (
                        <GraphicListBar
                            key={key}
                            title={key}
                            list={gruppedPeriod[key]}
                        />
                    ))
                    : (!loadingPeriod && isLoaded && <NoData />)
                }
            </div>
        </>
    );

    return (
        <>
            <PageLayout
                header="Отчет по сравнению периодов с ДТП (график)"
                filters={<Filter onChange={handleChange} />}
                loading={loadingMonths || loadingPeriod}
                content={renderContent}
            />
        </>
    );
};

export default DtpGraph;
