import { useState } from 'react';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({ setParams }) => {
    const [value, setValue] = useState({
        name: ''
    });

    const handleChange = (e) => {
        setValue({ name: e.target.value });
    };

    const search = (data = value) => {
        setParams({ data });
    };

    const resetSearch = (needRefresh) => {
        setValue({ name: '' });
        if (needRefresh) {
            search({ data: {} });
        }
    };

    const updateFilter = (filters) => {
        setValue(filters);
        if (value.name) {
            search(filters);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch} 
            onSearch={search} 
            disabled={!value.name}
            filter={value} 
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={value.name || ''}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filters;