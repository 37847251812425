import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { useStoreFromSelectorListToObject, useValidation } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import { reactSelectors } from 'modules/React';
import { createTrigger, editTrigger, loadTriggerStatuses } from 'modules/React/redux/actions';
import SelectScenarios from 'modules/React/utils/components/SelectScenarios';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';

import Phenomenons from './Phenomenons';

import type { Trigger } from '../types';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';

interface ModalFormProps {
    isOpen: boolean;
    onClose: () => void;
    isNew: boolean;
    item?: Trigger;
    reloadList: () => void;
}

const ModalForm = ({ isOpen, onClose, isNew, item, reloadList }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const statuses = useStoreFromSelectorListToObject(loadTriggerStatuses, reactSelectors.triggerStatuses);

    const initialState = {
        status: statuses.get(item?.status || 2),
        name: item?.name || '',
        type: item?.type || null,
        type_id: item?.type_id || null,
        responseScenario: item?.responseScenario || null,
        response_scenario_id: item?.response_scenario_id || null,
        threat_level: item?.threat_level || null,
        threat_level_id: item?.threat_level_id || null,
        phenomenons: item?.phenomenons || [],
    };

    const [formData, setFormData] = useState(initialState);

    const handleChange = (name: string, value: any) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const preparedData = removeEmptyFields({
            ...formData,
            status: formData.status?.id,
            type_id: formData.type?.id,
            response_scenario_id: formData.responseScenario?.id,
            threat_level_id: formData.threat_level?.id,
        }, false);

        dispatch(isNew
            ? createTrigger(preparedData, callback)
            : editTrigger(item?.id, preparedData, callback)
        );
    };

    const isDisabled = !formData.name
        || !formData.type?.id
        || !formData.responseScenario?.id
        || !formData.threat_level?.id
        || formData.phenomenons?.length === 0;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? 'Создание' : 'Изменение'}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <CommonAutocomplete
                    className="block"
                    label={titles.STATUS}
                    options={statuses.list as any[]}
                    selected={formData.status}
                    onChange={(value) => handleChange('status', value)}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    renderLabel={(option) => option?.name || ''}
                    showAsSelect
                    error={validation.isKey('status')}
                    helperText={validation.get('status')}
                />
                <FormInfoWrapper
                    className="block"
                    helperText={validation.get('name')}
                    error={validation.isKey('name')}
                >
                    <TextField
                        label={titles.NAME}
                        value={formData.name || ''}
                        onChange={(e) => handleChange('name', e.target.value)}
                        name={'name'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>
                <SelectIncidentTypes
                    className="block"
                    required={true}
                    selected={formData.type}
                    onChange={(value) => handleChange('type', value)}
                    error={validation.isKey('type_id')}
                    helperText={validation.get('type_id')}
                />
                <SelectScenarios
                    className="block"
                    required={true}
                    selected={formData.responseScenario}
                    onChange={(value) => handleChange('responseScenario', value)}
                    error={validation.isKey('response_scenario_id')}
                    helperText={validation.get('response_scenario_id')}
                />
                <SelectThreatLevels
                    className="block"
                    required={true}
                    selected={formData.threat_level}
                    onChange={(value) => handleChange('threat_level', value)}
                    error={validation.isKey('threat_level_id')}
                    helperText={validation.get('threat_level_id')}
                />
                <Phenomenons
                    phenomenons={formData.phenomenons}
                    onChange={(value) => handleChange('phenomenons', value)}
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
