import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import { TextField } from '@mui/material';

import { getStartOf } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import DateRange from 'components/common/Dates/DateRange';
import StatusesSelect from 'components/common/Autocomplete/Statuses';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';
import { loadIncidentsStatuses } from 'modules/React/redux/actions';
import SelectScenarios from 'modules/React/utils/components/SelectScenarios';
import SelectThreatLevels from 'modules/React/utils/components/SelectThreatLevels';
import SelectIncidentTypes from 'modules/React/utils/components/SelectIncidentTypes';

import type { FilterParams } from './types';

type FiltersProps = {
  setParams: Dispatch<SetStateAction<FilterParams>>;
}

const initState: FilterParams['data'] = {
    start_date: getStartOf('month'),
    end_date: new Date(),
    incident_id: '',
    name: '',
    address: '',
    threat_level: [],
    status: [
        {
            id: 3,
            name: 'Завершен',
            color: '#33CC33'
        },
        {
            id: 2,
            name: 'В работе',
            color: '#0099cc'
        },
        {
            id: 6,
            name: 'Прогноз',
            color: '#3333cc'
        },
        {
            id: 1,
            name: 'Новый',
            color: '#ff66ff'
        }
    ],
    incident_types: [],
    organizations: [],
    response_scenario_id: [],
    incident_date_start: null,
    incident_date_end: null,
    plan_date_start: null,
    plan_date_end: null,
    fact_date_start: null,
    fact_date_end: null,
};

export const Filters = ({ setParams }: FiltersProps) => {
    const statuses = useStoreProp(loadIncidentsStatuses, 'react', 'incidentsStatuses');

    const [filter, setFilter] = useState<FilterParams['data']>(initState);

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFilter({ ...filter, [name]: value });
    };

    const onChangeByKeyValue = (value: any, key: keyof FilterParams['data']) => {
        setFilter({ ...filter, [key]: value });
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            setParams(prev => ({ ...prev, page: 1, data: initState }));
        }

        setFilter(initState);
    };

    const onSearch = () => {
        setParams(prev => ({ ...prev, page: 1, data: filter }));
    };

    const updateFilter = (filters: { [key: string]: any }) => {
        setFilter({ ...initState, ...filters });

        setParams(prev => ({ ...prev, page: 1, data: { ...initState, ...filters } }));
    };

    return (
        <LayoutFilter
            filter={filter}
            onResetFilter={resetFilter}
            onSearch={onSearch}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filter?.start_date || null}
                    valueEndDate={filter?.end_date || null}
                    handleDate={(start_date) => setFilter({ ...filter, start_date })}
                    handleEndDate={(end_date) => setFilter({ ...filter, end_date })}
                    dateOnly={true}
                    equalDates={true}
                    // renderError={() => isPeriodInvalid}
                    // errorMessage={() => titles.SELECT_PERIOD}
                />
                <TextField
                    label={'Номер'}
                    variant="outlined"
                    size="small"
                    type="number"
                    inputProps={{ min: 0 }}
                    value={filter.incident_id}
                    name="incident_id"
                    onChange={onChange}
                />
            </LayoutFilter.Visible>

            <LayoutFilter.Invisible>
                <TextField
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={filter.name}
                    name="name"
                    onChange={onChange}
                />

                <TextField
                    label={titles.ADDRESS}
                    variant="outlined"
                    size="small"
                    value={filter.address}
                    name="address"
                    onChange={onChange}
                />

                <SelectThreatLevels
                    multiple
                    selected={filter?.threat_level || []}
                    onChange={(value: any) => onChangeByKeyValue(value, 'threat_level')}
                />

                <StatusesSelect
                    multiple
                    selected={filter?.status}
                    options={statuses}
                    renderLabel={(option) => option?.name || ''}
                    onChange={(value) => onChangeByKeyValue(value, 'status')}
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
                />

                <SelectIncidentTypes
                    multiple
                    selected={filter?.incident_types}
                    onChange={(val) => {
                        onChangeByKeyValue(val, 'incident_types');
                    }}
                    label="Тип"
                />

                <AutocompleteCompanies
                    onChange={(value) => {
                        // @ts-ignore
                        onChangeByKeyValue(value?.map((item) => ({ ...item, name: item.title })), 'organizations');
                    }}
                    selected={filter?.organizations || []}
                    multiple
                    filter={{ withDeleted: 1 }}
                    label={'Организация'}
                />

                <SelectScenarios
                    multiple
                    selected={filter.response_scenario_id}
                    // @ts-ignore
                    onChange={(value) => onChangeByKeyValue(value, 'response_scenario_id')}
                />


                <DateRange
                    valueStartDate={filter?.incident_date_start || null}
                    valueEndDate={filter?.incident_date_end || null}
                    handleDate={(incident_date_start) => setFilter({ ...filter, incident_date_start })}
                    handleEndDate={(incident_date_end) => setFilter({ ...filter, incident_date_end })}
                    dateOnly={true}
                    title="Дата инцидента"
                    // renderError={() => isPeriodInvalid}
                    // errorMessage={() => titles.SELECT_PERIOD}
                />

                <DateRange
                    valueStartDate={filter?.plan_date_start || null}
                    valueEndDate={filter?.plan_date_end || null}
                    handleDate={(plan_date_start) => setFilter({ ...filter, plan_date_start })}
                    handleEndDate={(plan_date_end) => setFilter({ ...filter, plan_date_end })}
                    dateOnly={true}
                    title="Дата закрытия план"
                    // renderError={() => isPeriodInvalid}
                    // errorMessage={() => titles.SELECT_PERIOD}
                />

                <DateRange
                    valueStartDate={filter?.fact_date_start || null}
                    valueEndDate={filter?.fact_date_end || null}
                    handleDate={(fact_date_start) => setFilter({ ...filter, fact_date_start })}
                    handleEndDate={(fact_date_end) => setFilter({ ...filter, fact_date_end })}
                    dateOnly={true}
                    title="Дата закрытия факт"
                    // renderError={() => isPeriodInvalid}
                    // errorMessage={() => titles.SELECT_PERIOD}
                />
            </LayoutFilter.Invisible>

        </LayoutFilter>
    );
};
