import * as types from './types';

/**
 * Actions
 **/

// остановки
export const loadStationsList = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_STATIONS,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadingStationsList = (bool = false) => ({
    type: types.LOADING_STATIONS,
    payload: bool,
});

export const loadedStationsList = (data) => ({
    type: types.LOADED_STATIONS,
    payload: data,
});

export const createNewStation = (data, callback) => ({
    type: types.CREATE_NEW_STATIONS,
    payload: data,
    callback
});

export const editStation = (id, data, callback) => ({
    type: types.EDIT_STATION,
    payload: { id, data },
    callback
});

export const deleteStation = (id, callback) => ({
    type: types.DELETE_STATION,
    payload: id,
    callback
});

export const loadStationTypes = () => ({
    type: types.LOAD_STATION_TYPES
});

export const loadedStationTypes = (data) => ({
    type: types.LOADED_STATION_TYPES,
    payload: data
});

// расписание
// todo такого метода больше нет - аккуратно удалить
export const loadStationSchedule = (query) => ({
    type: types.LOAD_SCHEDULE,
    payload: query,
});

export const loadingSchedule = (bool = false) => ({
    type: types.LOADING_SCHEDULE,
    payload: bool,
});

export const loadedSchedule = (data) => ({
    type: types.LOADED_SCHEDULE,
    payload: data,
});
// конец удаления

export const addSchedule = (data) => ({
    type: types.ADD_SCHEDULE,
    payload: data,
});

export const deleteSchedule = (data) => ({
    type: types.DELETE_SCHEDULE,
    payload: data,
});

export const editSchedule = (data) => ({
    type: types.EDIT_SCHEDULE,
    payload: data,
});

// бренды
export const loadBrandsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_BRANDS,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedBrandsList = (data) => ({
    type: types.LOADED_BRANDS,
    payload: data,
});

export const createBrand = (params, callback = () => {}) => ({
    type: types.CREATE_BRAND,
    payload: params,
    callback
});

export const editBrand = (id, data, callback = () => {}) => ({
    type: types.EDIT_BRAND,
    payload: { id, data },
    callback
});

export const deleteBrand = (id, callback = () => {}) => ({
    type: types.DELETE_BRAND,
    payload: id,
    callback
});

// модели
export const loadModelsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_MODELS,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedModelsList = (data) => ({
    type: types.LOADED_MODELS,
    payload: data,
});

export const createModel = (data, callback) => ({
    type: types.CREATE_MODEL,
    payload: data,
    callback
});

export const editModel = (id, data, callback) => ({
    type: types.EDIT_MODEL,
    payload: { id, data },
    callback
});

export const deleteModel = (id, callback) => ({
    type: types.DELETE_MODEL,
    payload: id,
    callback
});

// транспортные средства
export const loadVehicleList = (page = 1, limit = 25, data) => ({
    type: types.LOAD_VEHICLES_LIST,
    payload: {
        page,
        limit,
        data,
    },
});

export const loadedVehicleList = (data) => ({
    type: types.LOADED_VEHICLES_LIST,
    payload: data
});

export const loadingVehicleList = (bool = false) => ({
    type: types.LOADING_VEHICLES_LIST,
    payload: bool
});

export const createdNewVehicle = (data, callback) => ({
    type: types.CREATE_VEHICLE,
    payload: data,
    callback
});

export const editVehicle = (id, data, callback) => ({
    type: types.EDIT_VEHICLE,
    payload: { id, data },
    callback
});

export const deletedVehicle = (id, callback) => ({
    type: types.DELETE_VEHICLE,
    payload: id,
    callback
});

export const loadVehicleListNoWorkorder = (page = 1, limit = 25, data) => ({
    type: types.LOAD_VEHICLES_LIST_NO_WORKORDER,
    payload: {
        page,
        limit,
        data,
    },
});

export const loadedVehicleListNoWorkorder = (data) => ({
    type: types.LOADED_VEHICLES_LIST_NO_WORKORDER,
    payload: data
});

// категории ТС
export const loadVehicleCategories = () => ({
    type: types.LOAD_VEHICLES_CATEGORIES
});

export const loadedVehicleCategories = (data) => ({
    type: types.LOADED_VEHICLES_CATEGORIES,
    payload: data
});

export const loadVehicleCategoryList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_VEHICLE_CATEGORY_LIST,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedVehicleCategoryList = (data) => ({
    type: types.LOADED_VEHICLE_CATEGORY_LIST,
    payload: data
});

export const loadingVehicleCategoryList = (bool = false) => ({
    type: types.LOADING_VEHICLE_CATEGORY_LIST,
    payload: bool
});

export const createVehicleCategory = (data, callback = () => {}) => ({
    type: types.CREATE_VEHICLE_CATEGORY,
    payload: data,
    callback
});

export const editVehicleCategory = (id, data, callback = () => {}) => ({
    type: types.EDIT_VEHICLE_CATEGORY,
    payload: { id, data },
    callback
});

export const deleteVehicleCategory = (id, callback = () => {}) => ({
    type: types.DELETE_VEHICLE_CATEGORY,
    payload: id,
    callback
});

// типы ТС
export const loadVehiclesTypes = () => ({
    type: types.LOAD_VEHICLES_TYPES
});

export const loadedVehiclesTypes = (data) => ({
    type: types.LOADED_VEHICLES_TYPES,
    payload: data
});

// организации пассажирский транспорт
export const loadCompaniesList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_COMPANIES,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedCompaniesList = (data) => ({
    type: types.LOADED_COMPANIES,
    payload: data,
});

export const createCompany = (params, callback) => ({
    type: types.CREATE_COMPANY,
    payload: params,
    callback,
});

export const editCompany = (params, callback) => ({
    type: types.EDIT_COMPANY,
    payload: params,
    callback,
});

export const deleteCompany = (id) => ({
    type: types.DELETE_COMPANY,
    payload: id,
});

export const getCompaniesList = (page = 1, limit = 10, query) => ({    // TODO сервис dadata вынести в отдельный модуль
    type: types.GET_COMPANIES,
    payload: {
        page,
        limit,
        query,
    },
});

export const saveCompaniesList = (data) => ({
    type: types.SAVE_COMPANIES,
    payload: data,
});

export const loadCompany = (page = 1, limit = 10, query) => ({
    type: types.LOAD_COMPANY,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadedCompany = (data) => ({
    type: types.LOADED_COMPANY,
    payload: data
});

// маршруты
export const loadRouteTypes = () => ({
    type: types.LOAD_ROUTE_TYPES,
});

export const loadedRouteTypes = (data) => ({
    type: types.LOADED_ROUTE_TYPES,
    payload: data,
});

export const loadRoutesGroup = (page = 1, limit = 25, data) => ({
    type: types.LOAD_ROUTES_GROUP,
    payload: { page, limit, data: { ...data, status: data?.status || [1,2,3] } }
});

export const loadingRoutesGroup = (bool = false) => ({
    type: types.LOADING_ROUTES_GROUP,
    payload: bool
});

export const loadingRoutes = (bool = false) => ({
    type: types.LOADING_ROUTES,
    payload: bool
});

export const loadedRoutes = (data) => ({
    type: types.LOADED_ROUTES,
    payload: data
});

export const loadedRoutesForTrafficSchedule = (data) => ({
    type: types.LOADED_ROUTES_FOR_TS,
    payload: data
});

export const loadRoutes = (page = 1, limit = 25, data, bool = false, callback) => ({
    type: types.LOAD_ROUTES,
    payload: { page, limit, data, bool },
    callback
});

export const deleteScheduleTemplateStation = (id, clickedId, selectedRoute) => ({
    type: types.DELETE_SCHEDULE_TEMPLATE_STATION,
    payload: { id, clickedId, selectedRoute }
});

export const loadedRoutesGroup = (data) => ({
    type: types.LOADED_ROUTES_GROUP,
    payload: data
});

export const loadRouteNums = () => ({
    type: types.LOAD_ROUTE_NUMS,
});

export const loadingRouteNums = (bool = false) => ({
    type: types.LOADING_ROUTE_NUMS,
    payload: bool
});

export const loadedRouteNums = (data) => ({
    type: types.LOADED_ROUTE_NUMS,
    payload: data
});

export const loadRoutesStatuses = () => ({
    type: types.LOAD_ROUTES_STATUSES
});

export const loadedRoutesStatuses = (data) => ({
    type: types.LOADED_ROUTES_STATUSES,
    payload: data
});

export const loadRoutesTransportations = () => ({
    type: types.LOAD_ROUTES_TRANSPORTATIONS
});

export const loadedRoutesTransportations = (data) => ({
    type: types.LOADED_ROUTES_TRANSPORTATIONS,
    payload: data
});

export const saveRoutes = (data) => ({
    type: types.SAVE_ROUTES,
    payload: data
});

export const saveRoute = (data, callback = () => {}) => ({
    type: types.SAVE_ROUTE,
    payload: data,
    callback
});

export const changeRoute = (uuid, data, callback = () => {}) => ({
    type: types.CHANGE_ROUTE,
    payload: { uuid, data },
    callback
});


export const saveRouteV2 = (data, callback = () => {}) => ({
    type: types.SAVE_ROUTE_V2,
    payload: data,
    callback
});

export const changeRouteV2 = (uuid, data, callback = () => {}) => ({
    type: types.CHANGE_ROUTE_V2,
    payload: { uuid, data },
    callback
});

export const setRouteLoading = (bool = false) => ({
    type: types.SET_ROUTE_LOADING,
    payload: bool
});

export const changeRoutesAfter = (data = {}) => ({
    type: types.CHANGE_ROUTE_AFTER,
    payload: data
});

export const setChangeRoutesStatus = (bool = false) => ({
    type: types.SET_CHANGE_ROUTE_STATUS,
    payload: bool
});

export const loadRouteSchedule = (data) => ({
    type: types.LOAD_ROUTE_SCHEDULE,
    payload: data
});

export const loadingRouteSchedule = (bool = false) => ({
    type: types.LOADING_ROUTE_SCHEDULE,
    payload: bool
});

export const loadedRouteSchedule = (data) => ({
    type: types.LOADED_ROUTE_SCHEDULE,
    payload: data
});

export const loadScheduleTypes = () => ({
    type: types.LOAD_SCHEDULE_TYPES,
});

export const loadedScheduleTypes = (data) => ({
    type: types.LOADED_SCHEDULE_TYPES,
    payload: data
});

export const loadRouteStation = (page = 1, limit = 25, data) => ({
    type: types.LOAD_ROUTE_STATION,
    payload: { page, limit, data }
});

export const loadedRouteStation = (data) => ({
    type: types.LOADED_ROUTE_STATION,
    payload: data
});

export const loadingRouteStation = (bool = false) => ({
    type: types.LOADING_ROUTE_STATION,
    payload: bool
});

export const getPassengerVehicleReport = (params) => ({
    type: types.GET_VEHICLE_REPORT,
    payload: params
});

export const getFilteredTSList = (params = {}) => ({
    type: types.GET_FILTERED_LIST,
    payload: params
});

export const loadedFilteredTSList = (data) => ({
    type: types.LOADED_FILTERED_LIST,
    payload: data
});

export const loadingFilteredTSList = (bool = false) => ({
    type: types.LOADING_FILTERED_LIST,
    payload: bool
});

export const setFilteredTSListFilter = (data) => ({
    type: types.SET_FILTERED_LIST_FILTER,
    payload: data
});

export const clearFilteredTSListFilter = (data) => ({
    type: types.CLEAR_FILTERED_LIST_FILTER,
    payload: data
});

// export const getCurrentPassengerReport = (id, params = {}, callbackClose) => ({
//     type: types.GET_CURRENT_PASSENGER_REPORT,
//     payload: { id, params },
//     callbackClose
// });

export const getCurrentSpecialReport = (uid, params = {}, callbackClose) => ({
    type: types.GET_CURRENT_SPECIAL_REPORT,
    payload:  { uid, params },
    callbackClose
});

export const setActive = (data) => ({
    type: types.SET_ACTIVE,
    payload: data
});
export const clearActive = () => ({
    type: types.CLEAR_ACTIVE
});

export const setWsTelemetry = (data = null) => ({
    type: types.SET_WS_DATA,
    payload: data
});
export const clearWsTelemetry = () => ({
    type: types.CLEAR_WS_DATA,
});

// следующая остановка
export const loadNextStation = (route_num, vehicle_uuid) => ({
    type: types.LOAD_NEXT_STATION,
    payload: { route_num, vehicle_uuid }
});
export const loadedNextStation = (data) => ({
    type: types.LOADED_NEXT_STATION,
    payload: data
});
export const loadingNextStation = (bool = false) => ({
    type: types.LOADING_NEXT_STATION,
    payload: bool
});

// информация о транспорте
export const loadVehiclePopup = (external_uuid) => ({
    type: types.LOAD_VEHICLE_POPUP,
    payload: { external_uuid }
});
export const clearVehiclePopup = () => ({
    type: types.LOADED_VEHICLE_POPUP,
    payload: {}
});
export const loadedVehiclePopup = (data) => ({
    type: types.LOADED_VEHICLE_POPUP,
    payload: data
});
export const loadingVehiclePopup = (bool = false) => ({
    type: types.LOADING_VEHICLE_POPUP,
    payload: bool
});

// информация о транспорте
export const loadVehicleHistory = (id, params) => ({
    type: types.LOAD_VEHICLE_HISTORY,
    payload: { id, params }
});
export const clearVehicleHistory = () => ({
    type: types.LOADED_VEHICLE_HISTORY,
    payload: []
});
export const loadedVehicleHistory = (data) => ({
    type: types.LOADED_VEHICLE_HISTORY,
    payload: data
});
export const loadingVehicleHistory = (bool = false) => ({
    type: types.LOADING_VEHICLE_HISTORY,
    payload: bool
});

// История движения ТС в рейсе
export const loadVehicleFlightHistory = (id, params) => ({
    type: types.LOAD_VEHICLE_FLIGHT_HISTORY,
    payload: { id, params }
});
export const clearVehicleFlightHistory = () => ({
    type: types.LOADED_VEHICLE_FLIGHT_HISTORY,
    payload: {}
});
export const loadedVehicleFlightHistory = (data) => ({
    type: types.LOADED_VEHICLE_FLIGHT_HISTORY,
    payload: data
});
export const loadingVehicleFlightHistory = (bool = false) => ({
    type: types.LOADING_VEHICLE_FLIGHT_HISTORY,
    payload: bool
});

export const setFilter = (filter) => ({
    type: types.SET_FILTER,
    payload: filter
});
export const changeFilter = (filter) => ({
    type: types.CHANGE_FILTER,
    payload: filter
});
export const clearFilter = () => ({
    type: types.SET_FILTER,
    payload: {}
});

export const loadingCurrentVehicles = (bool = false) => ({
    type: types.LOADING_CURRENT_VEHICLES,
    payload: bool
});

export const loadCurrentVehicles = (id) => ({
    type: types.LOAD_CURRENT_VEHICLES,
    payload: id
});

export const loadedCurrentVehicles = (data) => ({
    type: types.LOADED_CURRENT_VEHICLES,
    payload: data
});

export const editVehicleToRoute = (id, data, routeNum) => ({
    type: types.EDIT_VEHICLE_TO_ROUTE,
    payload: { id, data, routeNum }
});

export const loadCurrentVehicleType = (page = 1, limit = 10, query, category_id) => ({
    type: types.LOAD_CURRENT_VEHICLE_TYPE,
    payload: { page, limit, query, category_id }
});

export const loadingCurrentVehicleType = (bool = false) => ({
    type: types.LOADING_CURRENT_VEHICLE_TYPE,
    payload: bool
});

export const loadedCurrentVehicleType = (data) => ({
    type: types.LOADED_CURRENT_VEHICLE_TYPE,
    payload: data
});

export const saveSelectedVehicleToRoute = (data, routeNum) => ({
    type: types.SAVE_SELECTED_VEHICLE_TO_ROUTE,
    payload: { data, routeNum }
});

export const loadedRoutes_ = (data) => ({
    type: types.LOADED_ROUTES_,
    payload: data
});

export const loadRoutes_ = (page = 1, limit = 25, params) => ({
    type: types.LOAD_ROUTES_,
    payload: {
        page,
        limit,
        params
    }
});

export const loadPositions = (page = 1, limit = 25, data) => ({
    type: types.LOAD_POSITIONS,
    payload: { page, limit, data }
});

export const loadedPositions = (data) => ({
    type: types.LOADED_POSITIONS,
    payload: data
});

export const loadingPositions = (bool = false) => ({
    type: types.LOADING_POSITIONS,
    payload: bool
});

export const loadPosition = (page = 1, limit = 10, data) => ({
    type: types.LOAD_POSITION,
    payload: { page, limit, data }
});

export const loadedPosition = (data) => ({
    type: types.LOADED_POSITION,
    payload: data
});

export const loadingPosition = (bool = false) => ({
    type: types.LOADING_POSITION,
    payload: bool
});

export const editPositions = (id, data, callback) => ({
    type: types.EDIT_POSITIONS,
    payload: { id, data },
    callback,
});

export const addPositions = (data, callback) => ({
    type: types.ADD_POSITIONS,
    payload: data,
    callback,
});

export const deletePositions = (id, callback) => ({
    type: types.DELETE_POSITIONS,
    payload: id,
    callback
});

export const loadScheduleEvent = (page = 1, limit = 25, data) => ({
    type: types.LOAD_SCHEDULE_EVENTS,
    payload: { page, limit, data }
});

export const loadedScheduleEvent = (data) => ({
    type: types.LOADED_SCHEDULE_EVENTS,
    payload: data
});

export const loadingScheduleEvent = (bool = false) => ({
    type: types.LOADING_SCHEDULE_EVENTS,
    payload: bool
});

export const editScheduleEvent = (id, data) => ({
    type: types.EDIT_SCHEDULE_EVENTS,
    payload: { id, data }
});

export const addScheduleEvent = (data) => ({
    type: types.ADD_SCHEDULE_EVENTS,
    payload: data
});

export const deleteScheduleEvent = (id) => ({
    type: types.DELETE_SCHEDULE_EVENTS,
    payload: id
});

export const loadEmployees = (page = 1, limit = 25, data) => ({
    type: types.LOAD_EMPLOYEES,
    payload: { page, limit, data }
});

export const loadEmployee = (page = 1, limit = 25, data, callback) => ({
    type: types.LOAD_EMPLOYEE,
    payload: { page, limit, data },
    callback
});

export const loadedEmployees = (data) => ({
    type: types.LOADED_EMPLOYEES,
    payload: data
});

export const loadedEmployee = (data) => ({
    type: types.LOADED_EMPLOYEE,
    payload: data
});

export const loadingEmployees = (bool = false) => ({
    type: types.LOADING_EMPLOYEES,
    payload: bool
});

export const loadingEmployee = (bool = false) => ({
    type: types.LOADING_EMPLOYEE,
    payload: bool
});

export const editEmployees = (id, data, callback) => ({
    type: types.EDIT_EMPLOYEES,
    payload: { id, data },
    callback,
});

export const addEmployees = (data, callback) => ({
    type: types.ADD_EMPLOYEES,
    payload: data,
    callback,
});

export const deleteEmployees = (id, callback) => ({
    type: types.DELETE_EMPLOYEES,
    payload: id,
    callback,
});

export const loadBrigades = (page = 1, limit = 25, data) => ({
    type: types.LOAD_BRIGADES,
    payload: { page, limit, data }
});

export const loadedBrigades = (data) => ({
    type: types.LOADED_BRIGADES,
    payload: data
});

export const loadingBrigades = (bool = false) => ({
    type: types.LOADING_BRIGADES,
    payload: bool
});

export const editBrigades = (id, data, callback) => ({
    type: types.EDIT_BRIGADES,
    payload: { id, data },
    callback,
});

export const addBrigades = (data, callback) => ({
    type: types.ADD_BRIGADES,
    payload: data,
    callback,
});

export const deleteBrigades = (id, callback) => ({
    type: types.DELETE_BRIGADES,
    payload: id,
    callback,
});

export const loadBrigadesNoWorkorder = (page = 1, limit = 25, data) => ({
    type: types.LOAD_BRIGADES_NO_WORKORDER,
    payload: { page, limit, data }
});

export const loadedBrigadesNoWorkorder = (data) => ({
    type: types.LOADED_BRIGADES_NO_WORKORDER,
    payload: data
});

// equipments
export const loadEquipmentsList = () => ({
    type: types.LOAD_EQUIPMENTS_LIST
});

export const loadingEquipmentsList = (bool = false) => ({
    type: types.LOADING_EQUIPMENTS_LIST,
    payload: bool
});

export const loadedEquipmentsList = (data) => ({
    type: types.LOADED_EQUIPMENTS_LIST,
    payload: data
});

// SCHEDULE_TEMPLATE
export const loadScheduleTemplateList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_SCHEDULE_TEMPLATE_LIST,
    payload: { page, limit, query }
});

export const loadingScheduleTemplateList = (bool = false) => ({
    type: types.LOADING_SCHEDULE_TEMPLATE_LIST,
    payload: bool
});

export const loadedScheduleTemplateList = (data) => ({
    type: types.LOADED_SCHEDULE_TEMPLATE_LIST,
    payload: data
});

export const clearScheduleTemplateList = () => ({
    type: types.CLEAR_SCHEDULE_TEMPLATE_LIST,
});

export const loadScheduleTemplateStatuses = () => ({
    type: types.LOAD_SCHEDULE_TEMPLATE_STATUSES
});

export const loadedScheduleTemplateStatuses = (data) => ({
    type: types.LOADED_SCHEDULE_TEMPLATE_STATUSES,
    payload: data
});

export const createScheduleTemplate = (data, callback = () => {}) => ({
    type: types.CREATE_SCHEDULE_TEMPLATE,
    payload: data,
    callback
});

export const editScheduleTemplate = (template, data, callback = () => {}) => ({
    type: types.EDIT_SCHEDULE_TEMPLATE,
    payload: { template, data },
    callback
});

export const deleteScheduleTemplate = (template, callback = () => {}) => ({
    type: types.DELETE_SCHEDULE_TEMPLATE,
    payload: { template },
    callback
});

export const loadScheduleTemplateStationsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_SCHEDULE_TEMPLATE_STATIONS_LIST,
    payload: { page, limit, query }
});

export const loadingScheduleTemplateStationsList = (bool = false)  => ({
    type: types.LOADING_SCHEDULE_TEMPLATE_STATIONS_LIST,
    payload: bool
});

export const loadedScheduleTemplateStationsList = (id, data) => ({
    type: types.LOADED_SCHEDULE_TEMPLATE_STATIONS_LIST,
    payload: { id, data }
});

export const saveRouteStations = (data, callback = () => {}) => ({
    type: types.SAVE_ROUTE_STATIONS,
    payload: data,
    callback
});

export const editScheduleTemplateStation = (id, clickedId, selectedRoute, data) => ({
    type: types.EDIT_SCHEDULE_TEMPLATE_STATION,
    payload: { id, clickedId, selectedRoute, data }
});

export const createScheduleTemplateStation = (data, clickedId, selectedRoute) => ({
    type: types.CREATE_SCHEDULE_TEMPLATE_STATION,
    payload: { data, clickedId, selectedRoute }
});

//PARKS
export const loadParksList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_PARKS_LIST,
    payload: { page, limit, query }
});

export const loadingParksList = (bool = false) => ({
    type: types.LOADING_PARKS_LIST,
    payload: bool
});

export const loadedParksList = (data) => ({
    type: types.LOADED_PARKS_LIST,
    payload: data
});

export const createPark = (data, callback = () => {}) => ({
    type: types.CREATE_PARK,
    payload: data,
    callback
});

export const editPark = (id, data, callback = () => {}) => ({
    type: types.EDIT_PARK,
    payload: { id, data },
    callback
});

export const deletePark = (id, callback = () => {}) => ({
    type: types.DELETE_PARK,
    payload: id,
    callback
});

export const loadIntersectionRoutes = (data) => ({
    type: types.LOAD_INTERSECTION_ROUTES,
    payload: data
});

export const loadedIntersectionRoutes = (data) => ({
    type: types.LOADED_INTERSECTION_ROUTES,
    payload: data
});

export const loadWorkorders = (page = 1, limit = 25, data) => ({
    type: types.LOAD_WORKORDERS,
    payload: { page, limit, data }
});

export const loadedWorkorders = (data) => ({
    type: types.LOADED_WORKORDERS,
    payload: data
});

export const loadingWorkorders = (bool = false) => ({
    type: types.LOADING_WORKORDERS,
    payload: bool
});

export const loadCurrentWorkorder = (page = 1, limit = 25, data, callback) => ({
    type: types.LOAD_CURRENT_WORKORDER,
    payload: { page, limit, data },
    callback,
});

// export const loadedCurrentWorkorder = (data) => ({
//     type: types.LOADED_CURRENT_WORKORDER,
//     payload: data
// });

export const loadingCurrentWorkorder = (bool = false) => ({
    type: types.LOADING_CURRENT_WORKORDER,
    payload: bool
});

export const createWorkorder = (data, callback = () => {}) => ({
    type: types.CREATE_WORKORDER,
    payload: data,
    callback
});

export const deleteWorkorder = (id, callback = () => {}) => ({
    type: types.DELETE_WORKORDER,
    payload: id,
    callback
});

export const editWorkorder = (id, data, callback = () => {}) => ({
    type: types.EDIT_WORKORDER,
    payload: { id, data },
    callback
});

export const editWorkorderStatus = (id, data, callback) => ({
    type: types.EDIT_WORKORDER_STATUS,
    payload: { id, data },
    callback
});

export const clearWorkorders = () => ({
    type: types.CLEAR_WORKORDERS
});

export const setSelectedWorkorderData = (data) => ({
    type: types.SET_SELECTED_WORKORDER_DATA,
    payload: data
});

export const loadWorkorderEvents = (id) => ({
    type: types.LOAD_WORKORDER_EVENTS,
    payload: id
});

export const loadedWorkorderEvents = (data) => ({
    type: types.LOADED_WORKORDER_EVENTS,
    payload: data
});

export const loadWorkorderEventStatuses = () => ({
    type: types.LOAD_WORKORDER_EVENT_STATUSES
});

export const loadedWorkorderEventStatuses = (data) => ({
    type: types.LOADED_WORKORDER_EVENT_STATUSES,
    payload: data
});

export const loadingWorkorderEvents = (bool = false) => ({
    type: types.LOADING_WORKORDER_EVENTS,
    payload: bool
});

export const loadTrafficSchedule = (page = 1, limit = 25, data, callback) => ({
    type: types.LOAD_TRAFFIC_SCHEDULE,
    payload: { page, limit, data },
    callback
});

export const loadAllTrafficSchedule = (data, callback) => ({
    type: types.LOAD_TRAFFIC_SCHEDULE,
    payload: { data },
    callback
});

export const loadedTrafficSchedule = (data) => ({
    type: types.LOADED_TRAFFIC_SCHEDULE,
    payload: data
});

export const loadingTrafficSchedule = (bool = false) => ({
    type: types.LOADING_TRAFFIC_SCHEDULE,
    payload: bool
});

export const loadTrafficScheduleNoWorkorder = (data, callback) => ({
    type: types.LOAD_TRAFFIC_SCHEDULE_NO_WORKORDER,
    payload: { data },
    callback,
});

export const loadedTrafficScheduleNoWorkorder = (data) => ({
    type: types.LOADED_TRAFFIC_SCHEDULE_NO_WORKORDER,
    payload: data
});

export const loadWorkordersByDate = (page = 1, limit = 25, data) => ({
    type: types.LOAD_WORKORDERS_BY_DATE,
    payload: { page, limit, data }
});

export const loadedWorkordersByDate = (data) => ({
    type: types.LOADED_WORKORDERS_BY_DATE,
    payload: data
});

export const loadingWorkordersByDate = (bool = false) => ({
    type: types.LOADING_WORKORDERS_BY_DATE,
    payload: bool
});

export const loadWorkorderStatuses = () => ({
    type: types.LOAD_WORKORDER_STATUSES,
});

export const loadedWorkorderStatuses = (data) => ({
    type: types.LOADED_WORKORDER_STATUSES,
    payload: data,
});

export const clearWorkordersByDate = () => ({
    type: types.CLEAR_WORKORDERS_BY_DATE,
});

export const loadTrafficScheduleStatuses = () => ({
    type: types.LOAD_TRAFFIC_SCHEDULE_STATUSES,
});

export const loadedTrafficScheduleStatuses = (data) => ({
    type: types.LOADED_TRAFFIC_SCHEDULE_STATUSES,
    payload: data
});

export const createTrafficSchedule = (data, callback) => ({
    type: types.CREATE_TRAFFIC_SCHEDULE,
    payload: data,
    callback
});

export const copyTrafficSchedule = (id, data, callback) => ({
    type: types.COPY_TRAFFIC_SCHEDULE,
    payload: { id, data },
    callback
});

export const loadTrafficScheduleTypes = () => ({
    type: types.LOAD_TRAFFIC_SCHEDULE_TYPES,
});

export const loadedTrafficScheduleTypes = (data) => ({
    type: types.LOADED_TRAFFIC_SCHEDULE_TYPES,
    payload: data
});

export const loadCurrentTrafficScheduleEvents = (id) => ({
    type: types.LOAD_CURRENT_TRAFFIC_SCHEDULE,
    payload: id
});

export const loadingCurrentTrafficScheduleEvents = (bool = false) => ({
    type: types.LOADING_CURRENT_TRAFFIC_SCHEDULE,
    payload: bool
});

export const loadedCurrentTrafficScheduleEvents = (data) => ({
    type: types.LOADED_CURRENT_TRAFFIC_SCHEDULE,
    payload: data
});

// обращения
export const loadAppeal = (page = 1, limit = 25, query) => ({
    type: types.LOAD_APPEAL,
    payload: {
        page,
        limit,
        query,
    },
});

export const loadingAppeal = (bool = false) => ({
    type: types.LOADING_APPEAL,
    payload: bool
});

export const loadedAppeal = (data) => ({
    type: types.LOADED_APPEAL,
    payload: data,
});

export const createAppeal = (params, callback) => ({
    type: types.CREATE_APPEAL,
    payload: params,
    callback
});

export const editAppeal = (id, data) => ({
    type: types.EDIT_APPEAL,
    payload: { id, data }
});

export const deleteAppeal = (id) => ({
    type: types.DELETE_APPEAL,
    payload: id,
});

export const loadedAppealType = (data) => ({
    type: types.LOADED_APPEAL_TYPE,
    payload: data,
});

export const loadAppealType = () => ({
    type: types.LOAD_APPEAL_TYPE,
});

export const loadedAppealStatus = (data) => ({
    type: types.LOADED_APPEAL_STATUS,
    payload: data,
});

export const loadAppealStatus = () => ({
    type: types.LOAD_APPEAL_STATUS,
});

// контрольные точки
export const loadRouteCheckpoints = (id) =>  ({
    type: types.LOAD_ROUTE_CHECKPOINTS,
    payload: id,
});

export const loadingRouteCheckpoints = (bool = false) =>  ({
    type: types.LOADING_ROUTE_CHECKPOINTS,
    payload: bool,
});

export const loadedRouteCheckpoints = (id, data) => ({
    type: types.LOADED_ROUTE_CHECKPOINTS,
    payload: { id, data },
});

export const saveCheckPointsToRoute = (id, checkpoints, callback) => ({
    type: types.SAVE_CHECKPOINTS_TO_ROUTE,
    payload: { id , checkpoints },
    callback
});

export const loadNearStation = (params) => ({
    type: types.LOAD_NEAR_STATION,
    payload: params
});

export const loadedNearStation = (data) => ({
    type: types.LOADED_NEAR_STATION,
    payload: data,
});

export const loadRouteSelectList = (data) => ({
    type: types.ROUTE_SELECT_LIST,
    payload: data
});

// маршруты с контрольными точками
export const loadRouteWithCheckPoints = (params = {}, callback = () => {}) => ({
    type: types.LOAD_ROUTE_WITH_CHECK_POINTS,
    payload: params,
    callback
});

export const loadedRouteWithCheckPoints = (data = []) => ({
    type: types.LOADED_ROUTE_WITH_CHECK_POINTS,
    payload: data,
});
export const clearRouteWithCheckPoints = () => ({
    type: types.CLEAR_ROUTE_WITH_CHECK_POINTS,
});

export const loadingRouteWithCheckPoints = (bool = false) => ({
    type: types.LOADING_ROUTE_WITH_CHECK_POINTS,
    payload: bool,
});

export const changeTrafficScheduleStatus = (id, data, callback) => ({
    type: types.CHANGE_TRAFFIC_SCHEDULE_STATUS,
    payload: { id, data },
    callback
});

//ROUTE_REQUESTS

export const loadRouteRequestsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_ROUTE_REQUEST_LIST,
    payload: {
        page,
        limit,
        query
    }
});

export const loadingRouteRequestsList = (bool = false) => ({
    type: types.LOADING_ROUTE_REQUESTS_LIST,
    payload: bool
});

export const loadedRouteRequestsList = (data) => ({
    type: types.LOADED_ROUTE_REQUESTS_LIST,
    payload: data
});

export const loadStatusesRouteRequests = () => ({
    type: types.LOAD_STATUSES_ROUTE_REQUESTS
});

export const loadedStatusesRouteRequests = (data) => ({
    type: types.LOADED_STATUSES_ROUTE_REQUESTS,
    payload: data
});

//CAPTCHA

export const loadCaptcha = () => ({
    type: types.LOAD_CAPTCHA
});

export const loadedCaptcha = (data) => ({
    type: types.LOADED_CAPTCHA,
    payload: data
});

export const createRouteRequest = (data, callback) => ({
    type: types.CREATE_ROUTE_REQUEST,
    payload: data,
    callback
});

export const editRouteRequest = (id, data) => ({
    type: types.EDIT_ROUTE_REQUEST,
    payload: { id, data }
});

export const deleteRouteRequest = (id) => ({
    type: types.DELETE_ROUTE_REQUEST,
    payload: id
});
// check-points for schedule-template
export const loadScheduleTemplateCheckpoints = (id) => ({
    type: types.LOAD_SCHEDULETEMPLATE_CHECKPOINTS,
    payload: id,
});

export const loadingScheduleTemplateCheckpoints = (bool = false) => ({
    type: types.LOADING_SCHEDULETEMPLATE_CHECKPOINTS,
    payload: bool
});

export const fetchSchedule = (page = 1, limit = 25, data) => ({
    type: types.FETCH_SCHEDULES,
    payload: {
        page,
        limit,
        data
    }
});

export const fetchingSchedule = (bool = false) => ({
    type: types.FETCHING_SCHEDULES,
    payload: bool
});

export const fetchedSchedule = (data) => ({
    type: types.FETCHED_SCHEDULES,
    payload: data
});

export const postSchedule = (data, callback = () => {}) => ({
    type: types.POST_SCHEDULE,
    payload: data,
    callback
});

export const putSchedule = (id, data, callback = () => {}) => ({
    type: types.PUT_SCHEDULE,
    payload: {
        id,
        data
    },
    callback
});

export const delSchedule = (id, callback = () => {}) => ({
    type: types.DEL_SCHEDULE,
    payload: id,
    callback
});

export const fetchScheduleStatuses = () => ({
    type: types.FETCH_SCHEDULE_STATUSES,
});

export const fetchedScheduleStatuses = (data) => ({
    type: types.FETCHED_SCHEDULE_STATUSES,
    payload: data,
});

export const fetchScheduleEvents = (data) => ({
    type: types.FETCH_SCHEDULE_EVENTS,
    payload: data
});

export const fetchingScheduleEvents = (bool = false) => ({
    type: types.FETCHING_SCHEDULE_EVENTS,
    payload: bool
});

export const fetchedScheduleEvents = (data) => ({
    type: types.FETCHED_SCHEDULE_EVENTS,
    payload: data
});

export const changeScheduleStatus = (id, statusId, callback = () => {}) => ({
    type: types.PUT_SCHEDULE_STATUS,
    payload: {
        id,
        statusId
    },
    callback
});

export const loadedScheduleTemplateCheckpoints = (id, data) => ({
    type: types.LOADED_SCHEDULETEMPLATE_CHECKPOINTS,
    payload: { id, data },
});

export const saveCheckPointsToScheduleTemplate = (template, checkpoints, callback, reloadTemplates) => ({
    type: types.SAVE_CHECKPOINTS_TO_SCHEDULETEMPLATE,
    payload: { template , checkpoints },
    callback,
    reloadTemplates
});
//ROUTE_TRANSPORTATION_TARIFF
export const saveTransportationTariff = (id, data) => {
    const newTariffs = Object.keys(data).reduce((res, key) => {
        res[key] = parseFloat(data[key]);
        return res;
    }, {});

    return ({
        type: types.SAVE_TRANSPORTATION_TARIFF,
        payload: { id, data: newTariffs }
    });
};
// ticket points
export const loadTicketPoints = (page = 1, limit = 25, data) => ({
    type: types.LOAD_TICKET_POINTS,
    payload: { page, limit, data }
});

export const loadedTicketPoints = (data) => ({
    type: types.LOADED_TICKET_POINTS,
    payload: data
});

export const loadingTicketPoints = (bool = false) => ({
    type: types.LOADING_TICKET_POINTS,
    payload: bool
});

export const createTicketPoint = (data, callback = () => {}) => ({
    type: types.CREATE_TICKET_POINT,
    payload: data,
    callback
});

export const editTicketPoint = (id, data, callback = () => {}) => ({
    type: types.EDIT_TICKET_POINT,
    payload: { id, data },
    callback
});

export const deleteTicketPoint = (id, callback = () => {}) => ({
    type: types.DELETE_TICKET_POINT,
    payload: id,
    callback
});

export const loadTicketPointTypes = () => ({
    type: types.LOAD_TICKET_POINT_TYPES
});

export const loadedTicketPointTypes = (data) => ({
    type: types.LOADED_TICKET_POINT_TYPES,
    payload: data
});

export const saveCurrentRoute = (route) => ({
    type: types.SAVE_CURRENT_ROUTE,
    payload: route
});

export const clearCurrentRoute = () => ({
    type: types.CLEAR_CURRENT_ROUTE
});

//WAYBILLS
export const loadWaybillsList = (page = 1, limit = 25, query) => ({
    type: types.LOAD_WAYBILLS_LIST,
    payload: {
        page,
        limit,
        query
    }
});

export const loadingWaybillsList = (bool = false) => ({
    type: types.LOADING_WAYBILLS_LIST,
    payload: bool
});

export const loadedWaybillsList = (data) => ({
    type: types.LOADED_WAYBILLS_LIST,
    payload: data
});

export const createWaybills = (data) => ({
    type: types.CREATE_WAYBILLS,
    payload: data
});

export const editWaybills = (id, data) => ({
    type: types.EDIT_WAYBILLS,
    payload: { id, data }
});

export const deleteWaybills = (id) => ({
    type: types.DELETE_WAYBILLS,
    payload: id
});

export const getWaybills = (id) => ({
    type: types.GET_WAYBILLS,
    payload: id
});

export const loadStationAttributes = () => ({
    type: types.LOAD_STATION_ATTRIBUTES,
});

export const loadedStationAttributes = (data) => ({
    type: types.LOADED_STATION_ATTRIBUTES,
    payload: data
});

//TARIFFS
export const loadTariffsList = (
    page = 1,
    limit = 25,
    query = {},
    type = { type_transportation: 1 }
) => ({
    type: types.LOAD_TARIFFS_LIST,
    payload: { page, limit, query, type }
});

export const loadingTariffsList = (bool = false) => ({
    type: types.LOADING_TARIFFS_LIST,
    payload: bool
});

export const loadedTariffsList = (data, type) => ({
    type: types.LOADED_TARIFFS_LIST,
    payload: { data, type }
});

export const createTariff = (data, type) => ({
    type: types.CREATE_TARIFF,
    payload: { data, type }
});

export const editTariff = (id, data, type) => ({
    type: types.EDIT_TARIFF,
    payload: { id, data, type }
});

export const deleteTariff = (id, type) => ({
    type: types.DELETE_TARIFF,
    payload: { id, type }
});

export const loadVehicleCard = (id, data, callback) => ({
    type: types.LOAD_VEHICLE_CARD,
    payload: {
        id,
        data,
    },
    callback
});

export const loadingVehicleCard = (bool = false) => ({
    type: types.LOADING_VEHICLE_CARD,
    payload: bool,
});

export const loadedVehicleCard = (data) => ({
    type: types.LOADED_VEHICLE_CARD,
    payload: data,
});

export const clearVehicleCard = () => ({
    type: types.CLEAR_VEHICLE_CARD,
});

export const loadWaybillsLicence = () => ({
    type: types.LOAD_WAYBILLS_LICENCE
});

export const loadedWaybillsLicence = (data) => ({
    type: types.LOADED_WAYBILLS_LICENCE,
    payload: data
});

export const loadTrafficScheduleListOfEvents = () => ({
    type: types.LOAD_TRAFFIC_SCHEDULE_LIST_OF_EVENTS,
});

export const loadedTrafficScheduleListOfEvents = (data) => ({
    type: types.LOADED_TRAFFIC_SCHEDULE_LIST_OF_EVENTS,
    payload: data
});

export const editTrafficSchedule = (id, data, callback) => ({
    type: types.EDIT_TRAFFIC_SCHEDULE,
    payload: { id, data },
    callback
});

/* get coords*/
export const loadCoords = (params = {}, callback) => ({
    type: types.LOAD_COORDS,
    payload: params,
    callback
});
export const loadedCoords = (data) => ({
    type: types.LOADED_COORDS,
    payload: data
});
export const clearCoords = () => ({
    type: types.CLEAR_COORDS
});
export const loadingCoords = (bool = false) => ({
    type: types.LOADING_COORDS,
    payload: bool
});


export const loadVehicle = (egtsYandex) => ({
    type: types.LOAD_VEHICLE,
    payload: egtsYandex,
});
export const loadedVehicle = (key, data) => ({
    type: types.LOADED_VEHICLE,
    payload: {
        key,
        data,
    }
});
export const clearVehicle = (key) => ({
    type: types.CLEAR_VEHICLE,
    payload: key,
});
export const clearVehicleAll = () => ({
    type: types.CLEAR_VEHICLE,
});
export const loadingVehicle = (key, bool = false) => ({
    type: types.LOADING_VEHICLE,
    payload: {
        key,
        bool,
    }
});

export const setMarkers = (data, useFilter = true) => ({
    type: types.SET_MARKERS,
    payload: {
        data,
        useFilter,
    },
});

// export const setCurrentMarkers = (data = {}) => ({
//     type: types.SET_CURRENT_MARKER,
//     payload: data,
// });
// export const refreshCurrentMarkers = (events) => ({
//     type: types.REFRESH_CURRENT_MARKER,
//     payload: events,
// });

export const setMarkersExtend = (data) => ({
    type: types.SET_MARKERS_EXTEND,
    payload: data,
});

export const clearMarkersExtend = () => ({
    type: types.CLEAR_MARKERS_EXTEND,
});

export const loadReportSummarySchedule = (page = 1, limit = 25, data) => ({
    type: types.GET_REPORT_SUMMARY_SCHEDULE,
    payload: { page, limit, data }
});

export const loadedSummarySchedule = (data) => ({
    type: types.LOADED_SUMMARY_SCHEDULE,
    payload: data,
});

export const loadingSummarySchedule = (data) => ({
    type: types.LOADING_SUMMARY_SHEDULE,
    payload: data
});

export const clearSummarySchedule = () => ({
    type: types.CLEAR_SUMMARY_SCHEDULE,
});

export const setSummaryScheduleFilter = (data) => ({
    type: types.SET_SUMMARY_SCHEDULE_FILTER,
    payload: data,
});

export const clearSummaryScheduleFilter = () => ({
    type: types.CLEAR_SUMMARY_SCHEDULE_FILTER,
});

// инфо по организации
export const loadOrganizationInformation = (organization_id, report, formats = []) => ({
    type: types.LOAD_ORGANIZATION_INFORMATION,
    payload: {
        organization_id,
        report,
        formats
    },
});

export const loadedOrganizationInformation = (data) => ({
    type: types.LOADED_ORGANIZATION_INFORMATION,
    payload: data,
});

export const loadingOrganizationInformation = (bool = false) => ({
    type: types.LOADING_ORGANIZATION_INFORMATION,
    payload: bool,
});

export const loadVehicleTelemetryStatistic = () => ({
    type: types.LOAD_VEHICLE_TELEMETRY_STATISTIC,
});

export const loadTelemetryStatistic = () => ({
    type: types.LOAD_TELEMETRY_STATISTIC,
});

export const loadedVehicleTelemetryStatistic = (data) => ({
    type: types.LOADED_VEHICLE_TELEMETRY_STATISTIC,
    payload: data
});

export const loadedTelemetryStatistic = (data) => ({
    type: types.LOADED_TELEMETRY_STATISTIC,
    payload: data
});

export const loadWorkorderReport = (page = 1, limit = 25, data) => ({
    type: types.LOAD_WORKORDERS_REPORT,
    payload: { page, limit, data }
});

export const loadingWorkorderReport = (bool = false) => ({
    type: types.LOADING_WORKORDERS_REPORT,
    payload: bool,
});

export const loadedWorkorderReport = (data) => ({
    type: types.LOADED_WORKORDERS_REPORT,
    payload: data
});

export const setWorkorderReportFilter = (data) => ({
    type: types.SET_WORKORDERS_REPORT_FILTER,
    payload: data
});

export const clearWorkorderReportFilter = () => ({
    type: types.CLEAR_WORKORDERS_REPORT_FILTER,
});

/**/
export const loadWoRequisiteChartererTypes = () => ({
    type: types.LOAD_WO_REQUISITE_CHARTERER_TYPES,
});
export const loadedWoRequisiteChartererTypes = (data) => ({
    type: types.LOADED_WO_REQUISITE_CHARTERER_TYPES,
    payload: data
});
export const loadWoRequisiteList = (page= 1, limit= 10, params = {}) => ({
    type: types.LOAD_WO_REQUISITE_LIST,
    payload: {
        page,
        limit,
        ...params,
    },
});
export const loadedWoRequisiteList = (data = { data: [], meta: {} }) => ({
    type: types.LOADED_WO_REQUISITE_LIST,
    payload: data,
});
export const loadingWoRequisite = (bool = false) => ({
    type: types.LOADING_WO_REQUISITE,
    payload: bool,
});
export const createWoRequisite = (data, callback) => ({
    type: types.CREATE_WO_REQUISITE,
    payload: data,
    callback,
});
export const editWoRequisiteList = (id, data, callback) => ({
    type: types.EDIT_WO_REQUISITE,
    payload: {
        id,
        data,
    },
    callback,
});
export const deleteWoRequisiteList = (id, callback) => ({
    type: types.DELETE_WO_REQUISITE,
    payload: id,
    callback,
});

export const loadRadioTypeList = (page, limit, data = {}) => ({
    type: types.LOAD_RADIO_TYPE,
    payload: {
        page,
        limit,
        ...data,
    }
});

export const loadedRadioTypeList = (data = {}) => ({
    type: types.LOADED_RADIO_TYPE,
    payload: data
});

export const loadingRadioTypeList = (bool = false) => ({
    type: types.LOADING_RADIO_TYPE,
    payload: bool
});

export const loadWorkorderEventManualStatus = () => ({
    type: types.LOAD_WORKORDER_EVENT_MANUAL_STATUSES
});

// ручные статусы для событий в нарядах
export const loadedWorkorderEventManualStatus = (data) => ({
    type: types.LOADED_WORKORDER_EVENT_MANUAL_STATUSES,
    payload: data
});
// ручные статусы для событий рейса в нарядах
export const loadWorkorderEventFlightManualStatus = () => ({
    type: types.LOAD_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES
});

export const loadedWorkorderEventFlightManualStatus = (data) => ({
    type: types.LOADED_WORKORDER_EVENT_FLIGHT_MANUAL_STATUSES,
    payload: data
});

export const loadFlightDefectGroup = () => ({
    type: types.LOAD_FLIGHT_DEFECT_GROUP,
});

export const loadedFlightDefectGroup = (data) => ({
    type: types.LOADED_FLIGHT_DEFECT_GROUP,
    payload: data
});

export const loadFlightDefects = (params = {}) => ({
    type: types.LOAD_FLIGHT_DEFECTS,
    payload: params
});

export const loadedFlightDefects = (data = {}) => ({
    type: types.LOADED_FLIGHT_DEFECTS,
    payload: data
});


export const createWorkorderEventStatus = (id, data = {}) => ({
    type: types.CREATE_WORKORDER_EVENT_STATUS,
    payload: { id, data }
});

export const editWorkorderEventStatus = (id, data = {}) => ({
    type: types.EDIT_WORKORDER_EVENT_STATUS,
    payload:  { id, data }
});

export const deleteWorkorderEventStatus = (id) => ({
    type: types.DELETE_WORKORDER_EVENT_STATUS,
    payload:  { id }
});

//
export const loadReportByFlights = (params) => ({
    type: types.LOAD_REPORT_BY_FLIGHTS,
    payload: params,
});

export const loadedReportByFlights = (data) => ({
    type: types.LOADED_REPORT_BY_FLIGHTS,
    payload: data,
});

export const loadingReportByFlights = (bool = false) => ({
    type: types.LOADING_REPORT_BY_FLIGHTS,
    payload: bool,
});

export const setReportByFlightsFilter = (data) => ({
    type: types.SET_REPORT_BY_FLIGHTS_FILTER,
    payload: data
});

export const clearReportByFlights = () => ({
    type: types.CLEAR_REPORT_BY_FLIGHTS,
});

export const setHistoryOption = (title = '', option = [], setBounds = true, zoom, maxZoom) => ({
    type: types.SET_HISTORY_OPTION,
    payload: option.length > 0
        ? {
            title,
            option,
            setBounds,
            zoom,
            maxZoom
        }
        : {},
});

export const loadReportByVehicles = (page = 1, limit = 25, params) => ({
    type: types.LOAD_REPORT_BY_VEHICLES,
    payload: {
        page,
        limit,
        ...params
    },
});

export const loadedReportByVehicles = (data) => ({
    type: types.LOADED_REPORT_BY_VEHICLES,
    payload:  data,
});

export const loadingReportByVehicles = (bool = false) => ({
    type: types.LOADING_REPORT_BY_VEHICLES,
    payload:  bool,
});

export const setReportByVehiclesFilter = (data) => ({
    type: types.SET_REPORT_BY_VEHICLES_FILTER,
    payload:  data,
});

export const clearReportByVehiclesFilter = () => ({
    type: types.CLEAR_REPORT_BY_VEHICLES_FILTER,
});

export const loadEmployeesTransportationReport = (params) => ({
    type: types.LOAD_EMPLOYEES_TRANSPORTATION_REPORT,
    payload: params,
});

export const loadedEmploadEmployeesTransportationReport = (data) => ({
    type: types.LOADED_EMPLOYEES_TRANSPORTATION_REPORT,
    payload: data,
});

export const loadingEmploadEmployeesTransportationReport = (bool = false) => ({
    type: types.LOADING_EMPLOYEES_TRANSPORTATION_REPORT,
    payload: bool,
});

export const setEmployeesTransportationReportFilter = (data) => ({
    type: types.SET_EMPLOYEES_TRANSPORTATION_REPORT_FILTER,
    payload: data,
});

export const clearEmployeesTransportationReportFilter = () => ({
    type: types.CLEAR_EMPLOYEES_TRANSPORTATION_REPORT_FILTER,
});

// типы рейсов
export const loadFlightTypes = () => ({
    type: types.LOAD_FLIGHT_TYPES
});

export const loadedFlightTypes = (data) => ({
    type: types.LOADED_FLIGHT_TYPES,
    payload: data
});

export const editFlightType = (data, params) => ({
    type: types.EDIT_FLIGHT_TYPE,
    payload: { data, params }
});


export const copyScheduleTemplate = (template, data, callback = () => {}) => ({
    type: types.COPY_SCHEDULE_TEMPLATE,
    payload: { template, data },
    callback
});

export const loadSpeedViolationsReport = (page, limit, params) => ({
    type: types.LOAD_SPEED_VIOLATIONS_REPORT,
    payload: {
        page,
        limit,
        ...params
    },
});

export const loadedSpeedViolationsReport = (data) => ({
    type: types.LOADED_SPEED_VIOLATIONS_REPORT,
    payload:  data,
});

export const loadingSpeedViolationsReport = (bool = false) => ({
    type: types.LOADING_SPEED_VIOLATIONS_REPORT,
    payload:  bool,
});

export const setSpeedViolationsReportFilter = (data) => ({
    type: types.SET_SPEED_VIOLATIONS_REPORT_FILTER,
    payload: data,
});

export const clearSpeedViolationsReportFilter = () => ({
    type: types.CLEAR_SPEED_VIOLATIONS_REPORT_FILTER,
});

//TRANSPORT_SETTINGS
export const loadTransportSettings = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_TRANSPORT_SETTINGS,
    payload: { page, limit, query }
});

export const loadingTransportSettings = (bool = false) => ({
    type: types.LOADING_TRANSPORT_SETTINGS,
    payload: bool
});

export const loadedTransportSettings = (data) => ({
    type: types.LOADED_TRANSPORT_SETTINGS,
    payload: data
});

export const editTransportSetting = (id, data, callback) => ({
    type: types.EDIT_TRANSPORT_SETTING,
    payload: { id, data },
    callback
});

export const editSettingsList = (data, callback) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

//REPORT_PASSENGERS
export const loadPassengersCount = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_PASSENGERS_COUNT,
    payload: { page, limit, query }
});

export const loadingPassengersCount = (bool = false) => ({
    type: types.LOADING_PASSENGERS_COUNT,
    payload: bool
});

export const loadedPassengersCount = (data) => ({
    type: types.LOADED_PASSENGERS_COUNT,
    payload: data
});

export const setPassengersCountFilter = (data) => ({
    type: types.SET_PASSENGERS_COUNT_FILTER,
    payload: data
});

export const clearPassengersCountFilter = () => ({
    type: types.CLEAR_PASSENGERS_COUNT_FILTER,
});


export const loadSidebar = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: { page, limit, ...params }
});

export const loadedSidebar = (data, replace) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        replace,
    }
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool
});


// export const loadRouteByNum = (num_list, callback = () => {}) => ({
//     type: types.LOAD_ROUTE_BY_NUM,
//     payload: { num_list },
//     callback
// });

export const setSidebarParams = (params) => ({
    type: types.SET_SIDEBAR_PARAMS,
    payload: params
});

export const seWsRoute = (events) => ({
    type: types.SET_ROUTE_WS,
    payload: events
});

export const getTemplate = (format) => ({
    type: types.GET_TEMPLATE,
    payload: { format }
});

export const loadingTemplate = (bool) => ({
    type: types.LOADING_TEMPLATE,
    payload: bool
});

export const loadTransorganizations = (page = 1, limit = 25, params = {}) => ({
    type: types.LOAD_TRANSORGANIZATIONS,
    payload: { page, limit, params }
});

export const loadingTransorganizations = (bool) => ({
    type: types.LOADING_TRANSORGANIZATIONS,
    payload: bool
});

export const loadedTransorganizations = (data) => ({
    type: types.LOADED_TRANSORGANIZATIONS,
    payload: data
});

export const editTransorganization = (id, data, callback = () => {}) => ({
    type: types.EDIT_TRANSORGANIZATION,
    payload: { id, data },
    callback
});


/* color speed page */
export const loadColorSpeed = () => ({
    type: types.LOAD_COLOR_SPEED,
});
export const loadedColorSpeed = (data) => ({
    type: types.LOADED_COLOR_SPEED,
    payload: data
});

export const loadColorSpeedList = (props) => ({
    type: types.LOAD_COLOR_SPEED_LIST,
    payload: props
});
export const loadedColorSpeedList = (data) => ({
    type: types.LOADED_COLOR_SPEED_LIST,
    payload: data
});
export const loadingColorSpeedList = (bool = false) => ({
    type: types.LOADING_COLOR_SPEED_LIST,
    payload: bool
});
export const setColorSpeedListProps = (props) => ({
    type: types.SET_COLOR_SPEED_LIST_PROPS,
    payload: props
});
export const resetColorSpeedListProps = (props) => ({
    type: types.RESET_COLOR_SPEED_LIST_PROPS,
});
export const addColorSpeed = (data, callback) => ({
    type: types.ADD_COLOR_SPEED,
    payload: data,
    callback,
});
export const editColorSpeed = (id, data, callback) => ({
    type: types.EDIT_COLOR_SPEED,
    payload: {
        id,
        data,
    },
    callback,
});
export const deleteColorSpeed = (id, callback) => ({
    type: types.DELETE_COLOR_SPEED,
    payload: id,
    callback,
});

export const loadRoutesGrouped = (page = 1, limit = 25, data) => ({
    type: types.LOAD_ROUTES_GROUPED,
    payload: { page, limit, data }
});

export const loadingRoutesGrouped = (bool = false) => ({
    type: types.LOADING_ROUTES_GROUPED,
    payload: bool
});

export const loadedRoutesGrouped = (data) => ({
    type: types.LOADED_ROUTES_GROUPED,
    payload: data
});

export const loadMilestoneEvents = () => ({
    type: types.LOAD_MILESTONE_EVENTS
});

export const loadedMilestoneEvents = (data) => ({
    type: types.LOADED_MILESTONE_EVENTS,
    payload: data
});

export const loadingButton = (bool = false) => ({
    type: types.LOADING_BUTTON,
    payload: bool,
});

// остановки на карте
export const loadStationPolygon = (params) => ({
    type: types.LOAD_STATION_POLYGON,
    payload: params,
});
export const loadedStationPolygon = (data) => ({
    type: types.LOADED_STATION_POLYGON,
    payload: data,
});
export const clearStationPolygon = () => ({
    type: types.LOADED_STATION_POLYGON,
    payload: [],
});
export const loadingStationPolygon = (bool = false) => ({
    type: types.LOADING_STATION_POLYGON,
    payload: bool,
});

// расписание на дату
export const loadStationScheduleByDate = (id, date, props = {}) => ({
    type: types.LOAD_STATION_SCHEDULE_BY_DATE,
    payload: {
        id,
        date,
        ...props,
    },
});

export const loadedStationScheduleByDate = (data) => ({
    type: types.LOADED_STATION_SCHEDULE_BY_DATE,
    payload: data,
});

export const clearStationScheduleByDate = () => ({
    type: types.LOADED_STATION_SCHEDULE_BY_DATE,
    payload: []
});

export const loadingStationScheduleByDate = (bool = false) => ({
    type: types.LOADING_STATION_SCHEDULE_BY_DATE,
    payload: bool,
});

export const loadTelemetryReceivers = (data) => ({
    type: types.LOAD_TELEMETRY_RECEIVERS,
    payload: data
});

export const loadedTelemetryReceivers = (data) => ({
    type: types.LOADED_TELEMETRY_RECEIVERS,
    payload: data
});

export const loadingTelemetryReceivers = (bool) => ({
    type: types.LOADING_TELEMETRY_RECEIVERS,
    payload: bool
});

export const addTelemetryReceiver = (data, callback) => ({
    type: types.ADD_TELEMETRY_RECEIVER,
    payload: data,
    callback,
});

export const editTelemetryReceiver = (id, data, callback) => ({
    type: types.EDIT_TELEMETRY_RECEIVER,
    payload: {
        id,
        data,
    },
    callback,
});

export const deleteTelemetryReceiver = (id, callback) => ({
    type: types.DELETE_TELEMETRY_RECEIVER,
    payload: id,
    callback,
});

export const loadTelemetryReceiverTypes = () => ({
    type: types.LOAD_TELEMETRY_RECEIVER_TYPES,
});

export const loadedTelemetryReceiverTypes = (data) => ({
    type: types.LOADED_TELEMETRY_RECEIVER_TYPES,
    payload: data
});

export const changeRouteStatus = (routeId, statusId, callback) => ({
    type: types.CHANGE_ROUTE_STATUS,
    payload: {
        routeId,
        statusId,
    },
    callback,
});
export const loadingSetRouteStatus = (bool = false) => ({
    type: types.LOADING_CHANGE_ROUTE_STATUS,
    payload: bool,
});

//Производственный календарь
export const loadWorkCalendar = (params) => ({
    type: types.LOAD_WORK_CALENDAR,
    payload: params
});

export const loadedWorkCalendar = (data) => ({
    type: types.LOADED_WORK_CALENDAR,
    payload: data
});

export const loadingWorkCalendar = (bool) => ({
    type: types.LOADING_WORK_CALENDAR,
    payload: bool
});

export const editWorkCalendar = (data = {}, callback = () => {}) => ({
    type: types.EDIT_WORK_CALENDAR,
    payload: data,
    callback,
});

export const loadWorkCalendarDayTypes = (params) => ({
    type: types.LOAD_WORK_CALENDAR_DAY_TYPES,
    payload: params
});

export const loadedWorkCalendarDayTypes = (data) => ({
    type: types.LOADED_WORK_CALENDAR_DAY_TYPES,
    payload: data
});

export const loadingWorkCalendarDayTypes = (bool) => ({
    type: types.LOADING_WORK_CALENDAR_DAY_TYPES,
    payload: bool
});

export const loadWorkCalendarHistory = (params) => ({
    type: types.LOAD_WORK_CALENDAR_HISTORY,
    payload: params
});

export const loadedWorkCalendarHistory = (data) => ({
    type: types.LOADED_WORK_CALENDAR_HISTORY,
    payload: data
});

export const loadingWorkCalendarHistory = (bool) => ({
    type: types.LOADING_WORK_CALENDAR_HISTORY,
    payload: bool
});

export const deleteRoute = (id, callback = () => {}) => ({
    type: types.DELETE_ROUTE,
    payload: id,
    callback
});

// Класс ТС
export const loadVehicleClasses = (params) => ({
    type: types.LOAD_VEHICLE_CLASSES,
    payload: params
});

export const loadingVehicleClasses = (bool) => ({
    type: types.LOADING_VEHICLE_CLASSES,
    payload: bool
});

export const loadedVehicleClasses = (data) => ({
    type: types.LOADED_VEHICLE_CLASSES,
    payload: data
});

export const createVehicleClass = (data, callback = () => {}) => ({
    type: types.CREATE_VEHICLE_CLASS,
    payload: data,
    callback,
});

export const editVehicleClass = (id, data, callback = () => {}) => ({
    type: types.EDIT_VEHICLE_CLASS,
    payload: { id, data },
    callback,
});

export const deleteVehicleClass = (id, callback = () => {}) => ({
    type: types.EDIT_VEHICLE_CLASS,
    payload: id,
    callback,
});

export const loadRegularTransportations = () => ({
    type: types.LOAD_REGULAR_TRANSPORTATIONS,
});

export const loadedRegularTransportations = (params) => ({
    type: types.LOADED_REGULAR_TRANSPORTATIONS,
    payload: params
});

export const loadSeasonality = () => ({
    type: types.LOAD_SEASONALITY,
});

export const loadedSeasonality = (params) => ({
    type: types.LOADED_SEASONALITY,
    payload: params
});

export const loadRouteSortDirections = () => ({
    type: types.LOAD_ROUTE_SORT_DIRECTIONS
});

export const loadedRouteSortDirections = (data) => ({
    type: types.LOADED_ROUTE_SORT_DIRECTIONS,
    payload: data
});

export const editRouteAdditionalParams = (id, data) => ({
    type: types.EDIT_ROUTE_ADDITIONAL_PARAMS,
    payload: { id, data }
});

export const loadingRouteAdditionalParams = (bool) => ({
    type: types.LOADING_ROUTE_ADDITIONAL_PARAMS,
    payload: bool
});
// История изменения маршрута
export const loadRouteHistoryHuman = (id, params) => ({
    type: types.LOAD_ROUTE_HISTORY_HUMAN,
    payload: { id, params }
});

export const loadingRouteHistoryHuman = (bool) => ({
    type: types.LOADING_ROUTE_HISTORY_HUMAN,
    payload: bool
});

export const loadedRouteHistoryHuman = (data) => ({
    type: types.LOADED_ROUTE_HISTORY_HUMAN,
    payload: data
});

export const addGroupToLoadingGroups = (group = {}) => ({
    type: types.ADD_GROUP_TO_LOADING_GROUPS,
    payload: group
});

export const removeGroupFromLoadingGroups = (group = {}) => ({
    type: types.REMOVE_GROUP_FROM_LOADING_GROUPS,
    payload: group
});

export const updateRoute = (data = {}) => ({
    type: types.UPDATE_ROUTE,
    payload: data
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});

export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});

export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});

export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const loadRoutesByNCCallback = (num, category_id, fnLoading, fnCallback) => ({
    type: types.LOAD_ROUTES_BY_NC_CALLBACK,
    payload: {
        num,
        category_id
    },
    fnLoading,
    fnCallback,
});

export const setShowRouteByVehicle = (bool = false) => ({
    type: types.SET_SHOW_ROUTE_BY_VEHICLE,
    payload: bool
});

// egts transmitters
export const loadEgtsTransmitters = (filter, fnLoading, fnCallback) => ({
    type: types.LOAD_EGTS_TRANSMITTER,
    payload: filter,
    fnLoading,
    fnCallback,
});

// crud
export const addEgtsTransmitter = (data, fnLoading, fnCallback) => ({
    type: types.ADD_EGTS_TRANSMITTER,
    payload: data,
    fnLoading,
    fnCallback,
});

export const editEgtsTransmitter = (id, data, fnLoading, fnCallback) => ({
    type: types.EDIT_EGTS_TRANSMITTER,
    payload: {
        id,
        data,
    },
    fnLoading,
    fnCallback,
});

export const deleteEgtsTransmitter = (id, fnLoading, fnCallback) => ({
    type: types.DELETE_EGTS_TRANSMITTER,
    payload: id,
    fnLoading,
    fnCallback,
});
