import {  useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import L from 'leaflet';

import * as actions from 'redux/SituationalPlans/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { useWsSubscribe } from 'helpers/ws/hooks';
import mapHelper from 'helpers/mapHelper';
import { useWsActive } from 'helpers/ws/hooks';
import getImagesObject from 'helpers/getImagesObject';
import colorExtend from 'helpers/mapHelper/colorExtend';
import getStatusesObject from 'helpers/getStatusesObject';
import { useDebounce, usePrevious, useStoreFromSelector } from 'helpers/hooks';
import MapLegends from 'components/common/Transport/MapLegends';
import {
    createIcon as createIconTitle,
    MapPopUp,
    MapPopUpListener
} from 'components/MapComponents/MapPopUp';
import getFilters from 'components/MapComponents/helpers/getFilters';
import {
    ContextMenuItem,
    GeoJson,
} from 'components/MapComponents/leaflet';

import { checkBase64 } from '../TransportPassenger/helper';

import Tooltip from './Tooltip';
import CollectorForms from './CollectorForms';
import CPopup from './PopUp';
import LegendsList from './LegendsList';
import { createIconMarker } from './helper';
import config from './config';

const Layer = (props) => {
    const { map, readOnly = false, minPopUp = false, showcaseFilters = {} } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(SituationalPlansSelectors.polygon);
    const active = useSelector(SituationalPlansSelectors.active);
    const saved = useSelector(SituationalPlansSelectors.saved);
    const filters = useSelector(SituationalPlansSelectors.filters);
    // const polygonLoading = useSelector(SituationalPlansSelectors.polygonLoading || false);
    // статусы
    const statuses = useStoreFromSelector(
        actions.loadTransportIncidentStatuses,
        SituationalPlansSelectors.transportIncidentStatuses
    );
    const statusesObject = getStatusesObject(statuses);

    // иконки
    const imagesLegends = useStoreFromSelector(
        actions.loadImagesLegends,
        SituationalPlansSelectors.imagesLegends
    );
    const images = getImagesObject(imagesLegends);

    const getColorByStatus = (status_id) => {
        return colorExtend(statusesObject?.[status_id]?.color || config.mapMarkersColors.default);
    };

    const getImage = ({ type_id = 0 }) => {
        return checkBase64(
            images?.[type_id] // иконка по типу
            || images?.[0] // иконка по умолчанию из данных
            || null
        );
    };

    // создание иконки
    const createIcon = useCallback(({ status_id = 0, type_id = 0 }) => {
        const imageB64 = getImage({ type_id });
        const color = getColorByStatus(status_id);

        return createIconMarker(color, imageB64);
    }, [images, statusesObject]);

    useWsSubscribe('situational-plans_transport_incident_update', (events) => {
        dispatch(actions.setWsTI(events));
    });

    const [geoJson, setGeoJson] = useState([]);
    const prevFilters = usePrevious(filters);

    useWsActive((activeData) => {
        dispatch(actions.setActive({
            ...activeData,
            id: activeData.external_id,
        }));
    });

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();

            fetchPolygon();
            dispatch(actions.setEditForm());
            dispatch(actions.saveStatus(false));
        }
    }, [saved]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        const filter = getFilters(filters);
        dispatch(actions.loadForPolygon(polygon, { ...filter, ...showcaseFilters }));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            geometry: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [lng, lat]
                }
            },
            lat,
            lon: lng,
        }));
    };

    useEffect(() => {
        fetchPolygon();
        dispatch(actions.loadThreatLevels());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.setActive({}));
            dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (
            active?.geometry
            && !active?.isNoMove
        ) {
            const { geometry } = active;

            if (geometry) {
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }
        }
    }, [active]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProp}
                    value="Редактировать"
                    onClick={() => {
                        dispatch(actions.setEditForm(item));
                    }}
                />
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    useEffect(() => {
        const geoJson = polygon.reduce((res, { geometry, ...props }) => [
            ...res,
            {
                ...geometry,
                properties: {
                    ...geometry.properties,
                    data: {
                        ...props,
                        geometry
                    },
                    attribution: {
                        slug: config.slug,
                        color: getColorByStatus(props.status_id)
                    },
                },
                style: {
                    color: getColorByStatus(props.status_id),
                    weight: 7
                }
            }
        ], []);

        setGeoJson(geoJson);
    }, [polygon, statusesObject]);

    return (
        <>
            <GeoJson
                {...props}
                data={geoJson}
                icon={createIcon}
                toolTipTemplate={(props) => <Tooltip {...props} statusesObject={statusesObject}/>}
                {...(!readOnly
                    ? {
                        contextMenuTemplate: (item, data) => <RenderContextMenu item={item} {...data} />
                    }
                    : {}
                )}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, -5],
                    sticky: true,
                }}
                popUpOptions={{
                    minWidth: minPopUp ? '400' : '900'
                }}
                onClosePopup={() => {
                    dispatch(actions.resetActive());
                }}
                onClick={(item) => {
                    const { geometry } = active;
                    if (geometry) {
                        const center = L.geoJSON(geometry)?.getBounds?.().getCenter?.();
                        if (center) map.setView(center);
                    }
                    dispatch(actions.setActive({
                        ...item,
                        isNoMove: true,
                    }));
                }}
                idPrefix={config.slug}
            />

            {/* popup */}
            <MapPopUpListener
                activeSelector={SituationalPlansSelectors.active}
                polygonSelector={SituationalPlansSelectors.polygon}
            >
                <MapPopUp
                    icon={(active) => {
                        const image = getImage(active);
                        return image
                            ? <img className="img margin" src={image}/>
                            : createIconTitle(config.layerIcon);
                    }}
                    title={({ name = '' }) => name || ''}
                    onClose={() => dispatch(actions.setActive({}))}
                    titleBackgroundColor={(item) => colorExtend(statusesObject?.[item?.status_id]?.color || config.mapMarkersColors.default)}
                    medium
                >
                    <CPopup
                        readOnly={readOnly}
                        statusesObject={statusesObject}
                    />
                </MapPopUp>
            </MapPopUpListener>

            <CollectorForms />

            <MapLegends
                layer="transport-incidents"
                isVisible={imagesLegends?.length > 0}
                visibleRequired={readOnly}
            >
                <LegendsList
                    category={imagesLegends}
                    statuses={statuses}
                />
            </MapLegends>
        </>
    );
};

export default Layer;
