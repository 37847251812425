import React from 'react';

import titles from 'helpers/constants/titles';
import Modal from 'components/common/Modal';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

interface LinkModalProps {
    name: string
    isOpen: boolean
    onClose: () => void
    linkObject: string
    onSave: () => void
    onChange: () => void
    children: React.ReactNode
    disabled?: boolean
    topComponent?: React.ReactElement
    changeBtnDisable?: boolean
}

const LinkModal = ({
    name,
    isOpen,
    onClose,
    linkObject,
    onSave,
    onChange,
    disabled = false,
    children,
    changeBtnDisable = false,
    topComponent,
}: LinkModalProps) => {
    return (
        <Modal
            title="Назначение связей объектов"
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            disabled,
                        },
                    ]}
                />
            }
        >
            {name && (
                <div style={{ paddingLeft: '1.2rem' }}>
                    <strong>{titles.NAME}:</strong> {name}
                </div>
            )}

            {topComponent && topComponent}

            <InformPanel
                buttons={
                    <FormButtons
                        noPadding
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.change,
                                color: 'infoButton',
                                onClick: onChange,
                                disabled: changeBtnDisable,
                            },
                        ]}
                    />
                }
            />

            {linkObject && <h2 style={{ padding: '0 0 0 1.2rem' }}>{linkObject}:</h2>}

            {children}
        </Modal>
    );
};

export default LinkModal;
