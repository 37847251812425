import React from 'react';
import { Checkbox, FormControl, FormControlLabel, } from '@mui/material';

import type { Factor } from 'modules/React/Dictionaries/IncidentFactors/types';

interface CheckIncidentFactorsProps {
    selectedItems: number[];
    onChange: (updatedSelectedItems: number[]) => void;
    data: Factor[];
}

const CheckIncidentFactors = ({ selectedItems = [], onChange, data }: CheckIncidentFactorsProps) => {
    const handleCheckboxChange = (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedSelectedItems = event.target.checked
            ? [...selectedItems, id]
            : selectedItems.filter((itemId: number) => itemId !== id);

        onChange(updatedSelectedItems);
    };

    return (
        <>
            <h2>Автоматическое копирование</h2>
            <FormControl>
                {data.map((el) => (
                    <FormControlLabel
                        key={el.id}
                        control={
                            <Checkbox
                                checked={selectedItems.includes(el.id as number)}
                                onChange={handleCheckboxChange(el.id as number)}
                                size="small"
                            />
                        }
                        label={el.name as string}
                    />
                ))}
            </FormControl>
        </>
    );
};

export default CheckIncidentFactors;
