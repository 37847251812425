import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import L from 'leaflet';
import { debounce } from 'lodash';

import * as actions from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import mapHelper from 'helpers/mapHelper';
import colorExtend from 'helpers/mapHelper/colorExtend';
import { usePrevious } from 'helpers/hooks';
import {
    ContextMenuItem,
    GeoJson,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import useLeafletEvents from 'components/MapComponents/leaflet/components/hooks/useLeafletEvents';
import { MapPopUpListener }  from 'components/MapComponents/MapPopUp';

import PopUpModal from './PopUpModal';
import config from './config.js';
import {
    createIconMarker,
    getDefaultColor,
    getFilter,
} from './helper';
import MarkersClusters from './MarkersClusters';
import { isShowJson } from './helper';
import CollectorForms from './CollectorForms';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const defectsActive = useSelector(roadworksSelectors.defectsActive);
    const defectsPolygonLoading = useSelector(roadworksSelectors.defectsPolygonLoading);
    const defectsPolygon = useSelector(roadworksSelectors.defectsPolygon);
    const defectsFilters = useSelector(roadworksSelectors.defectsFilters);
    const defectsSaved = useSelector(roadworksSelectors.defectsSaved);

    // const prevSaved = usePrevious(saved);
    const [geometry, setGeometry] = useState([]);
    const showPopUp = useRef(false);
    const prevPolygonLoading = usePrevious(defectsPolygonLoading);
    const [showJson, setShowJson] = useState(isShowJson(map.getZoom()));

    // кодключение к карте
    useLeafletEvents((e) => {
        // setZoom(e.target.getZoom());
        setShowJson(isShowJson(map.getZoom()));
    }, map, [
        'zoomend'
    ]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        const filter = getFilters(defectsFilters, getFilter);
        dispatch(actions.loadDefectsPolygon(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        if (defectsPolygonLoading === false && prevPolygonLoading === true && showPopUp.current) {
            const id = showPopUp.current;
            setTimeout(() => {
                // console.log('showPopUp.current', showPopUp.current);
                map.fire(`showBy${config.slug}${id}`);
            }, 200);

            showPopUp.current = false;
        }
    }, [defectsPolygonLoading, showPopUp.current]);

    const getColor = (color) => color ? colorExtend(color) : getDefaultColor();

    useEffect(() => {
        const geoJson = defectsPolygon.reduce((res, { geometry, ...props }) => [
            ...res,
            {
                ...geometry,
                properties: {
                    ...geometry.properties,
                    data: {
                        ...props,
                        geometry,
                        color: getColor(props?.type?.color)
                    },
                    attribution: {
                        slug: config.slug,
                        color: getColor(props?.type?.color)
                    },
                },
                style: {
                    color: getColor(props?.type?.color),
                    weight: 7
                }
            }
        ], []);
        setGeometry(geoJson);
    }, [defectsPolygon]);

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditFormDefects({
            geometry: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [
                        lng,
                        lat
                    ],
                },
            },
            lat,
            lon: lng,
        }));
    };

    useEffect(() => {
        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.clearDefectsActive());
            dispatch(actions.clearDefectsPolygon());
            map.closeContextMenu();
            // map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [defectsFilters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
            setGeometry([]);
        };
    }, []);

    useEffect(() => {
        if (defectsSaved) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [defectsSaved]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (
            Object.keys(defectsActive).length > 0
            && !defectsActive?.isNoMove
        ) {
            const { geometry, id } = defectsActive;

            if (defectsActive) {
                showPopUp.current = defectsActive.id;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);

                // map.fire(`showBy${config.slug}${id}`);
            }
        }
    }, [defectsActive]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                {item.status !== 3
                    ? (
                        <ContextMenuItem
                            {...rcmProp}
                            value="Редактировать"
                            onClick={() => {
                                //map.fire('context_menu_close');
                                dispatch(actions.setEditFormDefects(item));
                            }}
                        />
                    )
                    : null
                }
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setDeleteFormDefects(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    return (
        <>
            {showJson
                ? (
                    <>
                        {geometry.length && (
                            <GeoJson
                                {...props}
                                data={geometry}
                                icon={({ color }) => createIconMarker(color)}
                                contextMenuTemplate={(item, data) => <RenderContextMenu item={item} {...data} />}
                                toolTipTemplate={({ name }) => <div>{name}</div>}
                                toolTipOptions={{
                                    direction: 'top',
                                    offset: [0, 0],
                                    sticky: true,
                                }}
                                onClick={(item) => {
                                    map.setView([item.lat, item.lon]);
                                    dispatch(actions.setDefectsActive({ ...item, isNoMove: true }));
                                }}
                            />
                        )}
                    </>
                )
                : (
                    <MarkersClusters
                        {...props}
                        polygon={defectsPolygon}
                    />
                )
            }

            {/* popup */}
            <MapPopUpListener
                activeSelector={roadworksSelectors.defectsActive}
                polygonSelector={roadworksSelectors.defectsPolygon}
            >
                <PopUpModal
                    // history={history}
                    onClose={() => {
                        dispatch(actions.clearDefectsActive());
                    }}
                />
            </MapPopUpListener>

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
