import { Scrollbars } from 'react-custom-scrollbars';

const MapScroll = ({
    children,
    height,
    autoHeight,
    universal,
    refElement,
    onUpdate,
    renderTrackVertical,
    renderThumbVertical,
}) => {
    return (
        <Scrollbars
            style={
                height
                    ? { height }
                    : {}
            }
            autoHeight={autoHeight}
            universal={universal}
            ref={refElement}
            onUpdate={(values) => values.top > 0.85 && onUpdate?.()}
            renderTrackVertical={renderTrackVertical}
            renderThumbVertical={renderThumbVertical}
        >
            {children}
        </Scrollbars>
    );
};

export default MapScroll;
