import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, ListItem, MenuItem, Select, TextField } from '@mui/material';

import {
    createCamera,
    editCamera,
    loadCameraModel,
    loadVideoCamerasDictionaryInfrastructureTypes,
} from 'redux/RoadNetwork/actions';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useStoreProp, useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import LatLonCoordinates from 'components/common/Location/LatLonCoordinates';
import LoadAddressByCoords from 'components/common/Location/LoadAddressByCoords';
import AddressList from 'components/common/Location/AddressList';
import MapDragMarker from 'components/common/Location/MapDragMarker';
import SelectCameraModel from 'components/common/Autocomplete/RoadNetwork/CameraModel';
import UploadImageField from 'components/common/Upload/UploadImageField';

import SelectType from './SelectType';

import type { CameraModel } from 'types/RoadNetwork';
import type { Camera } from './types';

interface BaseProps {
    isOpen: boolean
    onClose: () => void
    reloadList: () => void
}

interface AddProp extends BaseProps {
    isNew: true
    item?: never
}

interface EditProps extends BaseProps  {
    isNew?: false | never
    item: Camera
}

type Props = AddProp | EditProps

const ModalForm = ({ isOpen, onClose, reloadList, item, isNew }: Props) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const infrastructureTypes = useStoreProp(
        loadVideoCamerasDictionaryInfrastructureTypes,
        'roadNetworks',
        'videoCamerasDictionaryInfrastructureTypes'
    );

    const initState = {
        name: item?.name || '',
        type_id: item?.type_id || '',
        address: item?.address || {},
        address_text: item?.address_text || '',
        lat: item?.lat || '',
        lon: item?.lon || '',
        geometry: item?.geometry || {},
        placing_type: item?.placing_type || '',
        serial_number: item?.serial_number || '',
        ip_route: item?.ip_route || '',
        infrastructure_type_id: item?.infrastructure_type_id || '',
        vendor_model_id: item?.vendor_model_id || '',
        custom_icon: item?.custom_icon || '',
    };

    const [formData, setFormData] = useState(initState);
    const [cameraModel, setCameraModel] = useState<CameraModel | null>(null);

    const handleChange = (name: string, value: string) => {
        setFormData({ ...formData, [name]: value });

        validation.deleteKey(name);
    };

    const handleSave = () => {
        const data = removeEmptyFields({
            ...formData,
            vendor_model_id: cameraModel?.id,
            vendor_id: cameraModel?.camera_vendor_id
        }, false);

        const callback = () => {
            onClose();
            reloadList();
        };

        isNew
            ? dispatch(createCamera(data, callback))
            : dispatch(editCamera(item?.id, data, callback));
    };

    useEffect(() => {
        if (!isNew) {
            const id_list = [item?.vendor_model_id];

            const returnResponse = (cameraModelList: CameraModel[]) => {
                if (cameraModelList.length > 0) {
                    setCameraModel(cameraModelList[0]);
                }
            };

            dispatch(loadCameraModel(1, 1, { id_list }, returnResponse));
        }
    }, [dispatch, isNew, item?.vendor_model_id]);

    const isDisabled = Object.values(removeEmptyFields(formData)).length === 0;
    
    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            noPadding
            maxWidthProp="md"
            isOpen={isOpen}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: handleSave,
                            disabled: isDisabled,
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('name')}
                            helperText={validation.get('name')}
                        >
                            <TextField
                                label={titles.NAME}
                                required
                                variant="outlined"
                                size="small"
                                value={formData.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                                error={validation.isKey('name')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            helperText={validation.get('type_id')}
                            error={validation.isKey('type_id')}
                        >
                            <SelectType
                                value={formData.type_id}
                                onChange={(value) => handleChange('type_id', value)}
                                className="block"
                                error={validation.isKey('type_id')}
                                required
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('placing_type')}
                            helperText={validation.get('placing_type')}
                        >
                            <TextField
                                label="Тип размещения"
                                required
                                variant="outlined"
                                size="small"
                                value={formData.placing_type}
                                onChange={(e) => handleChange('placing_type', e.target.value)}
                                error={validation.isKey('placing_type')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('vendor_model_id')}
                            helperText={validation.get('vendor_model_id')}
                        >
                            <SelectCameraModel
                                selected={cameraModel}
                                onChange={(value) => {
                                    if (!Array.isArray(value) && typeof value !== 'string') {
                                        setCameraModel(value);
                                        validation.deleteKey('vendor_model_id');
                                    }
                                }}
                                required
                                error={validation.isKey('vendor_model_id')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('serial_number')}
                            helperText={validation.get('serial_number')}
                        >
                            <TextField
                                label="Серийный номер"
                                variant="outlined"
                                size="small"
                                value={formData.serial_number}
                                onChange={(e) => handleChange('serial_number', e.target.value)}
                                error={validation.isKey('serial_number')}
                            />
                        </FormInfoWrapper>

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('ip_route')}
                            helperText={validation.get('ip_route')}
                        >
                            <TextField
                                label="IP адрес"
                                variant="outlined"
                                size="small"
                                value={formData.ip_route}
                                onChange={(e) => handleChange('ip_route', e.target.value)}
                                error={validation.isKey('ip_route')}
                            />
                        </FormInfoWrapper>

                        <FormControl className="block" size={'small'} variant="outlined">
                            <InputLabel>Тип инфраструктуры</InputLabel>

                            <Select
                                value={formData.infrastructure_type_id}
                                onChange={(e) => handleChange('infrastructure_type_id', e.target.value)}
                                label="Тип инфраструктуры"
                            >
                                <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>

                                {Object?.keys(infrastructureTypes)?.map((key) => (
                                    <MenuItem key={key} value={key}>
                                        {infrastructureTypes[key]}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <ListItem divider>
                            <UploadImageField
                                value={formData.custom_icon}
                                name="custom_icon"
                                onChange={handleChange}
                            />
                        </ListItem>
                    </Grid>

                    <Grid item xs={6}>
                        <LatLonCoordinates
                            lat={formData.lat || ''}
                            lon={formData.lon || ''}
                            onChange={(value) => setFormData({ ...formData, ...value })}
                            required
                        />

                        <FormInfoWrapper
                            className="block"
                            error={validation.isKey('geometry')}
                            helperText={validation.get('geometry')}
                        >
                            <LoadAddressByCoords
                                {...formData}
                                onChange={(value) => setFormData({ ...formData, ...value })}
                            >
                                <AddressList required returnGeometry />
                            </LoadAddressByCoords>
                        </FormInfoWrapper>

                        <MapDragMarker
                            lat={formData.lat}
                            lon={formData.lon}
                            onChange={(value: { lat: string; lon: string }) => setFormData({ ...formData, ...value })}
                            required
                            returnGeometry
                        />
                    </Grid>
                </Grid>
            </div>
        </Modal>
    );
};

export default ModalForm;
