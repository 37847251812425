import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import * as actions from 'redux/Parking/actions';
import { parkingSelectors } from 'redux/Parking';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { GeoJsonNew, Marker, ToolTip } from 'components/MapComponents/leaflet';
import {
    createIcon as createIconTitle,
    MapPopUp,
    MapPopUpListener
} from 'components/MapComponents/MapPopUp';

import { createIconMarker, getColor, getFilter, isShowGeometry } from './helper';
import config from './config.js';
import CollectorForms from './CollectorForms';
import ItemPopup from './PopUp';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(parkingSelectors.polygon);
    const active = useSelector(parkingSelectors.active);
    const filters = useSelector(parkingSelectors.filters);
    const saved = useSelector(parkingSelectors.saved);
    const loadingPolygon = useSelector(parkingSelectors.polygonLoading);
    const prevLoadingPolygon = usePrevious(loadingPolygon);

    const showGeometry = useRef(isShowGeometry(map.getZoom()));

    const showPopup = useRef(0);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadPolygon(polygon, filter));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();
    const handleFetchZoom = () => {
        showGeometry.current = isShowGeometry(map.getZoom());
        debounceFetchPolygon();
    };

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            fetchPolygon();
        }
    }, [saved]);

    useEffect(() => {
        fetchPolygon();
    }, [filters]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchZoom);

        return () => {
            dispatch(actions.clearActive());

            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchZoom);
        };
    }, [filters]);

    useCallback(() => () => {
        dispatch(actions.clearPolygon());
        // dispatch(actions.clearActive());
    }, []);

    const handleZoomedToBounds = (item) => {
        const { location = null } = item || {};
        if (location && location?.coordinates.length > 0) {
            showPopup.current = item.id;
            const b = L.geoJSON(location).getBounds();
            map.fitBounds(b);
        }
    };

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            handleZoomedToBounds(active);
        }
    }, [active]);

    useEffect(() => {
        if (
            showPopup.current
            && prevLoadingPolygon === true
            && loadingPolygon === false
        ) {
            setTimeout(() => {
                map.fire(`showBy${config.slug}${showPopup.current}`);
                showPopup.current = 0;
            }, 200);
        }
    }, [loadingPolygon, prevLoadingPolygon]);

    // собираем геообъект
    const showElements = useMemo(() => {
        // геообъекты
        if (showGeometry.current) {
            // собираем геообъект
            const geometry = polygon
                ?.map(({ id, name, location = {}, status_color }) => ({
                    ...location,
                    properties: {
                        id,
                        name,
                        color: status_color || getColor(),
                    },
                    attribution: {
                        slug: config.slug,
                        color: status_color || getColor()
                    },
                    style: {
                        color: status_color || getColor(),
                    }
                }));

            return (
                <GeoJsonNew
                    {...props}
                    data={geometry}
                    toolTipTemplate={({ name = '' }) => name ? <div>{name}</div> : ''}
                    icon={({ color }) => createIconMarker(color)}
                    idPrefix={config.slug}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5]
                    }}
                    //popUpTemplate={(data) => <ItemPopup {...data} />}
                    onClick={(item) => dispatch(actions.setActive(item))}
                />
            );
        }

        // маркеры
        return polygon
            ?.map((item) => (
                <Marker
                    {...props}
                    key={item.id}
                    icon={createIconMarker(item.status_color)}
                    latlng={[item.lat, item.lon]}
                    attribution={{
                        slug: config.slug,
                        color: item.status_color || getColor()
                    }}
                    onClick={() => handleZoomedToBounds(item)}
                >
                    {item.name && (
                        <ToolTip
                            direction="top"
                            offset={[0, -40]}
                        >
                            <div>{item.name}</div>
                        </ToolTip>
                    )}
                </Marker>
            ));
    }, [polygon, showGeometry.current]);

    return (
        <>
            {showElements}

            {/* popup */}
            <MapPopUpListener
                activeSelector={parkingSelectors.active}
                polygonSelector={parkingSelectors.polygon}
            >
                <MapPopUp
                    icon={createIconTitle(config.layerIcon)}
                    title={({ name = '' }) => name || ''}
                    onClose={() => {
                        dispatch(actions.clearActive());
                    }}
                    titleBackgroundColor={({ status_color }) => colorExtend(
                        status_color || config.mapMarkersColors.default
                    )}
                    medium
                >
                    <ItemPopup/>
                </MapPopUp>
            </MapPopUpListener>

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
