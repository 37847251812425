import type { Factor } from 'modules/React/Dictionaries/IncidentFactors/types';

import FactorItem from './FactorItem';

export interface IncidentFactor extends Factor {
    id: number;
}
export interface  FactorsListType  {
  factors: IncidentFactor[];
  onChange: (factors: IncidentFactor[]) => void;
  visibleGeometry: any;
}

export const FactorsList = ({ factors, onChange, visibleGeometry }: FactorsListType) => {

    const onChangeList = (index: number, value: IncidentFactor) => {
        const newList = [
            ...factors.slice(0, index),
            value,
            ...factors.slice(index +1)
        ];

        onChange(newList);
    };

    return (
        <>
            <h3>Добавление геоинформации:</h3>
            {factors.map((factor: IncidentFactor, index: number) => (
                <FactorItem
                    key={factor.id}
                    factor={factor}
                    onChange={(val: IncidentFactor) => onChangeList(index, val)}
                    visibleGeometry={visibleGeometry}
                />
            ))}
        </>
    );
};
