import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItemAvatar } from '@mui/material';

import ConfirmDelete from 'components/common/ConfirmModal';
import { checkBase64 } from 'components/MapComponents/Layers/TransportPassenger/helper';
import icon from 'components/MapComponents/Layers/TransportIncidents/icons/icon';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import messages from 'helpers/constants/messages';
import { deleteIncidentType } from 'modules/React/redux/actions';
import type { IncidentType } from 'modules/React/Dictionaries/IncidentsTypes/types';

import ModalForm from './ModalForm';


interface ItemProps {
    item: IncidentType;
    reloadList: (isDelete?: boolean) => void;
}

const Item = ({ item, reloadList }: ItemProps) => {
    const dispatch = useDispatch();

    const [isOpenEditModal, setIsOpenEditModal] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteIncidentType(item?.id, () => {
            reloadList(true);
            setIsOpenDeleteModal(false);
        }));
    };

    const getImage = () => {
        const imageB64 = item?.image
            || null;
        return imageB64 ? `<img class="image-inner" src="${checkBase64(imageB64)}">` : `${icon({ fill: '#000' })}`;
    };

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    <ListItemAvatar>
                        <i
                            style={{
                                display: 'flex',
                                maxWidth: '30px',
                            }}
                            dangerouslySetInnerHTML={{ __html: getImage() }}
                        />
                    </ListItemAvatar>
                </LSContentColumn>

                <LSContentColumn>{item.name || messages.NO_VALUE}</LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            ...(!item?.is_system
                                ? [
                                    {
                                        ...buttonsTypes.editIcon,
                                        onClick: () => setIsOpenEditModal(true),
                                    },
                                    {
                                        ...buttonsTypes.deleteIcon,
                                        onClick: () => setIsOpenDeleteModal(true),
                                    },
                                ]
                                : []),
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>

            {isOpenEditModal && (
                <ModalForm
                    isOpen={isOpenEditModal}
                    onClose={() => setIsOpenEditModal(false)}
                    item={item}
                    reloadList={reloadList}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmDelete
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                />
            )}
        </>
    );
};

export default Item;
