import { Divider } from '@mui/material';

import { loadOwnerships } from 'redux/Incidents/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';

import { getValue } from '../../helper';

const TabVehicles = ({ data, withoutDivider = false, tag = 'span' }) => {
    const TextTag = tag;
    const ownerships = useStoreProp(loadOwnerships, 'incidents', 'ownerships');

    const arrayValue = (value) =>
        Array.isArray(value) && value.length > 0
            ? value.map((item, i) => <div key={i}>{item}</div>)
            : messages.INFO_IS_NOT_FOUND;

    return (
        <>
            {data?.length
                ? data?.map((item, i) => {
                    return (
                        <div key={item.id}>
                            <div className="info__item">
                                <TextTag>{titles.TS}: </TextTag>
                                <span>{i + 1}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.LEAVING_SCENE}: </TextTag>
                                <span>{item.leaving_scene || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.GRZ}: </TextTag>
                                <span>{item.grn || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.TRANSPORT_TYPE}: </TextTag>
                                <span>{item.class_name || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.BRAND}: </TextTag>
                                <span>{item.brand || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.MODEL}: </TextTag>
                                <span>{item.model || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.COLOR}: </TextTag>
                                <span>{item.color || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.PLACES_OF_DAMAGE}: </TextTag>
                                <span>{arrayValue(item.places_of_damage_text)}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.YEAR_OF_MANUFACTURE}: </TextTag>
                                <span>{item.year || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.TECHNICAL_PROBLEMS}: </TextTag>
                                <span>{item.technical_issues || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.DRIVE_LOCATION_TYPE}: </TextTag>
                                <span>{item.drive_location_type || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.OWNERSHIP_FORM}: </TextTag>
                                <span>{getValue(ownerships, item?.ownership_type)?.name|| messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <TextTag>{titles.COMMENT}: </TextTag>
                                <span>{item.comment || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            {!withoutDivider && <Divider/>}
                        </div>
                    );
                })
                : <div className="info__item">
                    {messages.INFO_IS_NOT_FOUND}
                </div>
            }
        </>
    );
};

export default TabVehicles;