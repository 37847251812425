import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious, useStoreFromSelectorListToObject, useStoreProp } from 'helpers/hooks';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { Marker } from 'components/MapComponents/leaflet';
import { MapPopUpListener } from 'components/MapComponents/MapPopUp';
import { reactSelectors } from 'modules/React';
import * as actions from 'modules/React/redux/actions';

import config from './config';
import { createIconMarker, getFilter } from './helper';
import PopUpModal from './PopUpModal';

const Layer = (props) => {
    const { map, filter: filterProp } = props;
    const dispatch = useDispatch();

    const incidentPolygon = useSelector(reactSelectors.incidentPolygon);
    const filtersSelector = useSelector(reactSelectors.filters);
    const active = useSelector(reactSelectors.active);
    const filters =  filterProp || filtersSelector;
    const prevFilters = usePrevious(filters);

    const statuses = useStoreFromSelectorListToObject(
        actions.loadIncidentsStatuses,
        reactSelectors.incidentsStatuses
    );

    const threat_levels = useStoreProp(actions.loadThreatLevels, 'react', 'threatLevels');

    const fetchPolygon = useCallback(() => {
        const filter = getFilters(filters, getFilter);
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.loadIncidentPolygon(polygon, filter));
    },[dispatch, filters, map]);

    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = useCallback(() => debounceFetchPolygon(), [debounceFetchPolygon]);

    useEffect(() => {
        fetchPolygon();
    }, [fetchPolygon]);

    useEffect(() => () => {
        dispatch(actions.clearIncidentPolygon());
        dispatch(actions.clearActive());
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, map]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon]);
            }
        }
    }, [active]);

    const polygonData = useMemo(() => incidentPolygon?.map((item) => {
        const { id, lat, lon, status_id, threat_level_id } = item;

        const { color, name }  = item.threat_level || threat_levels.data.find(el => el.id === threat_level_id);
        const status_text   = item.status_text || statuses.find(el => el.id === status_id);
        item.status_text = status_text;
        item.threat_level_color = color;
        item.threat_level_name = name;
        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color,
            },
            onClick: () => {
                dispatch(actions.setActive(item));
            },
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(color)}
            />
        );
    }), [incidentPolygon]);

    return (
        <>
            {polygonData}
            <MapPopUpListener
                activeSelector={reactSelectors.active}
                polygonSelector={reactSelectors.incidentPolygon}
            >
                <PopUpModal
                    onClose={() => { dispatch(actions.clearActive()); }}
                    statuses={statuses}
                />
            </MapPopUpListener>
        </>
    );
};

export default Layer;
