import L from 'leaflet';
import { reduce } from 'lodash';

import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';

import iconMap from './icons/iconMap';
import config from './config';
/*
    если есть dead в ДТП - то всегда цвет красный #ad1818
    остальные цвета - примерно
    1: "Наезд на пешехода" - оранжевый #daa655
    2: "Столкновение" - синий #006195
    3: "Наезд на стоящее ТС" - голубой 0495e3
    4: "Иной вид ДТП" - зеленый #00AE5C
    5: "Наезд на препятствие " - серый 7b7b7b
    6: "Наезд на велосипедиста" - оранжевый c37700
*/

export const getColor = ({ dead, dtp_type }, fnGettype) => {
    // if (dead) {
    //     return config.mapMarkersColors.dead;
    // }
    const currentType = typeof fnGettype === 'function' ? fnGettype(dtp_type) : null;
    return currentType ? colorExtend(currentType.color) : config.mapMarkersColors.default;
};

// получить иконку маркера
export const createIconMarker = (color) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: color }),
    });
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_date: filter.start_date ? fullDateTimeWithTimeZone(filter.start_date) : null,
        end_date: filter.end_date ? fullDateTimeWithTimeZone(filter.end_date) : null,
    };
    if (filters.camera) delete filters.camera;

    const paramsWithoutEmpty = reduce(
        filters,
        (res, el, key) => {
            if (el) {
                res[key] = el;
            }
            return res;
        },
        {}
    );

    return paramsWithoutEmpty;
};

export const getValue = (obj, selected) => Array.isArray(selected)
    ? selected.map((el) => ({ id: el, name: obj[el] || '' }))
    : selected ? { id: selected, name: obj[selected] || '' } : null;

export const generateOptions = (obj) => Object.entries(obj).map(([id, name]) => ({ id, name }));

// поля для обогащения
const expendFields = [
    'address',
    'address_text',
    'camera_name',
    'changes_driving',
    'concentration_area_link_status_text',
    'count_members',
    'count_ts',
    'dead',
    'dead_child',
    'description',
    'device_traffic_light_crossroads',
    'device_traffic_light_crosswalk',
    'direction',
    'distance_to_marker',
    'driving_mode_influence_factors',
    'driving_mode_influence_factors_text',
    'dtp_at',
    'dtp_type',
    'dtp_type_name',
    'external_id',
    'frame_url',
    'intersection',
    'km',
    //'lat',
    'lat_degree',
    'lat_minutes',
    'lat_seconds',
    'lighting',
    //'lon',
    'lon_degree',
    'lon_minutes',
    'lon_seconds',
    'm',
    'manual_edit',
    'materials',
    'members',
    'road',
    'road_category',
    'road_condition',
    'road_defects',
    'road_defects_text',
    'road_objects_around',
    'road_purpose',
    'road_purpose_text',
    'road_value',
    'schema_img_url',
    'status',
    'status_text',
    'street_category',
    'ti_id',
    'ti_name',
    'timezone',
    'type_load',
    'type_load_text',
    'vehicles',
    'video_url',
    'weather',
    'wounded',
    'wounded_child'
];

// проверка поля
const isFieldNull = (field) => (Array.isArray(field) && field?.length === 0) || !field;

// обогатить дтп данными другого дтп
export const compareDtp = (sourceDtp, expendDtp) => expendFields
    .reduce((res, key) => {
        if (
            isFieldNull(res[key]) // нет данных
            && !isFieldNull(expendDtp[key]) // есть данные
        ) {
            res[key] = expendDtp[key]; // заполнение данными
        }
        return res;
    }, sourceDtp);
