import { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

import { TypeLoad, Types } from 'components/common/Autocomplete/DTP';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filters = ({ filterValues, setFilterValues, initialState, getGraph, resetFilters }) => {

    const handleDate = (value, keyName) => {
        setFilterValues({
            ...filterValues,
            [keyName]: value
        });
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const isDisabledHandler = (value) => setIsDisabled(value);

    const updateFilter = (filters) => {
        const result = {
            ...initialState,
            ...filters,
        };
        setFilterValues(result);
        if (Object.keys(filters).length > 0) {
            getGraph(result);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilters}
            onSearch={() => getGraph(filterValues)}
            filter={filterValues}
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
            disabled={!filterValues.start_date || !filterValues.end_date || isDisabled}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={filterValues.start_date}
                    valueEndDate={filterValues.end_date}
                    handleDate={(value) => handleDate(value, 'start_date')}
                    handleEndDate={(value) => handleDate(value, 'end_date')}
                    isDisabledHandler={isDisabledHandler}
                />

                <Types
                    multiple
                    selected={filterValues.dtp_type || []}
                    onChange={(value) => handleDate(value, 'dtp_type')}
                />

                <TypeLoad
                    selected={filterValues.type_load_list || []}
                    onChange={(value) => handleDate(value, 'type_load_list')}
                    openOnFocus
                />

            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <FormControl style={{ marginLeft: 9 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterValues.with_wounded}
                                onChange={(e) => handleDate(e.target.checked, 'with_wounded')}
                                size="small"
                            />
                        }
                        label="ДТП с ранеными"
                    />
                </FormControl>

                <FormControl style={{ marginLeft: 9 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterValues.with_dead}
                                onChange={(e) => handleDate(e.target.checked, 'with_dead')}
                                size="small"
                            />
                        }
                        label="ДТП с погибшими"
                    />
                </FormControl>

            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filters;
