import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setMapLoading } from 'redux/Map/actions';
import { showMessage } from 'redux/Message/actions';
import messageTypes from 'redux/Message/messagetypes';
import messages from 'helpers/constants/messages';

import * as actions from './actions';
import * as types from './types';
import api from './api.methods';


function* loadCountByMonthsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCountByMonths, payload);
    if (response?.success) {
        yield fnCallback?.(response.data || {});
    }
    yield fnLoading?.(false);
}

function* loadCountByPeriodsSaga({ payload, fnLoading, fnCallback }) {
    yield fnLoading?.(true);
    const response = yield call(api.getCountByPeriods, payload);
    if (response?.success) {
        yield fnCallback?.(response.data || {});
    }
    yield fnLoading?.(false);
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_COUNT_BY_MONTH, loadCountByMonthsSaga),
        takeLatest(types.LOAD_COUNT_BY_PERIODS, loadCountByPeriodsSaga),
    ]);
}
