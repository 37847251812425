import { pick } from 'lodash';

import { loadComplexTypes, loadComplexUnitTypes } from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import InformationFields from 'components/common/Information';
import MapScroll from 'components/MapComponents/MapScroll';

const Information = ({
    data,
    showButtons = true,
}) => {

    const dorisComplexTypes = useStoreProp(loadComplexTypes, 'dorisControl', 'doris_complex_types');
    const unitTypes = useStoreProp(loadComplexUnitTypes, 'dorisControl', 'doris_complex_unit_types');

    const fieldsData = {
        serial_number: {
            title: 'Серийный номер',
        },
        // name: {
        //     title: 'Наименование комплекса',
        // },
        // unit_types: {
        //     title: 'Вид комплекса',
        // },
        // species_id: {
        //     title: 'Вид комплекса',
        //     value: ({ species_id }) => unitTypes[species_id] || messages.INFO_IS_NOT_FOUND
        // },
        // type_id: {
        //     title: 'Тип комплекса',
        //     value: ({ type_id }) => dorisComplexTypes[type_id] || messages.INFO_IS_NOT_FOUND
        // },
        species_id_type_id: {
            title: 'Вид комплекса / Тип комплекса',
            value: ({ species_id, type_id }) => {
                // видимо сменился формат типов ([{ id: 8, name: 'Автодория' }, ...])
                const complexType = dorisComplexTypes
                    ?.find(({ id }) => id === type_id)
                    ?.name
                    || messages.INFO_IS_NOT_FOUND;
                return `${unitTypes[species_id] || messages.INFO_IS_NOT_FOUND} / ${complexType}`;
            }
        },
        // ip_route: {
        //     title:'IP-адрес',
        // },
        // mask: {
        //     title: 'Маска подсети',
        // },
        // ip_route_mask: {
        //     title: 'IP-адрес / Маска подсети',
        //     value: ({ ip_route, mask }) => `${ip_route} / ${mask}`
        // },
        lat_lon: {
            title: 'Координаты (lat, lon)',
            value: ({ lat, lon }) => `${lat}, ${lon}`
        },
        address_text: {
            title: 'Адрес',
        },
        // wwindspeed: {
        //     title: 'Дата окончания поверки комплекса',
        // },
    };

    // выводимые поля
    const fields = showButtons
        ? fieldsData
        : pick(fieldsData, [
            'serial_number',
            'species_id_type_id',
            // 'ip_route_mask',
            'lat_lon',
            'address_text',
        ]);

    return (
        <>
            {!data
                ? <div className="not-found">{messages.INFO_IS_NOT_FOUND}</div>
                : <MapScroll>
                    <InformationFields
                        data={data}
                        title={fields}
                        defaultValue={messages.INFO_IS_NOT_FOUND}
                    />
                </MapScroll>
            }
        </>
    );
};

export default Information;
