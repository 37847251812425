import moduleName from './module';

const prefix = `${moduleName}`;

export const GET_STATUSES = `${prefix}/GET_STATUSES`;
export const STATUSES_LOADED = `${prefix}/STATUSES_LOADED`;
export const LOADING = `${prefix}/LOADING`;
export const GET_USERS = `${prefix}/GET_USERS`;
export const USERS_LOADED = `${prefix}/USERS_LOADED`;
export const GET_ROLES = `${prefix}/GET_ROLES`;
export const ROLES_LOADED = `${prefix}/ROLES_LOADED`;
export const REGISTER_NEW_USER = `${prefix}/REGISTER_NEW_USER`;
export const EDIT_USER = `${prefix}/EDIT_USER`;
export const DELETE_USER = `${prefix}/DELETE_USER`;
export const GET_PERMISSIONS = `${prefix}/GET_PERMISSIONS`;
export const PERMISSIONS_LOADED = `${prefix}/PERMISSIONS_LOADED`;
export const CREATE_ROLE = `${prefix}/CREATE_ROLE`;
export const EDIT_ROLE = `${prefix}/EDIT_ROLE`;
export const DELETE_ROLE = `${prefix}/DELETE_ROLE`;
export const LOAD_DTP_SETTINGS = `${prefix}/LOAD/DTP/SETTINGS`;
export const LOADING_DTP_SETTINGS = `${prefix}/LOADING/DTP/SETTINGS`;
export const LOADED_DTP_SETTINGS = `${prefix}/LOADED/DTP/SETTINGS`;
export const EDIT_DTP_SETTINGS = `${prefix}/EDIT/DTP/SETTINGS`;
export const LOAD_CURRENT_USER = `${prefix}/LOAD/CURRENT/USER`;
export const LOADING_CURRENT_USER = `${prefix}/LOADING/CURRENT/USER`;
export const LOADED_CURRENT_USER = `${prefix}/LOADED/CURRENT/USER`;
export const CREATE_NEW_USER = `${prefix}/CREATE_NEW_USER`;
export const RESET_PASSWORD = `${prefix}/RESET_PASSWORD`;
export const RESETTING_PASSWORD = `${prefix}/RESETTING_PASSWORD`;
export const RESETED_PASSWORD = `${prefix}/RESETED_PASSWORD`;

export const LOAD_SETTINGS = `${prefix}/LOAD_SETTINGS`;
export const LOADING_SETTINGS = `${prefix}/LOADING_SETTINGS`;
export const LOADED_SETTINGS = `${prefix}/LOADED_MAIL_SETTINGS`;
export const EDIT_SETTING = `${prefix}/EDIT_SETTING`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOAD_USER_ACTIVITY = `${prefix}/LOAD_USERS_ACTIVITY`;
export const LOADING_USER_ACTIVITY = `${prefix}/LOADING_USER_ACTIVITY`;
export const LOADED_USER_ACTIVITY = `${prefix}/LOADED_USER_ACTIVITY`;

export const LOAD_METHODS = `${prefix}/LOAD_METHODS`;
export const LOADED_METHODS = `${prefix}/LOADED_METHODS`;

export const LOAD_MICROSERVICES = `${prefix}/LOAD_MICROSERVICES`;
export const LOADED_MICROSERVICES = `${prefix}/LOADED_MICROSERVICES`;

export const LOAD_ACTIONS = `${prefix}/LOAD_ACTIONS`;
export const LOADED_ACTIONS = `${prefix}/LOADED_ACTIONS`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_SERVICE_VERSIONS = `${prefix}/LOAD_SERVICE_VERSIONS`;
export const LOADING_SERVICE_VERSIONS = `${prefix}/LOADING_SERVICE_VERSIONS`;
export const LOADED_SERVICE_VERSIONS = `${prefix}/LOADED_SERVICE_VERSIONS`;

export const LOAD_USER_TYPES = `${prefix}/LOAD_USER_TYPES`;
export const LOADED_USER_TYPES = `${prefix}/LOADED_USER_TYPES`;
