import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { load1CNamesReport, loadComplexTypes } from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import GetReports from 'components/common/GetReports/index';
import ComplexTypeSelect from 'components/common/SimpleSelect/index';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter/index';
import removeEmptyFields from 'helpers/removeEmptyFields';

interface ComplexType {
    id: number;
    name: string;
}

interface Format {
    id: string;
    name: string;
}

interface DataState {
    complex_type: ComplexType | null;
    formats: Format[];
}

const Filter = () => {
    const dispatch = useDispatch();

    const complexTypes: ComplexType[] = useStoreProp(
        loadComplexTypes,
        'dorisControl',
        'doris_complex_types'
    );

    const initialState: DataState = {
        complex_type: null,
        formats: [],
    };

    const [data, setData] = useState<DataState>(initialState);

    const handleChange = (name: keyof DataState, value: any) => {
        setData({ ...data, [name]: value });
    };

    const handleLoadReport = () => {
        const paramsWithoutEmpty = removeEmptyFields({
            ...data,
            type_id: data.complex_type?.id,
            complex_type: null,
            formats: data.formats.map(item => item.id),
        }, false);

        dispatch(load1CNamesReport(paramsWithoutEmpty));
    };

    const resetFilter = () => {
        setData(initialState);
    };

    const updateFilter = (filter: Partial<DataState>) => {
        setData({
            ...initialState,
            ...filter
        });
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            buttonType="getReport"
            onSearch={handleLoadReport}
            filter={data}
            setUserFilter={updateFilter}
            disabled={(!data.complex_type && !data.formats.length)}
            isFilterVisible={true}
        >
            <LayoutFilter.Visible>
                <ComplexTypeSelect
                    options={complexTypes}
                    selected={data.complex_type}
                    onChange={(value) => handleChange('complex_type', value)}
                    label="Тип комплекса"
                    renderLabel={(option) => option?.name || ''}
                />

                <GetReports
                    selected={data.formats}
                    onChange={(values: Format[]) => handleChange('formats', values)}
                    reportId={8}
                    onDownload={undefined}
                    helperText={undefined}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
