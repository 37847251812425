import { useState } from 'react';

import titles from 'helpers/constants/titles';
import { fullDateTime } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Information from 'components/common/Information';
import TransportIncidentInfoModal from 'components/pages/Reports/DTP/Incidents/TransportIncidentInfoModal';

import numberValue from '../../numberValue';

import TypeValue from './TypeValue';
import ChangeStatusForm from './ChangeStatusForm';


const TabInfo = ({ data, readOnly }) => {
    const [openTIInfoModal, setOpenTIInfoModal] = useState(false);

    return (
        <>
            <Information
                data={data}
                defaultValue={messages.INFO_IS_NOT_FOUND}
                defaultValueClass="info__item"
                returnNull
                title={{
                    status_text: {
                        title: titles.STATUS,
                        value: ({ status_text }) => (
                            <>
                                {status_text}
                                &nbsp;
                                {readOnly === false && (
                                    <ChangeStatusForm
                                        dataId={data?.id}
                                        dataStatus={data?.status?.toString()}
                                    />
                                )}
                            </>
                        )
                    },
                    emtp_number: {
                        title: titles.DTP_NUMBER
                    },
                    dtp_type: {
                        title: titles.DTP_TYPE,
                        value: ({ dtp_type }) => <TypeValue id={dtp_type} />
                    },
                    manual_edit: {
                        title: 'Ручное редактирование',
                        value: ({ manual_edit }) => manual_edit ? 'Да' : 'Нет'
                    },
                    dtp_at: {
                        title: titles.DATE_TIME_DTP,
                        value: ({ dtp_at }) => fullDateTime(dtp_at)
                    },
                    lat: {
                        title: titles.LAT,
                        value: ({ lat }) => prepareCoords(lat, 6)
                    },
                    lon: {
                        title: titles.LON,
                        value: ({ lon }) => prepareCoords(lon, 6)
                    },
                    address: {
                        title: titles.ADDRESS,
                        value: ({ address, dtp }) => dtp?.address_text || Object.keys(address || {}).length > 0
                            ? dtp?.address_text || renderAddress(address)
                            : messages.INFO_IS_NOT_FOUND
                    },
                    intersection: {
                        title: titles.INTERSECTION_STREET,
                    },
                    km: {
                        title: titles.DTP_KM,
                        value: ({ km }) => numberValue(km)
                    },
                    m: {
                        title: titles.DTP_M,
                        value: ({ m }) => numberValue(m)
                    },
                    description: {
                        title: titles.DTP_DESCRIPTION
                    },
                    schema_img_url: {
                        title: titles.SCHEMA_DTP,
                        value: ({ schema_img_url }) =>
                            schema_img_url && schema_img_url.length > 0 ? (
                                <img
                                    alt="img"
                                    style={{
                                        height: 'auto',
                                        width: '200px',
                                        maxWidth: '100%',
                                        padding: '10px 0'
                                    }}
                                    src={schema_img_url}
                                />
                            ) : (
                                messages.INFO_IS_NOT_FOUND
                            )
                    },
                    type_load_text: {
                        title: titles.DTP_DATA_SOURCE
                    },
                    ti_id: {
                        title: titles.RELATIVE_EVENT,
                        value: ({ ti_id }) => {
                            if (ti_id) {
                                return <FormButtons
                                    buttons={[
                                        {
                                            ...buttonsTypes.show,
                                            variant: 'default',
                                            onClick: () => setOpenTIInfoModal(true),
                                        },
                                    ]}
                                />;
                            }
                            return messages.NO_DATA;
                        },
                        modal: true,
                    },
                    count_members: {
                        title: titles.COUNT_DTP_MEMBERS,
                        value: ({ count_members }) => numberValue(count_members)
                    },
                    dead: {
                        title: titles.COUNT_DTP_DEAD,
                        value: ({ dead }) => numberValue(dead)
                    },
                    wounded: {
                        title: titles.COUNT_DTP_WOUNDED,
                        value: ({ wounded }) => numberValue(wounded)
                    },
                    dead_child: {
                        title: titles.COUNT_DTP_DEAD_KIDS,
                        value: ({ dead_child }) => numberValue(dead_child)
                    },
                    wounded_child: {
                        title: titles.COUNT_DTP_WOUNDED_KIDS,
                        value: ({ wounded_child }) => numberValue(wounded_child)
                    },
                    count_ts: {
                        title: titles.COUNT_DTP_TS,
                        value: ({ count_ts }) => numberValue(count_ts)
                    },
                }}
            />

            {openTIInfoModal && (
                <TransportIncidentInfoModal
                    transportIncidentId={data?.ti_id}
                    onClose={() => setOpenTIInfoModal(false)}
                />
            )}
        </>
    );
};

export default TabInfo;
