import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar } from '@mui/material';

import { authSelectors } from 'redux/Auth';
import { getUsers } from 'redux/Admin/actions';
import Modal from 'components/common/Modal';
import { CustomTab, CustomTabs } from 'components/common/Tabs';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { isFloat } from 'helpers/validator';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import { createIncident, editIncident } from 'modules/React/redux/actions';

import { BasicData } from './BasicData';

import type { IncidentItem } from '../types';

type AddModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isNew?: boolean;
  item?: IncidentItem;
  reloadList: () => void;
}

export const AddModal = ({ isOpen, onClose, isNew = false, item = {}, reloadList }: AddModalProps) => {
    const dispatch = useDispatch();

    const getFirstCoordRec = (value: any): {lat: number | string; lon: number | string;} => {
        if (!value) {
            return {
                lat: '',
                lon: '',
            };
        }

        const lat = value?.[1] || '';
        const lon = value?.[0] || '';

        if (value?.geometry) {
            return getFirstCoordRec(value?.geometry);
        }
        if (value?.coordinates) {
            return getFirstCoordRec(value?.coordinates);
        }
        if (isFloat(lat) && isFloat(lon)) {
            // if (parseFloat(lat) === lat && parseFloat(lon) === lon) {
            return {
                lat,
                lon,
            };
        }
        return getFirstCoordRec(value[0]);
    };

    const user = useSelector(authSelectors.authUserInfo);

    const initialState: IncidentItem = {
        ...item,
        name: item?.name || '',
        type_id: item?.type_id,
        status_id: isNew ? 1 : item?.status_id,
        vehicle_types: item?.vehicle_types || [],
        registered_at: item?.registered_at || new Date(),
        date_plan: item?.date_plan || null,
        date_fact: item?.date_fact || null,
        created_at: item?.created_at || new Date(),
        description: item?.description || '',
        threat_level: item?.threat_level,
        factors: item?.factors || [],
        source: item?.source || '',
        organizations: item?.organizations || [],
        user: item?.user || [],
        dispatcher: item?.dispatcher || [user],
        resolve_rules: item?.resolve_rules,
        attachments: item?.attachments || [],
        type_creation: item?.type_creation || 1,
        geometry: item?.geometry || [],
        lat: item?.lat ?? getFirstCoordRec(item?.geometry).lat ?? 0,
        lon: item?.lon ?? getFirstCoordRec(item?.geometry).lon ?? 0,
        address: item?.address || [],
        area: item?.area || {}
    };

    const [currentTab, setCurrentTab] = useState('basicData');
    const [data, setData] = useState(initialState);

    const tabProps = (tabName: string) => ({
        id: `scrollable-force-tab-${tabName}`,
        'aria-controls': `scrollable-force-tabpanel-${tabName}`,
    });

    const tabPanelProps = (tabName: string) => ({
        role: 'tabpanel',
        id: `scrollable-force-tabpanel-${tabName}`,
        'aria-labelledby': `scrollable-force-tab-${tabName}`
    });

    const onAcceptModal = () => {
        const prepareData = removeEmptyFields({
            ...data,
            registered_at: fullDateTimeWithTimeZone(data.registered_at),
            created_at: fullDateTimeWithTimeZone(data.created_at),
            date_plan: fullDateTimeWithTimeZone(data.date_plan),
            date_fact: fullDateTimeWithTimeZone(data.date_fact),
            dispatcher: data?.dispatcher.map((el: any) => el.id),
            threat_level_id: data.threat_level?.id,
            user: data.user.map((el: any) => el.id)
        }, true, false, true);

        const callback = () => {
            reloadList();
            onClose();
        };

        isNew
            ? dispatch(createIncident(prepareData, callback))
            : dispatch(editIncident(item?.id, prepareData, callback));
    };

    useEffect(() => {
        const userIdList: Array<number> = [...new Set([...(item.dispatcher || []), ...(item.user || [])])];

        !isNew && userIdList.length > 0 && dispatch(getUsers({
            page: 1,
            limit: userIdList.length,
            //@ts-ignore
            ids: userIdList
        }, (response: any) => {
            setData((prev) => ({
                ...prev,
                user: response.filter((el: any) => item.user.find((user: number) => user === el.id)),
                dispatcher: response.filter((el: any) => item.dispatcher.find((user: number) => user === el.id)),
            }));
        }));
    }, [item.user, item.dispatcher]);

    return (
        <Modal
            isOpen={isOpen}
            title={isNew ? 'Создание Инцидента' : 'Редактирование Инцидента'}
            onClose={onClose}
            fullWidth
            noPadding={true}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onAcceptModal,
                        disabled: false
                    }
                ]}
            />}
        >
            <AppBar position="sticky" color="default">
                <CustomTabs
                    value={currentTab}
                    onChange={(_, newValue) => setCurrentTab(newValue)}
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="scrollable force tabs example"
                >
                    <CustomTab
                        iconPosition="start"
                        value="basicData"
                        label={'Основные данные'}
                        {...tabProps('basicData')}
                    />
                </CustomTabs>
            </AppBar>
            {currentTab === 'basicData'
                && <div {...tabPanelProps('basicData')}>
                    <BasicData isNew={isNew} data={data} setData={setData}/>
                </div>
            }
        </Modal>
    );
};
