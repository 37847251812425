import {
    TextField,
} from '@mui/material';

import FormInfoWrapper from 'components/common/FormInfoWrapper';
import FieldsModal from 'components/common/Location/FieldsModal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { buttonsTypes } from 'components/common/FormButtons';
import InfoBlock from 'components/common/InfoBlock';

import type { IncidentFactor } from './FactorsList';

interface FactorItemProps {
    factor: IncidentFactor;
    onChange: (factor: IncidentFactor) => void;
    visibleGeometry: any;
}

const FactorItem = ({ factor, onChange, visibleGeometry }: FactorItemProps) => {

    return (
        <>{factor.is_geo
            && <InfoBlock label={factor.name} mt="1rem">
                <div className="block">
                    <FieldsModal
                        title="Выбрать область на карте"
                        fields={{ geometry: factor?.geo_json || {} }}
                        buttonText={factor?.geo_json ? 'Редактировать геозону' :'+Добавить геозону'}
                        //@ts-ignore
                        buttonType={buttonsTypes.selectOnMap}
                        buttonVariant="contained"
                        onChange={({ geometry }: any) => {
                            onChange({ ...factor, geo_json: geometry, comment: null });
                        }}
                    >
                        <MapGeoJson
                            visibleGeometry={visibleGeometry}
                            circle={true}
                            polygon={true}
                            polyline={true}
                            marker={true}
                            isHideValidation
                        />
                    </FieldsModal>
                </div>
                <FormInfoWrapper>
                    <TextField
                        label="Причина отказа"
                        size="small"
                        value={factor.comment || ''}
                        variant="outlined"
                        name="name"
                        type="text"
                        onChange={(e) => {
                            console.log('EEE', e.target);

                            onChange({ ...factor, comment: e.target.value });
                        }}
                    />
                </FormInfoWrapper>
            </InfoBlock>
        }
        </>
    );
};

export default FactorItem;
