import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { reactSelectors } from 'modules/React';
import { loadIncidents } from 'modules/React/redux/actions';
import PageLayout from 'components/layout/PageLayout';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import { Filters } from './Filters';
import { AddModal } from './AddModal/Index';
import { IncidentItem as Item } from './IncidentItem';
import { RenderPassportTabs } from './RenderPassportTabs';

import type { Meta } from 'types/pagination';
import type { FilterParams, Incident, IncidentItem } from './types';
import titles from 'helpers/constants/titles';

const Incidents = () => {
    const dispatch = useDispatch();

    const data: Incident[] = useSelector(reactSelectors.incidentsList);
    const meta: Meta = useSelector(reactSelectors.incidentsMeta);
    const loading = useSelector(reactSelectors.loadingIncidents);
    // const incidentsFilterParams = useSelector(reactSelectors.incidentsFilterParams);

    const [params, setParams] = useState<FilterParams>({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
        data: {}
    });

    const [isOpenAddModal, setOpenAddModal] = useState(false);

    const [urlParams, setUrlParams] = useState<Record<string, number | null>>({});
    const [selectedItem, setSelectedItem] = useState<Incident | null>(null);

    const onClickItem = (item: Incident) => {
        setUrlParams(prev => ({ ...prev, id: item.id === selectedItem?.id ? null : item.id }));
    };

    const onChangePage = (page: number, limit?: number) => {
        setParams(prevParams => ({ ...prevParams, page }));
        setUrlParams({ page, limit: limit || null, id: null });
    };

    const prepareIncedentsData = (params: FilterParams) => {
        return {
            page: params.page,
            limit: params.limit,
            data: removeEmptyFields({
                ...params.data,
                status: params.data.status?.map((el: any) => el.id),
                organizations: params.data?.organizations?.map((el: any) => el.id),
                incident_types: params.data?.incident_types?.map((el: any) => el.id),
                threat_level: params.data?.threat_level?.map((el: any) => el.id),
                response_scenario_id: params.data?.response_scenario_id?.map((el: any) => el.id),
                start_date: fullDateTimeWithTimeZone(params.data?.start_date),
                end_date: fullDateTimeWithTimeZone(params.data?.end_date),
                incident_date_start: fullDateTimeWithTimeZone(params.data?.incident_date_start),
                incident_date_end: fullDateTimeWithTimeZone(params.data?.incident_date_end),
                plan_date_start: fullDateTimeWithTimeZone(params.data?.plan_date_start),
                plan_date_end: fullDateTimeWithTimeZone(params.data?.plan_date_end),
                fact_date_start: fullDateTimeWithTimeZone(params.data?.fact_date_start),
                fact_date_end: fullDateTimeWithTimeZone(params.data?.fact_date_end),
            })
        };
    };

    useEffect(() => {
        //При изменении urlParams?.id устанавливаем его в setSelectedItem
        if (data?.length > 0) {
            const id  = urlParams?.id;
            const item = id ? data.find((item: Incident) => item.id === Number(id)) : null;
            setSelectedItem(item || null);
        }
    }, [data, urlParams?.id]);

    useEffect(() => {
        const preparedData = prepareIncedentsData(params);

        dispatch(loadIncidents(preparedData.page, preparedData.limit, preparedData.data));
    }, [dispatch, params]);

    const reloadList = (isDelete?: IncidentItem) => {
        const page = !!isDelete && data.length === 1 && meta?.last_page > 1
            ? params.page - 1
            : params.page;

        setUrlParams({ page, limit: params.limit || null, id: selectedItem?.id || null });

        const preparedData = prepareIncedentsData({
            page: page,
            limit: params.limit,
            data: params.data,
        });

        dispatch(loadIncidents(preparedData.page, preparedData.limit, preparedData.data));
    };

    const renderList = () => (
        <LSContainer
            onSort={(sort) => setParams({ ...params, data: { ...params.data, sort } })}
            isMobile={!!selectedItem?.id}
            headers={[
                { title: '', width: '50px' },
                { title: 'id', width: '6%' },
                { title: titles.NAME, width: '12%' },
                {
                    title: 'Дата/время регистрации',
                    width: '12%',
                    options: { sortable: true, field: 'created_at' }
                },
                { title: 'Дата/время решения инцидента план', width: '12%' },
                { title: 'Дата/время решения инцидента факт', width: '12%' },
                { title: titles.ADDRESS, width: '18%' },
                { title: titles.TYPE, width: '12%' },
                { title: 'Уровень угрозы', width: 'calc(16% - 50px)'  }
            ]}
        >
            {data.map((item: any) => (
                <Item
                    key={item.id}
                    item={item}
                    onClickItem={() => onClickItem(item)}
                    selectedId={selectedItem?.id || null}
                />
            ))}
        </LSContainer>
    );



    return (
        <>
            <PageLayout
                header="Инциденты"
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        accessProp: '',
                                        onClick: () => setOpenAddModal(true)
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),
                    total: meta?.total
                }}
                loading={loading}
                content={() => (
                    data?.length > 0
                        ? <RenderPassportTabs
                            selectedItem={selectedItem}
                            queryKey="id"
                            urlParams={urlParams}
                            setUrlParams={setUrlParams}
                            setParams={setParams as any}
                            renderList={renderList}
                            reloadList={reloadList}
                        />
                        : !loading && <NoData />
                )}
                paginationProps={{
                    loadList: onChangePage,
                    list: meta,
                    limit: params.limit,
                    setLimit: (limit) => setParams(prev => ({ ...prev, limit })),
                }}
                customStyles={{ ...(!!selectedItem?.id && { padding: 0 }) }}
            />
            {isOpenAddModal && (
                <AddModal
                    isOpen={isOpenAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew={true}
                    reloadList={reloadList}
                />
            )}
        </>
    );
};

export default Incidents;
