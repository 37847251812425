import { config } from 'config';

const apiDtpUrl = () => config.get('urls').apiDtpUrl;

const apiUrls = {

    getCountByMonths: () => `${apiDtpUrl()}/v2/dtp/count-by-months`,

    getCountByPeriods: () => `${apiDtpUrl()}/v2/dtp/count-by-periods`,

};

export default apiUrls;
