import { useMemo } from 'react';

import * as actions from 'redux/RoadNetwork/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import renderAddress from 'helpers/renderAddress';
import { getHumanDate } from 'helpers/date.config';
import Information from 'components/common/Information';
import RoadSectionRelationList from 'components/common/RoadSectionRelationList';
import MapScroll from 'components/MapComponents/MapScroll';
import { createIcon as createIconTitle, MapPopUp }  from 'components/MapComponents/MapPopUp';

import iconSvg from '../icons/icon';
import { getColorItem } from '../helper';
import config from '../config';


const PopUpModal = ({
    isOpen,
    data,
    onClose,
}) => {

    const types = useStoreProp(actions.loadRoadSectionTypes, 'roadNetworks', 'roadSectionTypes');
    const categories = useStoreProp(actions.loadRoadSectionCategories, 'roadNetworks', 'roadSectionCategories');
    const statuses = useStoreProp(actions.loadStatusesRoadSection, 'roadNetworks', 'statusesRoadSection');

    // статус объекта
    const currentStatus = useMemo(() => statuses
        ?.find(({ id }) => id === data?.status)
        || {}
    , [statuses, data?.status]);

    // выводимые поля
    const fieldsObj = {
        name: {
            title: titles.NAME,
            value: ({ name }) => name || messages.INFO_IS_NOT_FOUND,
        },
        status: {
            title: titles.STATUS,
            value: () => currentStatus?.name || messages.INFO_IS_NOT_FOUND,
        },
        category: {
            title: titles.CATEGORY,
            value: ({ category }) => categories[category] || messages.INFO_IS_NOT_FOUND,
        },
        type: {
            title: titles.TYPE,
            value: ({ type }) => types[type],
        },
        organization: {
            title: titles.ORGANIZATION,
            value: ({ organization }) => organization?.title,
        },
        address: {
            title: 'Адрес дислокации',
            value: ({ address }) => renderAddress(address),
        },
        created_at: {
            title: 'Дата создания',
            value: ({ created_at }) => getHumanDate(created_at),
        },
        fixated_at: {
            title: 'Дата регистрации',
            value: ({ fixated_at }) => getHumanDate(fixated_at),
        },
        description: {
            title: 'Описание',
            value: ({ description }) => description,
        },
        // перечень присвоенных объектов с указанием подзаголовков (типов объектов)
    };

    const {
        name = '',
        category,
    } = data || {};

    const icon = useMemo(() => {
        switch (parseInt(category)) {
            // Перекресток
            case 2: return createIconTitle('far fa-dot-circle');
            // Точка технического присоединения
            case 3: return <span className="inner-icon" dangerouslySetInnerHTML={{ __html: iconSvg(getColorItem(data)) }}/>;
            // остальное (если будет)
            default: return createIconTitle(config.layerIcon);
        }
    }, []);

    return (
        <MapPopUp
            isOpen={isOpen}
            icon={icon}
            title={name || ''}
            onClose={onClose}
            titleBackgroundColor={getColorItem(data)}
            medium
        >
            <MapScroll>
                <Information
                    title={fieldsObj}
                    data={data}
                    returnNull
                    defaultValue={messages.INFO_IS_NOT_FOUND}
                />
                <RoadSectionRelationList roadSectionId={data?.id} isMap/>
            </MapScroll>
        </MapPopUp>
    );
};

export default PopUpModal;
