import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
    Autocomplete, 
    Checkbox, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField 
} from '@mui/material';

import { 
    createMaterialExportGroup, 
    editMaterialExportGroup, 
    loadMaterialExportGroupProtocols, 
    loadMaterialExportGroupTypes 
} from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import { useStoreProp, useValidation } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import SelectComplex from 'components/common/Autocomplete/DorisControl/Complex';

const ModalForm = ({ isOpen, onClose, item, isNew, loadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const types = useStoreProp(loadMaterialExportGroupTypes, 'dorisControl', 'materialExportGroupTypes');
    const protocols = useStoreProp(loadMaterialExportGroupProtocols, 'dorisControl', 'materialExportGroupProtocols');

    const initState = {
        name: item?.name || '',
        destination: item?.destination || '',
        types: item?.types || [],
        protocol: item?.protocol || '',
        complexes: item?.complexes || [],
        complex_id_list: item?.complex_id_list || [],
        enable: item?.enable || false,
        is_url_wsdl: item?.is_url_wsdl || false,
        with_empty_grz: item?.with_empty_grz || false
    };

    const [data, setData] = useState(initState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
        validation.deleteKey(name);
    };

    const autoCompleteChange = (e, types) => {
        if (e?.key !== 'Enter') {
            setData({ ...data, types });
            validation.deleteKey('types');
        }
    };

    const handleSave = () => {
        const prepareData = {
            ...data,
            complex_id_list: data.complexes.map(({ id }) => Number(id)),
            types: data.types.map(id => Number(id)),
            protocol: Number(data.protocol)
        };

        const callback = () => {
            onClose();
            loadList();
        };

        isNew
            ? dispatch(createMaterialExportGroup(prepareData, callback))
            : dispatch(editMaterialExportGroup(item.id, prepareData, callback));
    };

    const isDisabled = !data.name
        || !data.destination
        || !data.types.length
        || !data.protocol
        || !data.complexes.length;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <TextField
                    label={titles.NAME}
                    className="block"
                    variant="outlined"
                    size="small"
                    required
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                    error={!data.name || validation.isKey('name')}
                    helperText={validation.get('name')}
                />

                <TextField
                    label={'Назначение'}
                    className="block"
                    variant="outlined"
                    required
                    size="small"
                    value={data.destination}
                    name="destination"
                    onChange={handleChange}
                    error={!data.destination || validation.isKey('destination')}
                    helperText={validation.get('destination')}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.is_url_wsdl}
                            onChange={({ target: { checked } }) => setData({ ...data, is_url_wsdl: checked })}
                        />
                    }
                    label="Использовать WSDL"
                />

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.with_empty_grz}
                            onChange={({ target: { checked } }) => setData({ ...data, with_empty_grz: checked })}
                        />
                    }
                    label="Отправлять материалы с пустым ГРЗ"
                />

                <div className="block">
                    <Autocomplete
                        multiple
                        value={data.types}
                        noOptionsText={titles.NO_RESULTS}
                        isOptionEqualToValue={(option, value) => Number(option) === Number(value)}
                        options={Object.keys(types)}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => types[option] || option}
                        onChange={autoCompleteChange}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Тип экспорта"
                                size="small"
                                variant="outlined"
                                required
                                error={!data.types.length || validation.isKey('types')}
                                helperText={validation.get('types')}
                            />
                        )}
                    />
                </div>

                <FormControl 
                    size="small" 
                    variant="outlined" 
                    className="block"
                    required
                    error={!data.protocol}
                >
                    <InputLabel>Протокол</InputLabel>
                    <Select
                        value={data.protocol}
                        name="protocol"
                        onChange={handleChange}
                        label="Протокол"
                    >
                        {Object.keys(protocols).map((key) => (
                            <MenuItem key={key} value={key}>{protocols[key]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <div className="block">
                    <SelectComplex
                        selected={data.complexes}
                        onChange={(complexes) => setData({ ...data, complexes })}
                        multiple
                        required
                        error={!data.complexes.length || validation.isKey('complex_id_list')}
                        helperText={validation.get('complex_id_list')}
                    />
                </div>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={data.enable}
                            onChange={({ target: { checked } }) => setData({ ...data, enable: checked })}
                        />
                    }
                    label="Включить выгрузку"
                />
            </form>
        </Modal>
    );
};

export default ModalForm;
