import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import Information from '../../../../common/Information';
import { fullDateTimeWithoutSeconds } from '../../../../../helpers/date.config';
import messages from '../../../../../helpers/constants/messages';
import renderAddress from '../../../../../helpers/renderAddress';

const DtpTable = ({ list = [] }) => {

    const cols = {
        number: {
            title: '№',
        },
        id: {
            title: 'Id',
        },
        created_at: {
            title: 'Дата/время',
            value: ({ created_at }) => fullDateTimeWithoutSeconds(created_at),
        },
        dtp_type_name: {
            title: 'Тип',
        },
        dead: {
            title: 'Количество погибших',
            value: ({ dead }) => dead?.toString?.() || '0',
        },
        wounded: {
            title: 'Количество раненых',
            value: ({ wounded }) => wounded?.toString?.() || '0',
        },
        address_text: {
            title: 'Адрес',
            value: ({ address, address_text }) => address_text || Object.keys(address || {}).length > 0
                ? address_text || renderAddress(address)
                : 'Информация отсутствует'
        },
    };

    return (
        list?.length > 0
            ? (
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {Object.keys(cols).map(key => (
                                    <TableCell
                                        key={key}
                                        // align="center"
                                    >
                                        {cols[key].title || ''}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {list?.map((item, index) => (
                                <TableRow key={index}>
                                    <Information
                                        data={{
                                            ...item,
                                            number: index + 1,
                                        }}
                                        title={cols}
                                        wrapperTpl={(key, title, value) => <TableCell key={key}>{value}</TableCell>}
                                        titleTpl={() => ''}
                                        valueTpl={(value) => value}
                                        returnNull
                                    />
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
            : messages.NO_DATA
    );
};

export default DtpTable;
