import { useCallback } from 'react';
import cn from 'classnames';


import { convertDateToTimestamp, getStartOf, isDateValid } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

import FormInfoWrapper from '../../FormInfoWrapper';
import SingleKeyboardDateTimePicker from '../SingleKeyboardDateTimePicker';
import { customPattern } from '../helpers';

import styles from './styles.module.scss';

import type { DateRangeProps } from 'types/Dates';

const DateRange = ({
    views,
    valueStartDate,
    valueEndDate,
    handleDate,
    handleEndDate,
    startLabel = titles.START_PERIOD,
    endLabel = titles.END_PERIOD,
    isDisabledHandler = () => {},
    renderError = () => false, // функция, возвращающая boolean
    errorMessage = () => '',
    required = false,
    pickersFormat,
    disableStartField = false,
    disableEndField = false,
    dateOnly = false,
    timeOnly = false,
    disableFuture = false,
    className = '',
    isDisabledValidation = false,
    maxDate,
    minDate,
    maxTime,
    minTime,
    helperText = '', // если надо вывести сообщение
    equalDates = false,
    test_id_prefix = '',
    title = '',
}: DateRangeProps) => {

    const errorDateRange = useCallback((): boolean => {
        // находим минимальное значение до которого округляем дату - до минут или секунд или дней
        // в зависимости от переданных параметров views и pickersFormat
        const minValue = customPattern(views, pickersFormat, dateOnly, timeOnly).minValue;

        const start = getStartOf(minValue, valueStartDate);

        const end = getStartOf(minValue, valueEndDate);

        if (valueStartDate !== null && valueEndDate !== null) {
            return equalDates
                ? convertDateToTimestamp(start) > convertDateToTimestamp(end)
                : convertDateToTimestamp(start) >= convertDateToTimestamp(end);
        }
        return false;
    }, [valueStartDate, valueEndDate, views, dateOnly, timeOnly, pickersFormat, equalDates]);

    const isError = errorDateRange();
    const checkError = useCallback((date: Date | string | null): boolean => {
        const checkInvalidDate = (date: Date | string | null): boolean => {
            return date ? !isDateValid(date) : false;
        };
        return renderError() || isError || checkInvalidDate(date);
    }, [renderError, isError]);

    const error = !isDisabledValidation && (checkError(valueEndDate) || (required && !valueEndDate));

    const tooltipText = !isError && !renderError()
        ? helperText
        : !isDisabledValidation
            ? isError
                ? timeOnly ? messages.ERROR_TIME_RANGE : messages.ERROR_DATE_RANGE
                : renderError() ? errorMessage() : ''
            : ''
        ;

    return (
        <>  {title && <h2 style={{ 'gridColumn': '1 / 4' }}>{title}</h2>}
            <SingleKeyboardDateTimePicker
                views={views}
                label={startLabel}
                onChange={handleDate}
                value={valueStartDate}
                error={!isDisabledValidation && (checkError(valueEndDate))}
                pickerFormat={pickersFormat}
                required={required}
                isDisabled={disableStartField}
                dateOnly={dateOnly}
                timeOnly={timeOnly}
                disableFuture={disableFuture}
                minTime={minTime}
                maxTime={maxTime}
                minDate={minDate}
                maxDate={maxDate}
                className={cn(styles.date_range__item, className)}
                isDisabledHandler={isDisabledHandler}
                test_id_prefix={`${test_id_prefix}/period-start`}
            />
            {/* Wrap only the second component in FormInfoWrapper to prevent field misalignment */}
            <FormInfoWrapper error={error} helperText={tooltipText} className={cn(styles.date_range__item, className)}>
                <SingleKeyboardDateTimePicker
                    views={views}
                    label={endLabel}
                    onChange={handleEndDate}
                    value={valueEndDate}
                    error={!isDisabledValidation && (checkError(valueEndDate))}
                    pickerFormat={pickersFormat}
                    required={required}
                    isDisabled={disableEndField}
                    dateOnly={dateOnly}
                    timeOnly={timeOnly}
                    disableFuture={disableFuture}
                    minTime={minTime}
                    maxTime={maxTime}
                    minDate={minDate}
                    maxDate={maxDate}
                    isDisabledHandler={isDisabledHandler}
                    test_id_prefix={`${test_id_prefix}/period-end`}
                />
            </FormInfoWrapper>
        </>);
};

export default DateRange;
