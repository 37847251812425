import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { showMessage } from 'redux/Message/actions';
import { setValidation } from 'redux/Validation/actions';
import messageTypes from 'redux/Message/messagetypes';
import { setMapLoading } from 'redux/Map/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { messageHelper } from 'helpers/messageHelper';

import * as actions from './actions';
import * as types from './types';
import api from './api.methods';

function* loadTransportIncidentTypesSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingTransportIncidentTypes(true));
    const typesList = yield call(api.getTransportIncidentTypes, page, limit, params);
    if (typesList) {
        yield put(actions.loadedTransportIncidentTypes(typesList));
    }
    yield put(actions.loadingTransportIncidentTypes(false));
}

function* createTransportIncidentTypeSaga({ payload: data, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.createTransportIncidentType, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingButton(false));
}

function* editTransportIncidentTypeSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingButton(true));
    const response = yield call(api.editTransportIncidentType, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingButton(false));
}

function* deleteTransportIncidentTypeSaga({ payload: id, callback }) {
    yield put(actions.loadingButton(true));
    const response = yield call(api.deleteTransportIncidentType, id);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingButton(false));
}

function* loadEventsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingEvents(true));
    const eventsList = yield call(api.getEvents, page, limit, params);
    if (eventsList) {
        yield put(actions.loadedEvents(eventsList));
    }
    yield put(actions.loadingEvents(false));
}

function* createEventSaga({ payload: data }) {
    yield put(actions.loadingEvents(true));
    const response = yield call(api.createEvent, data);
    if (response?.success) {
        yield put(actions.loadEvents());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEvents(false));
}

function* editEventSaga({ payload }) {
    const { id, data } = payload;
    yield put(actions.loadingEvents(true));
    const response = yield call(api.editEvent, id, data);
    if (response?.success) {
        yield put(actions.loadEvents());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEvents(false));
}

function* deleteEventSaga({ payload: id }) {
    yield put(actions.loadingEvents(true));
    const response = yield call(api.deleteEvent, id);
    if (response?.success) {
        yield put(actions.loadEvents());
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEvents(false));
}

function* loadKeyWordsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingKeyWordList(true));
    const response = yield call(api.getKeywordList, page, limit, params);
    if (response) {
        yield put(actions.loadedKeyWordList(response));
    }
    yield put(actions.loadingKeyWordList(false));
}

function* editKeyWordSaga({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editKeyWord, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
        yield put(setValidation({ success: true }));
    }
}

function* addKeyWordSaga({ payload, callback }) {
    const response = yield call(api.addKeyWord, payload);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.SAVE_SUCCESS));
        yield put(setValidation({ success: true }));
    }
}

function* deleteKeyWordSaga({ payload: id, callback }) {
    const response = yield call(api.deleteKeyWord, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
}

//Получение списка угроз и рисков
function* loadDangersListSaga ({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingDangersList(true));
    const response = yield call(api.getDangersList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedDangersList(response));
    }
    yield put(actions.loadingDangersList(false));
}

//Создание сущности угроз и риска
function* createDangerSaga ({ payload, callback }) {
    const response = yield call(api.postDanger, payload.data);
    yield put(actions.setStatusDanger(response?.success));
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, 'Данные были успешно добавлены'));
        yield callback && callback();
    }
}

//Редактирование сущности угроз и риска
function* editDangerSaga ({ payload, callback }) {
    const { id, data } = payload;
    const response = yield call(api.editDanger, id, data);
    yield put(actions.setStatusDanger(response?.success));
    if (response?.success) {
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, 'Данные были успешно добавлены'));
        yield callback && callback();
    }
}

//Удаление сущности угроз и риска
function* deleteDangerSaga ({ payload, callback }) {
    const response = yield call(api.deleteDanger, payload);
    if (response?.success) {
        yield callback && callback();
    }
}

function* loadThreatLevelsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingThreatLevels(true));
    const threatLevelsList = yield call(api.getThreatLevels, page, limit, params);
    if (threatLevelsList) {
        yield put(actions.loadedThreatLevels(threatLevelsList));
    }
    yield put(actions.loadingThreatLevels(false));
}

function* createThreatLevelSaga({ payload: data }) {
    yield put(actions.loadingThreatLevels(true));
    const response = yield call(api.createThreatLevel, data);
    if (response?.success) {
        yield put(actions.loadThreatLevels());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingThreatLevels(false));
}

function* editThreatLevelSaga({ payload }) {
    const { id, data } = payload;
    yield put(actions.loadingThreatLevels(true));
    const response = yield call(api.editThreatLevel, id, data);
    if (response?.success) {
        yield put(actions.loadThreatLevels());
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingThreatLevels(false));
}

function* deleteThreatLevelSaga({ payload: id }) {
    yield put(actions.loadingThreatLevels(true));
    const response = yield call(api.deleteThreatLevel, id);
    if (response?.success) {
        yield put(actions.loadThreatLevels());
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingThreatLevels(false));
}

function* loadTypicalOperationsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingTypicalOperations(true));
    const typicalOperationsList = yield call(api.getTypicalOperations, page, limit, params);
    if (typicalOperationsList) {
        yield put(actions.loadedTypicalOperations(typicalOperationsList));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* loadIncidentOperationsSaga({ payload }) {
    yield put(actions.loadingIncidentOperations(true));
    const operationsList = yield call(api.getIncidentOperations, payload);
    if (operationsList) {
        yield put(actions.loadedIncidentOperations({ [payload]: operationsList?.data }));
    }
    yield put(actions.loadingIncidentOperations(false));
}

function* editIncidentOperationsSaga({ payload, callback }) {
    const { id, data } = payload;
    const operationsList = yield call(api.editIncidentOperations, id, data);
    if (operationsList) {
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
        callback();
    }
}

function* createTypicalOperationSaga({ payload: data, callback }) {
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.createTypicalOperation, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* editTypicalOperationSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.editTypicalOperation, id, data);
    if (response?.success) {
        yield callback && callback();
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* deleteTypicalOperationSaga({ payload: id, callback }) {
    yield put(actions.loadingTypicalOperations(true));
    const response = yield call(api.deleteTypicalOperation, id);
    if (response?.success) {
        yield callback && callback();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingTypicalOperations(false));
}

function* loadResponseScenariosSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingResponseScenarios(true));
    const responseScenariosList = yield call(api.getResponseScenarios, page, limit, params);
    if (responseScenariosList) {
        yield put(actions.loadedResponseScenarios(responseScenariosList));
    }
    yield put(actions.loadingResponseScenarios(false));
}

function* createResponseScenarioSaga({ payload: data, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.createResponseScenario, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }

    yield put(actions.loadingButton(false));
}

function* editResponseScenarioSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));

    const response = yield call(api.editResponseScenario, id, data);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.EDIT_SUCCESS));
    }

    yield put(actions.loadingButton(false));
}

function* deleteResponseScenarioSaga({ payload: id, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.deleteResponseScenario, id);
    if (response?.success) {
        yield callback?.();
        yield put(showMessage(messageTypes.success, messages.DELETE_SUCCESS));
    }
    yield put(actions.loadingButton(false));
}

function* loadEventObjectsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingEventObjects(true));
    const eventObjectsList = yield call(api.getEventObjects, page, limit, params);
    if (eventObjectsList) {
        yield put(actions.loadedEventObjects(eventObjectsList));
    }
    yield put(actions.loadingEventObjects(false));
}

function* createEventObjectsSaga({ payload: data, callback }) {
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.createEventObjects, data);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEventObjects(false));
    yield put(actions.setSavedEvents(response?.success || false));
}

function* editEventObjectsSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.editEventObjects, id, data);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEventObjects(false));
    yield put(actions.setSavedEvents(response?.success || false));
}

function* deleteEventObjectsSaga({ payload: id, callback }) {
    yield put(actions.loadingEventObjects(true));
    const response = yield call(api.deleteEventObjects, id);
    if (response?.success) {
        yield callback();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingEventObjects(false));
    yield put(actions.setSavedEvents(response?.success || false));
}

function* loadEventObjectsTypesSaga() {
    const typesList = yield call(api.getEventObjectsTypes);
    if (typesList) {
        yield put(actions.loadedEventObjectsTypes(typesList.data));
    }
}

function* loadCurrentEventSaga({ payload: id }) {
    const currentEvent = yield call(api.getCurrentEvent, id);
    yield put(actions.loadingCurrentEvent(true));
    if (currentEvent) {
        yield put(actions.loadedCurrentEvent(currentEvent.data));
    }
    yield put(actions.loadingCurrentEvent(false));
}

function* loadCurrentKeywordSaga({ payload: id }) {
    const currentKeyword = yield call(api.getCurrentKeyword, id);
    if (currentKeyword) {
        yield put(actions.loadedCurrentKeyword(currentKeyword.data));
    }
}

// Получение полигона
function* loadForPolygonSaga({ payload: params }) {
    yield put (actions.loadingPolygon(true));
    yield put (setMapLoading('sp_polygon', true));
    const response = yield call(api.getForPolygon, params);
    if (response) {
        yield put(actions.saveForPolygon(response));
    }
    yield put (actions.loadingPolygon(false));
    yield put (setMapLoading('sp_polygon', false));
}

// Получение списка для сайдбара
function* loadSideBarSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebarList, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadTransportIncidentsSaga({ payload }) {
    const { page, limit, params } = payload;
    yield put(actions.loadingTransportIncidents(true));
    const transportIncidentsList = yield call(api.getTransportIncidents, page, limit, params);
    if (transportIncidentsList) {
        yield put(actions.loadedTransportIncidents(transportIncidentsList));
    }
    yield put(actions.loadingTransportIncidents(false));
}

function* createTransportIncidentSaga({ payload: data, callback = () => {} }) {
    yield put(actions.loadingTransportIncidents(true));
    const response = yield call(api.createTransportIncident, data);
    if (response?.success) {
        yield put(actions.saveStatus(response?.success || false));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
        yield callback(response.data);
    }
    yield put(actions.loadingTransportIncidents(false));
}

function* editTransportIncidentSaga({ payload, callback = () => {} }) {
    const { id, data } = payload;
    yield put(actions.loadingTransportIncidents(true));
    const response = yield call(api.editTransportIncident, id, data);
    if (response?.success) {
        yield put(actions.saveStatus(response?.success || false));
        yield put(actions.loadIncidentOperations(id));
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
        yield callback();
    }
    yield put(actions.loadingTransportIncidents(false));
}

function* deleteTransportIncidentSaga({ payload: id, callback }) {
    yield put(actions.loadingTransportIncidents(true));
    const response = yield call(api.deleteTransportIncident, id);
    if (response?.success) {
        yield put(actions.saveStatus(response?.success || false));
        callback?.();
        yield put(showMessage(messageTypes.success, titles.DATA_SAVED_SUCCESSFULLY));
    }
    yield put(actions.loadingTransportIncidents(false));
}

function* loadTransportIncidentStatusesSaga() {
    const statuses = yield call(api.getTransportIncidentStatuses);
    if (statuses) {
        yield put(actions.loadedTransportIncidentStatuses(statuses.data));
    }
}

function* loadCurrentCategorySaga({ payload: id }) {
    const currentCategory = yield call(api.getCurrentCategory, id);
    if (currentCategory) {
        yield put(actions.loadedCurrentCategory(currentCategory.data));
    }
}

function* loadCurrentResponseScenarioSaga({ payload: id }) {
    const currentResponseScenario = yield call(api.getCurrentResponseScenario, id);
    yield put(actions.loadingCurrentResponseScenario(true));
    if (currentResponseScenario) {
        yield put(actions.loadedCurrentResponseScenario(currentResponseScenario.data));
    }
    yield put(actions.loadingCurrentResponseScenario(false));
}

function* loadCurrentTransportIncidentTypeSaga({ payload: id }) {
    const currentTransportIncidentType = yield call(api.getCurrentTransportIncidentType, id);
    yield put(actions.loadingCurrentTransportIncidentType(true));
    if (currentTransportIncidentType) {
        yield put(actions.loadedCurrentTransportIncidentType(currentTransportIncidentType.data));
    }
    yield put(actions.loadingCurrentTransportIncidentType(false));
}

function* loadCurrentThreatLevelSaga({ payload: id }) {
    const currentThreatLevel = yield call(api.getCurrentThreatLevel, id);
    yield put(actions.loadingCurrentThreatLevel(true));
    if (currentThreatLevel) {
        yield put(actions.loadedCurrentThreatLevel(currentThreatLevel.data));
    }
    yield put(actions.loadingCurrentThreatLevel(false));
}

function* loadCurrentEventObjectSaga({ payload: id }) {
    const currentEventObject = yield call(api.getCurrentEventObject, id);
    if (currentEventObject) {
        yield put(actions.loadedCurrentEventObject(currentEventObject.data));
    }
}

function* loadArrayOfKeywordsSaga({ payload }) {
    const { page, limit, params } = payload;
    const arrayOfKeywords = yield call(api.getArrayOfKeyword, page, limit, params);
    if (arrayOfKeywords) {
        yield put(actions.loadedArrayOfKeywords(arrayOfKeywords.data));
        // yield put(loadedCurrentKeyword(arrayOfKeywords.data.map( el => [el.id]: el )));
    }
}

function* loadReportTransportIncidentsSaga({ payload }) {
    const { page, limit, params } = payload;
    const reportTransportIncident = yield call(api.getReportTransportIncidents, { page, limit, ...params });
    if (reportTransportIncident?.success) {
        yield put(actions.loadedReportTransportIncidents(reportTransportIncident));
    }
}

function* getReportTransportIncidentsSaga({ payload }) {
    const response = yield call(api.getReportTransportIncidents, payload);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* loadReportTransportIncidentsResolutionTimeSaga({ payload }) {
    yield put(actions.loadingReportTransportIncidentsResolutionTime(true));
    const config = { params: payload };
    const reportTransportIncidentResolutionTime = yield call(api.getReportTransportIncidentsResolutionTime, config);

    if (reportTransportIncidentResolutionTime?.success) {
        yield put(actions.loadedReportTransportIncidentsResolutionTime(reportTransportIncidentResolutionTime));
    }
    yield put(actions.loadingReportTransportIncidentsResolutionTime(false));
}

function* getReportTransportIncidentsResolutionTimeSaga({ payload }) {
    const config = { params: { ...payload } };
    const response = yield call(api.getReportTransportIncidentsResolutionTime, config);
    if (response?.success) {
        yield messageHelper(response);
    }
}

function* loadForPolygonEventsSaga({ payload: params }) {
    yield put(actions.loadingPolygonEvents(true));
    yield put(setMapLoading('sp_events', true));
    const response = yield call(api.getForPolygonEvents, params);

    if (response) {
        yield put(actions.saveForPolygonEvents(response));
    }
    yield put(actions.loadingPolygonEvents(false));
    yield put(setMapLoading('sp_events', false));
}

function* loadSideBarEventsSaga({ payload }) {
    const { page, limit, query } = payload;
    yield put(actions.loadingSidebarEvents(true));
    const response = yield call(api.getEventObjects, page, limit, query);
    if (response?.success) {
        yield put(actions.loadedSidebarEvents(response, page === 1));
    }
    yield put(actions.loadingSidebarEvents(false));
}

function* loadTransportIncidentHistorySaga({ payload }) {
    const { page, limit, id } = payload;
    yield put(actions.loadingTransportIncidentHistory(true));
    const response = yield call(api.getTransportIncidentHistory, page, limit, id);
    if (response) {
        yield put(actions.loadedTransportIncidentHistory(response));
    }
    yield put(actions.loadingTransportIncidentHistory(false));
}

function* loadTransportIncidentTypeCreationSaga() {
    const response = yield call(api.getTransportIncidentTypeCreation);
    if (response) {
        yield put(actions.loadedTransportIncidentTypeCreation(response?.data));
    }
}

function* loadTransportIncidentSaga({ payload, callback }) {
    const { id } = payload;
    yield put(actions.loadingTransportIncident(true));
    const transportIncident = yield call(api.getTransportIncident, id);
    if (transportIncident) {
        if (callback) {
            callback(transportIncident.data);
        } else {
            yield put(actions.loadedTransportIncident(transportIncident));
        }
    } else {
        callback?.(null);
        yield showMessage(messageTypes.error, messages.INFO_NOT_AVAILABLE);
    }
    yield put(actions.loadingTransportIncident(false));
}

function* loadInfopanelSaga({ payload }) {
    yield put(actions.loadingInfopanel(true));
    const request = yield call(api.loadInfopanel, payload);
    if (request) {
        yield put(actions.loadedInfopanel(request));
    }
    yield put(actions.loadingInfopanel(false));
}

function* loadDistictsByMonthSaga({ payload }) {
    yield put(actions.loadingDistrictsByMonth(true));
    const request = yield call(api.loadDistictsByMonth, payload);
    if (request) {
        yield put(actions.loadedDistrictsByMonth(request.data || {}));
    }
    yield put(actions.loadingDistrictsByMonth(false));
}

function* loadTransportIncidentModalSaga({ payload }) {
    const { id } = payload;
    yield put(actions.loadingTransportIncidentModal(true));
    const transportIncident = yield call(api.getTransportIncidentModal, id);
    if (transportIncident) {
        yield put(actions.loadedTransportIncidentModal(transportIncident.data));
    }
    yield put(actions.loadingTransportIncidentModal(false));
}

function* editSettingsListSaga({ payload, callback }) {
    yield put(actions.loadingButton(true));

    const response = yield call(api.editSettingsList, payload);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }

    yield put(actions.loadingButton(false));
}


function* loadSettingsGroupSaga() {
    yield put(actions.loadingSettingsGroup(true));
    const response = yield call(api.getSettingsGroup);
    if (response?.success) {
        yield put(actions.loadedSettingsGroup(response));
    }
    yield put(actions.loadingSettingsGroup(false));
}

function* editSettingsGroupSaga({ payload, callback }) {
    const { id, data } = payload;

    yield put(actions.loadingButton(true));
    const response = yield call(api.editSettingsGroup, id, data);
    if (response?.success) {
        yield callback();
        yield showMessage(messageTypes.success, messages.EDIT_SUCCESS);
    }
    yield put(actions.loadingButton(false));
}

function* loadShowcaseSaga() {
    yield put(actions.loadingShowcase(true));
    const response = yield call(api.loadShowcase);
    if (response?.success) {
        yield put(actions.loadedShowcase(response.data));
    }
    yield put(actions.loadingShowcase(false));
}

function* loadImagesLegendsSaga() {
    const response = yield call(api.loadImagesLegendsSaga);
    if (response?.success) {
        yield put(actions.loadedImagesLegends(response?.data || {}));
    }
}

function* loadReportByEventPlansSaga({ payload }) {
    yield put(actions.loadingReportByEventPlans(true));
    const response = yield call(api.loadReportByEventPlans, payload);
    if (response?.success) {
        yield put(actions.loadedReportByEventPlans(response));
    }
    yield put(actions.loadingReportByEventPlans(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_TRANSPORT_INCIDENT_TYPES, loadTransportIncidentTypesSaga),
        takeLatest(types.CREATE_TRANSPORT_INCIDENT_TYPE, createTransportIncidentTypeSaga),
        takeLatest(types.EDIT_TRANSPORT_INCIDENT_TYPE, editTransportIncidentTypeSaga),
        takeLatest(types.DELETE_TRANSPORT_INCIDENT_TYPE, deleteTransportIncidentTypeSaga),
        takeLatest(types.LOAD_EVENTS, loadEventsSaga),
        takeLatest(types.CREATE_EVENT, createEventSaga),
        takeLatest(types.EDIT_EVENT, editEventSaga),
        takeLatest(types.DELETE_EVENT, deleteEventSaga),
        takeLatest(types.LOAD_KEY_WORD_LIST, loadKeyWordsSaga),
        takeLatest(types.EDIT_KEY_WORD, editKeyWordSaga),
        takeLatest(types.DELETE_KEY_WORD, deleteKeyWordSaga),
        takeLatest(types.ADD_KEY_WORD, addKeyWordSaga),
        takeLatest(types.LOAD_DANGERS_LIST, loadDangersListSaga),
        takeLatest(types.CREATE_DANGER, createDangerSaga),
        takeLatest(types.EDIT_DANGER, editDangerSaga),
        takeLatest(types.DELETE_DANGER, deleteDangerSaga),
        takeLatest(types.LOAD_THREAT_LEVELS, loadThreatLevelsSaga),
        takeLatest(types.CREATE_THREAT_LEVEL, createThreatLevelSaga),
        takeLatest(types.EDIT_THREAT_LEVEL, editThreatLevelSaga),
        takeLatest(types.DELETE_THREAT_LEVEL, deleteThreatLevelSaga),
        takeLatest(types.LOAD_TYPICAL_OPERATIONS, loadTypicalOperationsSaga),
        takeLatest(types.LOAD_INCIDENT_OPERATIONS, loadIncidentOperationsSaga),
        takeLatest(types.EDIT_INCIDENT_OPERATIONS, editIncidentOperationsSaga),
        takeLatest(types.CREATE_TYPICAL_OPERATION, createTypicalOperationSaga),
        takeLatest(types.EDIT_TYPICAL_OPERATION, editTypicalOperationSaga),
        takeLatest(types.DELETE_TYPICAL_OPERATION, deleteTypicalOperationSaga),
        takeLatest(types.LOAD_RESPONSE_SCENARIOS, loadResponseScenariosSaga),
        takeLatest(types.CREATE_RESPONSE_SCENARIO, createResponseScenarioSaga),
        takeLatest(types.EDIT_RESPONSE_SCENARIO, editResponseScenarioSaga),
        takeLatest(types.DELETE_RESPONSE_SCENARIO, deleteResponseScenarioSaga),
        takeLatest(types.LOAD_EVENT_OBJECTS, loadEventObjectsSaga),
        takeLatest(types.CREATE_EVENT_OBJECTS, createEventObjectsSaga),
        takeLatest(types.EDIT_EVENT_OBJECTS, editEventObjectsSaga),
        takeLatest(types.DELETE_EVENT_OBJECTS, deleteEventObjectsSaga),
        takeLatest(types.LOAD_EVENT_OBJECTS_TYPES, loadEventObjectsTypesSaga),
        takeEvery(types.LOAD_CURRENT_EVENT, loadCurrentEventSaga),
        takeEvery(types.LOAD_CURRENT_KEYWORD, loadCurrentKeywordSaga),
        takeLatest(types.LOAD_TRANSPORT_INCIDENTS, loadTransportIncidentsSaga),
        takeLatest(types.LOAD_TRANSPORT_INCIDENT, loadTransportIncidentSaga),
        takeLatest(types.CREATE_TRANSPORT_INCIDENT, createTransportIncidentSaga),
        takeLatest(types.EDIT_TRANSPORT_INCIDENT, editTransportIncidentSaga),
        takeLatest(types.DELETE_TRANSPORT_INCIDENT, deleteTransportIncidentSaga),
        takeLatest(types.LOAD_TRANSPORT_INCIDENT_STATUSES, loadTransportIncidentStatusesSaga),
        takeEvery(types.LOAD_CURRENT_RESPONSE_SCENARIO, loadCurrentResponseScenarioSaga),
        takeEvery(types.LOAD_CURRENT_TRANSPORT_INCIDENT_TYPE, loadCurrentTransportIncidentTypeSaga),
        takeEvery(types.LOAD_CURRENT_THREAT_LEVEL, loadCurrentThreatLevelSaga),
        takeEvery(types.LOAD_CURRENT_CATEGORY, loadCurrentCategorySaga),
        takeEvery(types.LOAD_CURRENT_EVENT_OBJECT, loadCurrentEventObjectSaga),
        takeLatest(types.LOAD_ARRAY_OF_KEYWORDS, loadArrayOfKeywordsSaga),
        takeLatest(types.LOAD_POLYGON, loadForPolygonSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSideBarSaga),
        takeLatest(types.LOAD_REPORT_TRANSPORT_INCIDENTS, loadReportTransportIncidentsSaga),
        takeLatest(types.GET_REPORT_TRANSPORT_INCIDENTS, getReportTransportIncidentsSaga),
        takeLatest(types.LOAD_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME, loadReportTransportIncidentsResolutionTimeSaga),
        takeLatest(types.GET_REPORT_TRANSPORT_INCIDENTS_RESOLUTION_TIME, getReportTransportIncidentsResolutionTimeSaga),
        /* события мероприятий */
        takeLatest(types.LOAD_SIDEBAR_EVENTS, loadSideBarEventsSaga),
        takeLatest(types.LOAD_POLYGON_EVENTS, loadForPolygonEventsSaga),
        takeLatest(types.LOAD_TRANSPORT_INCIDENT_HISTORY, loadTransportIncidentHistorySaga),
        takeLatest(types.LOAD_TRANSPORT_INCIDENT_TYPE_CREATION, loadTransportIncidentTypeCreationSaga),
        takeLatest(types.LOAD_INFOPANEL, loadInfopanelSaga),
        takeLatest(types.LOAD_DISTRICTS_BY_MONTH, loadDistictsByMonthSaga),

        takeLatest(types.LOAD_TRANSPORT_INCIDENT_MODAL, loadTransportIncidentModalSaga),

        takeLatest(types.EDIT_SETTINGS_LIST, editSettingsListSaga),

        takeLatest(types.LOAD_SETTINGS_GROUP, loadSettingsGroupSaga),
        takeLatest(types.EDIT_SETTINGS_GROUP, editSettingsGroupSaga),

        takeLatest(types.LOAD_SHOWCASE, loadShowcaseSaga),

        takeLatest(types.LOAD_IMAGES_LEGENDS, loadImagesLegendsSaga),

        takeLatest(types.LOAD_REPORT_BY_EVENT_PLANS, loadReportByEventPlansSaga),
    ]);
}
