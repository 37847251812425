import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import L from 'leaflet';

import { setRoadWorksWS } from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import * as actions from 'redux/RoadWorks/actions';
import mapHelper from 'helpers/mapHelper';
import { usePrevious } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import { useStoreFromSelector } from 'helpers/hooks';
import colorExtend from 'helpers/mapHelper/colorExtend';
import {
    ContextMenuItem,
    GeoJson,
    Marker,
    ToolTip,
} from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';
import MapLegends from 'components/common/Transport/MapLegends';
import { MapPopUpListener }  from 'components/MapComponents/MapPopUp';

import LegendList from './LegendsList';
import CollectorForms from './CollectorForms';
import PopUpModal from './PopUpModal';
import config from './config.js';
import {
    createIconMarker,
    // isShowJson,
} from './helper';

const Layer = (props) => {
    const { map, readOnly = false, hideEmpty = false } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const statuses = useSelector(roadworksSelectors.statuses);
    const polygon = useSelector(roadworksSelectors.polygon);
    const activeRW = useSelector(roadworksSelectors.activeRW);
    const filters = useSelector(roadworksSelectors.filters);
    const loadingPolygon = useSelector(roadworksSelectors.loadingPolygon);

    // статусы с цветами
    const statusColors = useStoreFromSelector(
        actions.loadRoadWorksColors,
        roadworksSelectors.roadWorksColors
    );

    const showPopUp = useRef(false);
    const prevPolygonLoading = usePrevious(loadingPolygon) || false;
    // выбранный геообъект
    const [selectedItem, setSelectedItem] = useState(null);

    useWsSubscribe('road-work_road_work_update_model_v2', (events) => {
        dispatch(setRoadWorksWS(events));
    });

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getPolygon(map);
        dispatch(actions.getForPolygonRW(polygon, getFilters(filters)));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    useEffect(() => {
        if (loadingPolygon === false && prevPolygonLoading === true && showPopUp.current) {
            const id = showPopUp.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 200);
            showPopUp.current = false;
        }
    }, [loadingPolygon]);

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            // data: {
            //     type: 'Feature',
            //     geometry: {
            //         type: 'Point',
            //         coordinates: [
            //             lng, lat
            //         ],
            //     }
            // },
            lat,
            lon: lng,
        }));
    };

    useEffect(() => {
        if (Object.keys(statuses).length === 0) {
            dispatch(actions.getStatusesRW());
        }
    }, []);

    const handleClickMap = () => {
        setSelectedItem(null);
    };

    useEffect(() => {
        fetchPolygon();

        map
            .on('click', handleClickMap)
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(actions.setActiveRW());
            dispatch(actions.clearForPolygonRW());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('click', handleClickMap)
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters]);

    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(activeRW).length) {
            const { data } = activeRW;

            if (data && data?.geometry?.coordinates.length > 0) {
                showPopUp.current = activeRW.id;
                const b = L.geoJSON(data).getBounds();
                map.fitBounds(b);
            }

            setSelectedItem(activeRW?.id || null);
        }
    }, [activeRW]);

    // меню
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                {item.status !== 3 ? (
                    <ContextMenuItem
                        {...rcmProp}
                        value="Редактировать"
                        onClick={() => {
                            //map.fire('context_menu_close');
                            dispatch(actions.setEditForm(item));
                        }}
                    />
                ) : null}
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        //map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    // собираем
    const {
        markers,
        staticMarkers,
    } = useMemo(() => {
        return polygon?.reduce(({
            markers,
            staticMarkers,
        }, item) => {
            // маркер
            if (item?.data?.geometry?.type === 'Point' && !item?.data?.properties?.radius) {
                const {
                    data,
                    ...prs
                } = item;

                return {
                    markers,
                    staticMarkers: [
                        ...staticMarkers,
                        {
                            ...data,
                            properties: {
                                ...data.properties,
                                data: {
                                    ...prs,
                                    data
                                },
                                attribution: {
                                    slug: config.slug,
                                    color: colorExtend(prs.color)
                                },
                            },
                            style: {
                                color: colorExtend(prs.color),
                                weight: 7
                            }
                        }
                    ],
                };
            }

            // остальные геообъекты
            const json = L.geoJSON(item?.data);
            const { lat, lng } = json.getBounds().getCenter();

            return {
                staticMarkers,
                markers: [
                    ...markers,
                    {
                        id: item.id,
                        key: item.id,
                        icon: createIconMarker(item.color),
                        latlng: [lat, lng],
                        attribution: {
                            slug: config.slug,
                            color: item.color
                        },
                        data: item || {},
                    }
                ]
            };
        }, {
            markers: [],
            staticMarkers: [],
        });

    }, [polygon]);

    const handleClick = (item) => (e) => {
        // if (e.lat && e.lng) {
        //     props.map.setView([e.lat, e.lng], 17);
        // }

        setSelectedItem(item?.data?.id || null);
    };

    const geoItem = useMemo(() => {
        const item = polygon?.find(({ id }) => id === selectedItem);

        if (selectedItem && item) {
            const {
                data,
                ...props
            } = item ||  {};

            return {
                ...data,
                properties: {
                    ...data.properties,
                    data: {
                        ...props,
                        data
                    },
                    attribution: {
                        slug: config.slug,
                        color: colorExtend(props.color)
                    },
                },
                style: {
                    color: colorExtend(props.color),
                    weight: 7
                }
            };
        }

        return null;
    }, [selectedItem, polygon]);

    return (
        <>
            {/* маркеры */}
            <GeoJson
                {...props}
                data={staticMarkers}
                icon={({ color }) => createIconMarker(colorExtend(color))}
                contextMenuTemplate={readOnly === false ? (item, data) => <RenderContextMenu item={item} {...data} /> : null}
                toolTipTemplate={({ name }) => <div>{name}</div>}
                toolTipOptions={{
                    direction: 'top',
                    offset: [0, 0],
                    sticky: true,
                }}
                idPrefix={config.slug}
                onClick={(item) => {
                    setSelectedItem(null);
                    dispatch(actions.setActiveRW(item));
                }}
            />

            {/* геообъекты маркерами */}
            {markers?.map((item, index) => {
                if (selectedItem !== item?.id) {
                    return (
                        <Marker
                            {...props}
                            {...item}
                            key={index}
                            onClick={handleClick(item)}
                        >
                            <ToolTip
                                direction="top"
                                offset={[0, -40]}
                            >
                                <div>Кликните, чтобы отобразить объект</div>
                            </ToolTip>
                        </Marker>
                    );
                }

                return null;
            })}

            {/* кликнутый геообъект (линия, полигон, окружность)*/}
            {geoItem && (
                <GeoJson
                    {...props}
                    parent={map}
                    data={geoItem}
                    icon={({ color }) => createIconMarker(colorExtend(color))}
                    contextMenuTemplate={readOnly === false ? (item, data) => <RenderContextMenu item={item} {...data} /> : null}
                    toolTipTemplate={({ name }) => <div>{name}</div>}
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, 0],
                        sticky: true,
                    }}
                    idPrefix={config.slug}
                    centerAfter={true}
                    onClick={(item) => {
                        dispatch(actions.setActiveRW(item));
                    }}
                />
            )}

            {/* формы */}
            <CollectorForms />

            {/* popup */}
            <MapPopUpListener
                activeSelector={roadworksSelectors.activeRW}
                polygonSelector={roadworksSelectors.polygon}
            >
                <PopUpModal
                    statuses={statuses}
                    history={history}
                    hideEmpty={hideEmpty}
                    onClose={() => {
                        dispatch(actions.resetActiveRW());
                    }}
                />
            </MapPopUpListener>

            {/* отобразить легенду */}
            <MapLegends
                layer="roadworks"
                visibleRequired={readOnly}
                isVisible={statusColors?.length > 0}
            >
                <LegendList
                    category={statusColors}
                />
            </MapLegends>
        </>
    );
};

export default Layer;
