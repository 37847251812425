import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, LinearProgress, List } from '@mui/material';

import {
    createMultipleRoadSectionRelation,
    loadInfrastructureByRadius,
    loadRoadSectionAllRelation,
    loadRoadSectionByRadius,
    loadRoadSectionRelation,
} from 'redux/RoadNetwork/actions';

import { roadNetworksSelectors } from 'redux/RoadNetwork';
import { loadMeteoByRadius } from 'redux/Meteo/actions';
import { loadCamerasByRadius } from 'redux/VideoAnalytics/actions';
import { loadComplexByRadius } from 'redux/DorisControl/actions';
import { loadTrafficLightByRadius } from 'redux/TrafficLights/actions';
import { loadTrafficFlowByRadius } from 'redux/TrafficFlow/actions';
import { loadEcoByRadius } from 'redux/Ecology/actions';
import { loadVideoCamerasByRadius } from 'redux/VideoCameras/actions';
import messages from 'helpers/constants/messages';
import SimpleSelect from 'components/common/SimpleSelect';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';

import Map from './Map';
import RelationItem from './RelationItem';

export const RelationModal = ({ onClose, isOpen, relationTypes, roadSection }) => {
    const dispatch = useDispatch();

    const loadingRoadSectionRelation = useSelector(roadNetworksSelectors.loadingRoadSectionRelation);

    const params = {
        lat: roadSection.lat,
        lon: roadSection.lon,
        radius: 50000
    };

    const [data, setData] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [relations, setRelations] = useState([]);
    const [relationsMap, setRelationsMap] = useState([]);
    const [allRelations, setAllRelations] = useState([]);

    const filterData = (array, type) => {
        const allRelationsFilter = allRelations.filter(rel => (Number(rel.entity_type) === Number(type)) && rel).map(mapRel => mapRel.entity_id);
        const filteredData = array.filter(item => {
            if (type === '8' && item.id === roadSection.id ) {
                return null;
            } else if (!allRelationsFilter.includes(item.id)) {
                return item;
            } else return null;
        });
        setData(filteredData);
    };

    const loadRelationElements = (type) => {
        if (type === '1') {
            dispatch(loadInfrastructureByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '2') {
            dispatch(loadComplexByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '3') {
            dispatch(loadMeteoByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '4') {
            dispatch(loadEcoByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '5') {
            dispatch(loadTrafficLightByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '6') {
            dispatch(loadCamerasByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '7') {
            dispatch(loadTrafficFlowByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '8') {
            dispatch(loadRoadSectionByRadius(params, (arr) => filterData(arr, type)));
        } else if (type === '9') {
            dispatch(loadVideoCamerasByRadius(params, (arr) => filterData(arr, type)));
        }
    };

    const handleSelectChange = (value) => {
        setSelectedType(value);
        loadRelationElements(value.id);
    };

    const handleClick = (elem, type, isCreate) => {
        isCreate
            ? setRelations([
                ...relations,
                {
                    road_section_id: roadSection.id,
                    entity_type: type,
                    entity_id: elem.id,
                    entity_data: elem,
                }
            ])
            : setRelations(relations.filter(item => item.entity_id !== elem.entity_id && item));
    };

    const setMarkerColor = (elem, type) => {
        const filteredRelationList = relations?.filter(item => (item.entity_type === type && item.entity_id === elem.id));
        if (filteredRelationList.length > 0) {
            return {
                color: '#0000FF',
                onClick: () => handleClick(filteredRelationList[0], type, false)
            };
        }
        return {
            color: '#00BFFF',
            onClick: () => handleClick(elem, type, true)
        };
    };

    const savedRelations = (rels) => {
        setRelations(rels);
        setRelationsMap(rels);
    };

    useEffect(() => {
        if (roadSection?.id) {
            dispatch(loadRoadSectionAllRelation({ id: roadSection.id }, setAllRelations, savedRelations));
        } else {
            setAllRelations([]);
        }
    }, [dispatch]);

    const handleClose = () => {
        onClose();
        setAllRelations([]);
    };

    const createRelations = () => {
        const mapRelations = relations.map(item => ({
            entity_type: item.entity_type,
            entity_id: item.entity_id
        }));

        dispatch(createMultipleRoadSectionRelation(roadSection.id, mapRelations, handleClose));
    };

    const convertedRelationTypes = useMemo(() => {
        return Object.keys(relationTypes).map(key => ({ id: key, name: relationTypes[key] }));
    }, [relationTypes]);

    return (
        <Modal
            title={`Добавление связи для элемента ${roadSection?.name || ''} ${roadSection?.type_text || ''}`}
            onClose={handleClose}
            fullWidth
            noPadding
            isOpen={isOpen}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: handleClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: createRelations
                    }
                ]}
            />}
        >
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid item xs={8}>
                    <Map
                        data={data}
                        roadSection={roadSection}
                        markersTitle={relationTypes[selectedType.id]}
                        selectedType={selectedType.id}
                        setMarkerColor={setMarkerColor}
                        relationsMap={relationsMap}
                        relations={relations}
                        handleClick={handleClick}
                    />
                </Grid>
                <Grid item xs={4}>
                    <div className="block" style={{ paddingRight: 10, paddingTop: 10 }}>
                        <SimpleSelect
                            selected={selectedType}
                            options={convertedRelationTypes}
                            onChange={handleSelectChange}
                            label="Выберите тип объекта"
                            renderLabel={(option) => option?.name || ''}
                        />
                    </div>

                    <h2>Связанные объекты:</h2>

                    {loadingRoadSectionRelation
                        ? <LinearProgress/>
                        : (
                            <>
                                {relations?.length > 0
                                    ? <List className="list">
                                        {relations?.map((item, i) =>
                                            <RelationItem
                                                key={`${item.entity_id}_${item.entity_type}`}
                                                item={item}
                                                index={i}
                                                relationTypes={relationTypes}
                                                handleClick={handleClick}
                                            />
                                        )}
                                    </List>
                                    : (<p style={{ padding: '1rem' }}>{messages.NO_DATA}</p>)
                                }
                            </>
                        )
                    }

                </Grid>
            </Grid>
        </Modal>
    );

};
