import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiDtpMethods = {

    getCountByMonths: async (params) => {
        const response = await instance.post(apiUrls.getCountByMonths(), params);
        return response.data;
    },

    getCountByPeriods: async (params) => {
        const response = await instance.post(apiUrls.getCountByPeriods(), params);
        return response.data;
    },
};

export default ApiDtpMethods;
