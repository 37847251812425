import { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { Skeleton } from '@mui/lab';

import { deleteRoute } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import ConfirmModal from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import {
    ActionMore,
    ActionsButtons,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';

import ShowMapModal from './ShowMapModal';
import EditStatusModal from './EditStatusModal';
import AddEditCopyModal from './AddEditCopyModal';

function RouteItem({ data, typeTransportation, onClickItem, selectedId, reloadList, loading, focus, test_id_prefix, currentRoute }) {

    const dispatch = useDispatch();

    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalStatusOpen, setModalStatusOpen] = useState(false);
    const [modalIsNew, setModalIsNew] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [isCopy, setIsCopy] = useState(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [showMap, setShowMap] = useState(false);

    const handleOpenModalCopy = (e) => {
        e.stopPropagation();
        setModalIsNew(true);
        setIsCopy(true);
    };

    useEffect(() => {
        if (modalIsNew) {
            setModalOpen(true);
        }
    }, [modalIsNew]);

    const handleCloseStatusModal = () => {
        setModalStatusOpen(false);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalIsNew(false);
        setIsCopy(false);
    };

    const handleDelete = () => {
        dispatch(deleteRoute(data?.id, () => {
            reloadList();
            setIsOpenDeleteModal(false);
        }));
    };

    // const showOnMap = (e) => {
    //     e.stopPropagation();
    //     history.push({
    //         pathname: '/map',
    //         search: 'layers[]=routes',
    //     });
    //     dispatch(addActiveLayer('routes'));
    //     dispatch(setSelectedLayer('routes'));
    //     dispatch(setActive([data]));
    // };

    const isCanDelete = data?.is_can_be_deleted && (data.status === 1 || data.status === 2 || data.status === 4);

    return (
        <>
            <LSContentItem
                autoFocus={focus === data?.id ? true : false}
                disableGutters
                className="list_item"
                onClick={onClickItem}
                style={ selectedId ? { backgroundColor: '#d7d7d7' } : { backgroundColor: '' } }
            >
                <LSContentColumn>
                    {/* <Avatar sx={{ backgroundColor: currentCategory?.color }}>
                        <img
                            style={{ width: 30, height: 30, margin: 0 }}
                            src={currentCategory.image_with_work_order}
                            alt=""
                        />
                    </Avatar> */}
                </LSContentColumn>
                <LSContentColumn>
                    {/* <Typography variant="subtitle2">
                        <strong>{titles.ROUTE_NUM}: </strong><span>{data?.num || messages.INFO_IS_NOT_FOUND}</span>
                    </Typography> */}
                    {/* <Typography variant="body2">
                        <strong>{titles.ROUTE_NAME}: </strong><span>{data?.name}</span>
                    </Typography> */}
                    <Typography variant="body2">
                        <strong>{titles.ROUTE_TYPE}: </strong>
                        {loading
                            ? <Skeleton style={{ display: 'inline-block' }} width={52}/>
                            : <span>{data?.is_direct_text || currentRoute?.statusText || messages.NO_DATA}</span>
                        }
                    </Typography>
                    <Typography variant="body2">
                        <strong>{titles.STATUS}: </strong>
                        {loading
                            ? <Skeleton style={{ display: 'inline-block' }} width={103} />
                            : <span>{data?.statusText || currentRoute?.type_transportationText || messages.NO_DATA}</span>
                        }
                    </Typography>
                </LSContentColumn>
                <LSContentColumn align="right" isActions>
                    <ActionsButtons
                        test_id_prefix={test_id_prefix}
                        buttons={[
                            {
                                ...buttonsTypes.copyIcon,
                                onClick: handleOpenModalCopy,
                            },
                            {
                                ...buttonsTypes.mapIcon,
                                onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowMap(true);
                                },
                            },
                            ...(data.status === 1
                                // редактирование если в разработке
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setEditModal(true);
                                    },
                                }]
                                : []
                            ),
                            ...(isCanDelete
                                ? [{
                                    ...buttonsTypes.deleteIcon,
                                    onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setIsOpenDeleteModal(true);
                                    },
                                }]
                                : []
                            ),
                        ]}
                    />
                    <ActionMore
                        isOpen={selectedId}
                        onClick={onClickItem}
                        test_id_prefix={test_id_prefix}
                        isPassport
                    />
                </LSContentColumn>
            </LSContentItem>
            {modalOpen
                && <AddEditCopyModal
                    data={data}
                    isNew={modalIsNew}
                    isOpen={modalOpen}
                    onClose={handleCloseModal}
                    typeTransportation={typeTransportation}
                    isCopy={isCopy}
                    reloadList={reloadList}
                />
            }
            {modalStatusOpen
                && <EditStatusModal
                    data={data}
                    isOpen={modalStatusOpen}
                    onClose={handleCloseStatusModal}
                />
            }
            {editModal && (
                <AddEditCopyModal
                    data={data}
                    isOpen={editModal}
                    onClose={setEditModal}
                    typeTransportation={typeTransportation}
                    isNew={false}
                    reloadList={reloadList}
                />
            )}
            {showMap && (
                <ShowMapModal
                    isOpen={showMap}
                    onClose={() => setShowMap(false)}
                    route={data}
                />
            )}

            {isOpenDeleteModal && (
                <ConfirmModal
                    open={isOpenDeleteModal}
                    onClose={() => setIsOpenDeleteModal(false)}
                    onSuccess={handleDelete}
                    message={messages.CONFIRM_DELETE}
                    loadingButton={loadingButton}
                />
            )}
        </>
    );
}

export default RouteItem;
