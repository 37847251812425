import * as types from './types';

const initialState = {
    stationStatuses: [],

    stationList: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingStationList: false,

    stationSidebar: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingStationSidebar: false,

    active: {},
    filters: {},

    vestibulePolygon: [],
    loadingVestibulePolygon: false,

    vestibuleList: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingVestibuleList: false,

    vestibuleStatuses: [],

    sidebar: {
        data: [],
        meta: {
            last_page: 0,
            total: 0,
        }
    },
    sidebarLoading: false,

    activeVestibule: {},
    filtersVestibule: {},

    vestibuleLoad: [],
    loadingVestibuleLoad: false,
    vestibulePolygonLoading: false,

    vestibuleTraffic: [],
    loadingVestibuleTraffic: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_STATION_STATUSES: {
            return {
                ...state,
                stationStatuses: payload
            };
        }

        case types.LOADED_STATION_LIST: {
            return {
                ...state,
                stationList: payload
            };
        }

        case types.LOADING_STATION_LIST: {
            return {
                ...state,
                loadingStationList: payload
            };
        }

        case types.LOADED_STATION_SIDEBAR: {
            const { data, isReplace } = payload;

            if (isReplace) {
                return {
                    ...state,
                    stationSidebar: data,
                };
            }

            return {
                ...state,
                stationSidebar: {
                    ...state.stationSidebar,
                    ...data,
                    data: [
                        ...state.stationSidebar.data,
                        ...data.data,
                    ],
                },
            };
        }

        case types.LOADING_STATION_SIDEBAR: {
            return {
                ...state,
                loadingStationSidebar: payload,
            };
        }

        case types.CLEAR_STATION_SIDEBAR: {
            return {
                ...state,
                stationSidebar: initialState.sidebar,
            };
        }

        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }

        case types.SET_FILTERS: {
            return {
                ...state,
                filters: payload
            };
        }

        case types.LOADING_VESTIBULE_POLYGON: {
            return {
                ...state,
                loadingVestibulePolygon: payload,
            };
        }

        case types.LOADED_VESTIBULE_POLYGON: {
            return {
                ...state,
                vestibulePolygon: payload,
            };
        }

        case types.CHANGE_COLOR_VESTIBULE_POLYGON: {
            // структура события (UAPTS-7909)
            // {
            //     "vestibule_id_list":[1,2,3],
            //     "color":"#008000"
            // }

            // есть данные
            if (
                state.vestibulePolygon.length > 0
                && payload.length > 0
            ) {
                // собираем структуру "id: color"
                const idColor = payload?.reduce((r, event) => {

                    const vestibule_id_list = event?.vestibule_id_list || [];
                    const color = '#0000ff'; //event?.color;

                    if (
                        vestibule_id_list
                        && vestibule_id_list.length > 0
                        && color
                    ) {
                        vestibule_id_list
                            .forEach(key => {
                                r[key] = color;
                            });
                    }

                    return r;
                }, {});

                // обновление цвета полигона
                const vestibulePolygon = state.vestibulePolygon
                    .map(item => ({
                        ...item,
                        color_load: idColor[item.id] || item.color_load
                    }));

                return {
                    ...state,
                    vestibulePolygon,
                };
            }

            return state;
        }

        case types.LOADED_VESTIBULE_LIST: {
            return {
                ...state,
                vestibuleList: payload
            };
        }

        case types.LOADING_VESTIBULE_LIST: {
            return {
                ...state,
                loadingVestibuleList: payload
            };
        }

        case types.LOADED_VESTIBULE_STATUSES: {
            return {
                ...state,
                vestibuleStatuses: payload
            };
        }

        case types.LOADED_SIDEBAR: {
            const page = payload.meta?.current_page || 1;

            if (page === 1) {
                return {
                    ...state,
                    sidebar: payload,
                };
            }

            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...payload,
                    data: [
                        ...state?.sidebar?.data,
                        ...payload?.data
                    ]
                },
            };
        }

        case types.CLEAR_SIDEBAR: {
            return {
                ...state,
                sidebar: initialState.sidebar,
            };
        }

        case types.LOADING_SIDEBAR: {
            return {
                ...state,
                sidebarLoading: payload,
            };
        }

        case types.SET_ACTIVE_VESTIBULE: {
            return {
                ...state,
                activeVestibule: payload,
            };
        }

        case types.SET_FILTERS_VESTIBULE: {
            return {
                ...state,
                filtersVestibule: payload
            };
        }

        case types.LOADING_VESTIBULE_LOAD: {
            return {
                ...state,
                loadingVestibuleLoad: payload,
            };
        }

        case types.LOADED_VESTIBULE_LOAD: {
            return {
                ...state,
                vestibuleLoad: payload,
            };
        }

        case types.LOADING_VESTIBULE_TRAFFIC: {
            return {
                ...state,
                loadingVestibuleTraffic: payload,
            };
        }

        case types.LOADED_VESTIBULE_TRAFFIC: {
            return {
                ...state,
                vestibuleTraffic: payload,
            };
        }

        default:
            return state;
    }
}
